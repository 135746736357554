import React, {useEffect, useState} from 'react';
import {Button, Col, notification, Row, Space, Typography} from 'antd';
import {ExportOutlined, FilterOutlined, UserAddOutlined,} from '@ant-design/icons';
import {Table} from 'ant-table-extensions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {createUser, getUsers, updateUser} from 'store/users/actions';
import {getDistricts, getRegions, getWards} from 'store/locations/actions';
import FilterForm from 'components/shared/filter-form';
import {tablePagination} from 'utils/constants/layout';
import {getAgentColumns} from "./agent-columns";
import AgentProfile from "./profile";
import DrawerForm from "../../shared/drawer-form";
import {useTranslation} from "react-i18next";
import {getUserFullName} from "../../../utils/lib/helpers";
import SalesList from "../technicians/sales-list";
import TechnicianList from "./technicians-list";
import Sales from "../sales";
import moment from "moment/moment";
import Search from "antd/es/input/Search";
import {exportUserList} from "../../../utils/api";

let exportData = [];

const Agents = props => {
  const {
    onGetUserList,
    agentList,
    regions,
    districts,
    onGetRegions,
    onGetDistricts,
    loading,
      wards,
    onGetWards,
    onCreateAgent,
    onUpdateAgent,
      pagination
  } = props

  const {t} = useTranslation();

  const _notification = notification.useNotification()[0]
  const _notification_context_handler = notification.useNotification()[1]

  const [state, setState] = useState({
    showFormModal: false,
    showFilter: false,
    selectedUser: null
  })

  const [sortedInfo, setSortedInfo] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [pageSize, setPageSize] = useState(50)
  const [tblPagination, setTblPagination] = useState({limit: pageSize, offset: 0})
  const [showProfile, setShowProfile] = useState({show: {profile: false}, agent: null})
  const [params, setParams] = useState({designation: 'agent'})
  const [exporting, setExporting] = useState(false);

  const [form, setForm] = useState({
    show: false,
    editing: false,
    title: '',
    fields: [],
    onClose: () => {},
    onSubmit: () => {},
  });


  const handleOnFieldChange = (name, value) => {
    console.log('OnChange Called::', name);
    console.log('OnChange Called Value::', value);

    if(value === null || value === undefined) return;

    switch (name) {
      case 'region':
        onGetDistricts({region: value});
        break;
      case 'district':
        onGetWards({district: value});
        break;
      default:
        return;
    }
  };

  const getFields = formType => {
    const sharedFields = [
      {name: 'first_name', label: 'First Name', placeholder: 'First Name'},
      {name: 'last_name', label: 'Last Name', placeholder: 'Last Name'},
      {name: 'email', label: 'Email Address', placeholder: 'Email Address'},
      {name: 'phone_number', label: 'Phone Number', placeholder: 'Phone Number'},
      {name: 'alternative_number', label: 'Secondary Phone Number', placeholder: 'Secondary Phone Number'},
      {name: 'agent_office_name', label: 'Office Name', placeholder: 'Office Name'},
      {name: 'user_id', label: 'TIN #', placeholder: 'TIN #'},
      {name: 'region', label: 'Region', placeholder: 'Region', fieldType: 'select', options: regions, onChange: handleOnFieldChange},
      {name: 'district', label: 'District', placeholder: 'District', fieldType: 'select', options: districts, onChange: handleOnFieldChange},
      {name: 'ward', label: 'Ward', placeholder: 'Ward', fieldType: 'select', options: wards},
    ];

    const genericFields = [...sharedFields];
    const fields = {
      filter: [...sharedFields],
      create: [...genericFields],
      edit: [...genericFields],
    };
    return fields[formType];
  };

  const handleChange = (pagination, filters, sorter) => {
    // setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    if(params?.q){
      onGetUserList({...params})
    }else{
      onGetUserList({...params, ...tblPagination})
    }
    onGetRegions()
  }, [tblPagination, params])

  const exportUsers = (p) => {
    setExporting(true)
    exportUserList({...p, type: 'agent'})
        .then(response => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `agents-export-${p?.startDate}-${p?.endDate}.xlsx`
          link.click()
          setExporting(false)
        })
  }

  const handleOnPageChange = page => {
    setTblPagination(prevState => ({
      ...prevState,
      offset: (page - 1) * pageSize
    }))
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (keys, rows) => {
      exportData = rows
      setSelectedRowKeys(keys);
    }
  };

  const handleGetOptionValues = (field) => {
    if(form?.selectedRecord){
      console.log("Selected Record:: ", form?.selectedRecord)
      onGetDistricts({region: form?.selectedRecord?.region?.uid || form?.selectedRecord?.region});
      onGetWards({district: form?.selectedRecord?.district?.uid || form?.selectedRecord?.district});
    }
  }

  const handleOnRecordSelected = (record, event) => {
    setShowProfile(prevState => ({
      show: {
        [event]: true
      },
      agent: record
    }));
  }

  const handleOnClose = (key) => {
    setShowProfile(prevState => ({
      ...prevState,
      show: {
        [key]: false
      },
      agent: null
    }))
  }

  const handleOnSubmit = (data, callback) => onCreateAgent(data, callback);

  const handleOnUpdate = (data, callback, record) =>
      onUpdateAgent(record?.uid, data, callback);

  const handleOnCloseForm = () =>
      setForm(prevState => ({...prevState, show: !prevState.show, selectedRecord: null}));

  const handleOnEditRecord = record => {
    const payload = {...record};
    console.log("Record Object::", record)
    payload.region = record?.region?.uid
    payload.district = record?.district?.uid || record?.district
    payload.ward = record?.ward?.uid || record?.ward

    setForm(prevState => ({
      ...prevState,
      show: !prevState?.show,
      editing: !prevState?.editing,
      title: t('editing', {record: getUserFullName(payload)}),
      label: t('update'),
      fields: getFields('edit'),
      selectedRecord: payload,
      onClose: handleOnCloseForm,
      onSubmit: handleOnUpdate,
    }));
  };

  const handleOnDeleteRecord = record => {
    const payload = {is_active: false, archived: true}
    onUpdateAgent(record?.uid, payload, 'agent')
  }

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Agents</Typography.Title>
          <Space>
              <Search  onSearch={(v, e) => setParams(prevState => ({...prevState, q: v}))} placeholder="Type and press ENTER to search..." style={{width: 350}} loading={loading}/>
            <Button onClick={() => {
              setForm(prevState => ({
                ...prevState,
                show: !prevState?.show,
                title: t('new_agent'),
                label: t('save'),
                fields: getFields('create'),
                onClose: handleOnCloseForm,
                onSubmit: handleOnSubmit,
              }))
            }} icon={<UserAddOutlined />}>Add Agent</Button>
              <Button onClick={() => { setState({ ...state, showFilter: true, title: "Filter", action: 'FILTER' }) }} icon={<FilterOutlined />}>Filter</Button>
              <Button onClick={() => { setState({ ...state, showFilter: true, title: "Export", action: 'EXPORT',  }) }} icon={<ExportOutlined />} loading={exporting}>{exporting ? 'Exporting...' : 'Export'}</Button>
          </Space>
        </Space>
        <Table
          pagination={{
            total: pagination?.count,
            defaultPageSize: pageSize,
            onChange: handleOnPageChange,
          }}
          rowKey={'uid'}
          rowSelection={rowSelection}
          columns={getAgentColumns(handleOnRecordSelected, handleOnEditRecord, handleOnDeleteRecord)}
          dataSource={agentList.filter(a => !a?.archived)}
          onChange={handleChange}
          loading={loading} />
      </Col>

      <FilterForm
        filterLocation
        filterDates
        title={`${state?.title} Agents`}
        regions={regions}
        districts={districts}
        wards={wards}
        onGetDistricts={onGetDistricts}
        onGetWards={onGetWards}
        openDrawer={state.showFilter}
        onCloseDrawer={() => setState({ ...state, showFilter: false })}
        onSubmitFilterOptions={(data) => {
          setState({ ...state, showFilter: false });
          let payload = {
            ...data
          }
          if(payload.date) {
            payload = {...payload,
              startDate: moment(data.date[0].$d.toLocaleDateString()).format('DD/MM/YYYY'),
              endDate: moment(data.date[1].$d.toLocaleDateString()).format('DD/MM/YYYY'),}
          }
          delete data.date
          if(state?.action === 'FILTER') {
            setParams(prevState => ({...prevState, ...payload}))
          }else if(state?.action === 'EXPORT') {
            exportUsers(payload)
          }
        }}
      />

      {showProfile?.show?.profile && (
          <AgentProfile
              isVisible={showProfile?.show?.profile}
              agent={showProfile?.agent}
              onClose={handleOnClose}
          />
      )}

      {showProfile?.show?.technicians && (
          <TechnicianList
              isVisible={showProfile?.show?.technicians}
              user={showProfile?.agent}
              onClose={handleOnClose}
          />
      )}

      {showProfile?.show?.sales && (
          <SalesList
              title={'Agent'}
              isVisible={showProfile?.show?.sales}
              user={showProfile?.agent}
              onClose={handleOnClose}
          />
      )}

      {form?.show && (
          <DrawerForm
              visible={form?.show}
              fields={form?.fields}
              editing={form?.editing}
              title={form?.title}
              containerStyle={{
                marginTop: 0,
                marginBottom: 8,
              }}
              fieldSize="large"
              loading={loading}
              buttonLabel={form?.label}
              selectedRecord={form?.selectedRecord}
              customOptions={handleGetOptionValues}
              onDrawerMount={() => {
                onGetRegions();
              }}
              onClose={form?.onClose}
              onSubmitForm={form?.onSubmit}
          />
      )}
    </Row>
  )
}

Agents.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  agentList: PropTypes.any,
  error: PropTypes.any,
  onGetUserList: PropTypes.any,
  onGetDistricts: PropTypes.any,
  onGetRegions: PropTypes.any,
  onGetWards: PropTypes.any,
  pagination: PropTypes.any,
  regions: PropTypes.array,
  districts: PropTypes.array,
  wards: PropTypes.array,
  onCreateAgent: PropTypes.func,
  onUpdateAgent: PropTypes.func
}

const mapStateToProps = ({
  auth: { user },
  users: { userList, agentList, error, loading, pagination },
  locations: { regions, districts, wards }
}) => ({
  user, userList, error, loading, regions, districts, wards, agentList, pagination
})

const mapDispatchToProps = dispatch => ({
  onGetUserList: (params) => dispatch(getUsers(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
  onGetWards: params => dispatch(getWards(params)),
  onCreateAgent: (payload, callback) => dispatch(createUser(payload, callback)),
  onUpdateAgent: (userId, payload, userType, callback) => dispatch(updateUser(userId, payload, userType, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Agents);
