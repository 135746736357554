import React from "react";
import {Col, Divider, Drawer, Row, Typography} from "antd";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {getUserFullName} from "../../../utils/lib/helpers";

const NotificationDetails = props => {
    const {notification, onClose, isVisible } = props;
    const {t} = useTranslation();

    return(
        <Drawer
            title={'Notification Detail'}
            placement={'right'}
            closable={true}
            onClose={onClose}
            open={isVisible}>
            <Row>
                <Col lg={24}>
                    <Typography.Title level={4} style={{margin: 0}}>{notification?.title}</Typography.Title>
                    <Typography.Text>{notification?.type}</Typography.Text>
                </Col>
            </Row>
            <Divider />
            <Row>
                {['target', 'message', 'region', 'district', 'ward',].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k)}</Typography.Title>
                        <Typography.Text>{k === 'created_by' ? getUserFullName(notification[k]) : notification[k] ? notification[k] : 'N/A'}</Typography.Text>
                    </Col>
                )))}
            </Row>
        </Drawer>
    )
}

NotificationDetails.propTypes = {
    user: PropTypes.object,
    notification: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
}

export default NotificationDetails;
