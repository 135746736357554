import {call, put, takeEvery} from 'redux-saga/effects';
import {
    addRewardSettingsFail,
    addRewardSettingsSuccess,
    getRewardSettingsFail,
    getRewardSettingsSuccess,
    getSalesFail,
    getSalesSuccess,
    updateRewardSettingsFail,
    updateRewardSettingsSuccess
} from './actions';
import {extractErrorMessage} from '../../utils/lib/helpers';
import {createRewardSetting, getPersonalSales, getRewardSettings, getSales, updateRewardSetting} from 'utils/api';
import {ADD_REWARD_SETTINGS, GET_REWARD_SETTINGS, GET_SALES, UPDATE_REWARD_SETTING} from './action-types';

function* fetchRewardSettings({ payload }) {
  try {
    const response = yield call(getRewardSettings, payload);
    yield put(getRewardSettingsSuccess(response.data.results));
  } catch (error) {
    yield put(getRewardSettingsFail(extractErrorMessage(error)));
  }
}

function* fetchSales({ payload }) {
  try {
    console.log(payload)
    const response = yield call(getSales, payload);
    yield put(getSalesSuccess(response.data));
  } catch (error) {
    yield put(getSalesFail(extractErrorMessage(error)));
  }
}

function* putRewardSetting({ payload: {payload, callback} }) {
  try {
    console.log(payload)
    const response = yield call(createRewardSetting, payload);
    yield put(addRewardSettingsSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(addRewardSettingsFail(extractErrorMessage(error)));
  }
}

function* patchRewardSetting({ payload: {rewardId, payload, callback} }) {
  try {
    console.log(payload)
    const response = yield call(updateRewardSetting, {rewardId, payload});
    yield put(updateRewardSettingsSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateRewardSettingsFail(extractErrorMessage(error)));
  }
}


function* rewardsAndSalesSaga() {
  yield takeEvery(GET_REWARD_SETTINGS, fetchRewardSettings);
  yield takeEvery(GET_SALES, fetchSales);
  yield takeEvery(ADD_REWARD_SETTINGS, putRewardSetting);
  yield takeEvery(UPDATE_REWARD_SETTING, patchRewardSetting);
}

export default rewardsAndSalesSaga;
