import React from 'react';

import {
    ApartmentOutlined,
    BankOutlined,
    GiftOutlined,
    HomeOutlined,
    NotificationOutlined,
    OrderedListOutlined,
    TeamOutlined,
    UserOutlined
} from '@ant-design/icons';
import {Menu} from 'antd';
import {Link, useLocation} from 'react-router-dom'
import ProductIcon from '../../assets/images/products.svg'
import TechnicianIcon from '../../assets/images/technician.svg'
import EmiratesLogo from '../../assets/images/emirates.svg'

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const iconStyles = {
  height: '25px',
  resize: 'cover'
}

const items = [
  getItem(<Link to={'/dashboard/'}>Dashboard</Link>, '/dashboard/', <HomeOutlined />),
  getItem(<Link to={'/agents/'}>Agents</Link>, '/agents/', <UserOutlined />),
  getItem(<Link to={'/technicians/'}>Technicians</Link>, '/technicians/', <img src={TechnicianIcon} style={{ height: 18, width: 18 }} />),
  getItem('Products', 'grp1', null, [
    getItem(<Link to={'/products/'}>Product List</Link>, '/products/', <img src={ProductIcon} style={iconStyles} />),
    getItem(<Link to={'/products-categories/'}>Product Categories</Link>, '/products-categories/', <img src={ProductIcon} style={iconStyles} />),
    getItem(<Link to={'/products-colors/'}>Product Colors</Link>, '/products-colors/', <img src={ProductIcon} style={iconStyles} />),
    getItem(<Link to={'/products-sizes/'}>Product Sizes</Link>, '/products-sizes/', <img src={ProductIcon} style={iconStyles} />)
  ], 'group'),
  getItem('Projects', 'grp2', null, [
    getItem(<Link to={'/projects/'}>Project List</Link>, '/projects/', <img src={EmiratesLogo} style={iconStyles} />),
    getItem(<Link to={'/projects-categories/'}>Project Categories</Link>, '/projects-categories/', <img src={EmiratesLogo} style={iconStyles} />)
  ], 'group'),
  getItem('Rewards', 'grp3', null, [
    getItem(<Link to={'/rewards/'}>Reward Settings</Link>, '/rewards/', <GiftOutlined />),
    getItem(<Link to={'/awards/'}>Redeemed Awards</Link>, '/awards/', <GiftOutlined />),
    getItem(<Link to={'/sales/'}>Sales</Link>, '/sales/', <OrderedListOutlined />)
  ], 'group'),
  getItem(<Link to={'/regions/'}>Locations</Link>, '/regions/', <ApartmentOutlined />),
  getItem(<Link to={'/ads/'}>Ads</Link>, '/ads/', <BankOutlined />),
  getItem(<Link to={'/notifications/'}>Notifications</Link>, '/notifications/', <NotificationOutlined />),
    getItem(<Link to={'/profession-categories/'}>Profession Categories</Link>, '/profession-categories/', <img src={TechnicianIcon} style={{ height: 18, width: 18 }} />),
  getItem(<Link to={'/users/'}>Users</Link>, '/users/', <TeamOutlined />),

];
const Sidebar = () => {
  const { pathname } = useLocation()


  return (
    <div
      style={{
        width: 250,
      }}
    >
      <Menu
        defaultSelectedKeys={[]}
        selectedKeys={[pathname]}
        defaultOpenKeys={[]}
        mode="inline"
        items={items}
        inlineIndent={22}
      />
    </div>
  );
};
export default Sidebar;
