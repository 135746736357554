import React, {useEffect, useState} from "react";
import {Col, Divider, Drawer, Image, Row, Tag, Typography} from "antd";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {createProjectImage, getProjectImages} from "../../../store/projects/actions";
import {getUserFullName} from "../../../utils/lib/helpers";
import ImagesForm from "../../shared/images-form";

const ProjectDetails = props => {
    const {project, onClose, isVisible, loading, error, user, images, onGetProjectImages, onCreateProjectImages} = props;
    const {t} = useTranslation();
    const [state, setState] = useState({addImage: false})

    useEffect(() => {
        onGetProjectImages({project: project?.uid});
    }, [onGetProjectImages]);

    return(
        <Drawer
            title={t('detail', {record: project?.project_title})}
            placement={'right'}
            closable={true}
            onClose={onClose}
            open={isVisible}>
            <Row>
                <Col lg={18}>
                    <Typography.Title level={4} style={{margin: 0}}>{project?.project_title}</Typography.Title>
                    <Typography.Text>{project?.district?.name} - {project?.region?.name}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Image
                        src={project?.image}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                        onClick={() => {}}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {['description', 'number_of_likes', 'created_by'].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k)}</Typography.Title>
                        <Typography.Text>{k === 'created_by' ? getUserFullName(project[k]) : project[k]}</Typography.Text>
                    </Col>
                )))}
            </Row>
            <Divider />
            <Row>
                <Col lg={24} style={{marginTop: 10}}>
                    <Typography.Title level={5} style={{margin: 0}}>{t(`project_categories`)}</Typography.Title>
                    {project?.categories?.length > 0 ? project?.categories.map(({name, uid}) => (
                        <Tag key={uid} color={'#cbb52f'}>{name}</Tag>
                    )) : 'N/A'}
                </Col>
            </Row>
            <Divider />
            <Row>
                <Col lg={24}>
                    <Typography.Title level={5} style={{margin: 0}}>Project Images</Typography.Title>
                    <Image.PreviewGroup
                        preview={{ onChange: (current, prev) => { }, }}>
                        {images?.map((_img, index) => (<Image key={`images-${index}`} height={120} src={_img.image} style={{ padding: '0  20px 10px 0px' }} /> ))}
                    </Image.PreviewGroup>
                    <div className='upload-button' style={{ padding: '0 0 0 0px' }}
                         onClick={() => setState(prevState => ({ ...state, addImage: !prevState?.addImage }))}>
                        <PlusOutlined />
                        <div style={{ marginTop: 10, fontSize: 13 }}>Add Image</div>
                    </div>
                </Col>
            </Row>
            {state?.addImage && (
                <ImagesForm
                    isOpen={state?.addImage}
                    onCancel={() => setState(prevState => ({ ...state, addImage: !prevState?.addImage }))}
                    user={user}
                    project={project}
                    imageList={images}
                    objectName={'project'}
                    objectRefId={project?.uid}
                    onSubmit={onCreateProjectImages}
                />
            )}
        </Drawer>
    )
}

ProjectDetails.propTypes = {
    user: PropTypes.object,
    project: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onGetProjectImages: PropTypes.func,
    onCreateProjectImages: PropTypes.func,
    images: PropTypes.array,
}

const mapStateToProps = ({auth: {user}, projectsReducer: {images, loading, error}}) => ({ loading, error, images, user});

const mapDispatchToProps = dispatch => ({
    onGetProjectImages: (params) => dispatch(getProjectImages(params)),
    onCreateProjectImages: (payload, callback) => dispatch(createProjectImage(payload, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectDetails);
