import {Popconfirm, Typography} from "antd";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


export const getCategoryColumns = (sortedInfo, onRecordSelected, onDeleteRecord) => [
    {
        title: 'Category Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <React.Fragment>
                    <Typography.Link onClick={() => onRecordSelected(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                        <EditOutlined /> Edit
                    </Typography.Link>
                    <Popconfirm
                        onConfirm={() => onDeleteRecord(record)}
                        title="Delete Confirmation"
                        description="Are you sure to delete this category?"
                        okText="Yes"
                        cancelText="No">
                        <Typography.Link
                            style={{ color: '#ff0000' }}>
                            <DeleteOutlined />
                            Delete
                        </Typography.Link>
                    </Popconfirm>
                </React.Fragment>
            );
        },
    },
];
