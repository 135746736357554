import {Popconfirm, Typography} from "antd";
import {formatNumber, getUserFullName} from "../../../utils/lib/helpers";
import React from "react";
import {archiveUser} from "../users";
import {CheckCircleFilled, CloseCircleFilled, DeleteOutlined} from "@ant-design/icons";


export const getUserColumns = (sortedInfo, onSelectRecord, onDeleteRecord) => [
    {
        title: 'Date, Time',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        width: '10%',
        render: (_, record) => (<Typography.Text style={{ color: '#000', fontWeight: 'bold' }}>{record.created_at}</Typography.Text>)
    },
    {
        title: 'First Name',
        dataIndex: 'first_name',
        key: 'first_name',
        sorter: (a, b) => a.first_name.length > b.first_name.length,
        sortOrder: sortedInfo.columnKey === 'first_name' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onSelectRecord(record, 'profile')}>
            {record.first_name}</Typography.Link>)
    },
    {
        title: 'Last Name',
        dataIndex: 'last_name',
        key: 'last_name',
        sorter: (a, b) => a.last_name.length > b.last_name.length,
        sortOrder: sortedInfo.columnKey === 'last_name' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onSelectRecord(record, 'profile')}>
            {record.last_name}</Typography.Link>)
    },
    {
        title: 'ID #',
        dataIndex: 'user_id',
        key: 'user_id',
        sortOrder: sortedInfo.columnKey === 'user_id' ? sortedInfo.order : null,
        ellipsis: true,
        width: '10%',
        render: (_, record) => {
            return (
                <Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onSelectRecord(record, 'idPreview')}>
                    {record.user_id.match(/.{1,3}/g).join('-')}
                </Typography.Link>
            )
        }
    },
    {
        title: 'Region',
        dataIndex: 'region.name',
        key: 'region.name',
        sortOrder: sortedInfo.columnKey === 'region' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => record?.region?.name
    },
    {
        title: 'District',
        key: 'district.name',
        dataIndex: 'district.name',
        sortOrder: sortedInfo.columnKey === 'district' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => record?.district?.name
    },
    {
        title: 'Ward',
        key: 'ward.name',
        dataIndex: 'ward.name',
        sortOrder: sortedInfo.columnKey === 'ward' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => record?.ward ? record?.ward?.name : 'N/A'
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        sortOrder: sortedInfo.columnKey === 'phone_number' ? sortedInfo.order : null,
        ellipsis: true,
        width: '7%',
    },
    {
        title: 'Award Points',
        dataIndex: 'award_points',
        key: 'award_points',
        sortOrder: sortedInfo.columnKey === 'award_points' ? sortedInfo.order : null,
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{formatNumber(record)}</Typography.Link>)
    },
    {
        title: 'Total Sales',
        dataIndex: 'total_sales',
        key: 'total_sales',
        ellipsis: true,
        render: (text, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onSelectRecord(record, 'sales')}>{`TZS${formatNumber(text)}`}</Typography.Link>)
    },
    {
        title: 'Verification',
        dataIndex: 'is_account_verified',
        key: 'total_sales',
        width: '6%',
        render: (record) => (
            <Typography.Text style={{color: record ? '#CAB52F' : '#ff0000'}}>
                {record ? <CheckCircleFilled /> : <CloseCircleFilled /> }
                &nbsp; {record ? 'Verified' : 'Not Verified'}
            </Typography.Text>
        )
    },
    {
        title: 'Account Status',
        dataIndex: 'is_active',
        key: 'is_active',
        ellipsis: true,
        width: '8%',
        render: (record) => (
            <Typography.Text style={{color: record ? '#CAB52F' : '#ff0000'}}>
                {record ? <CheckCircleFilled /> : <CloseCircleFilled /> }
                &nbsp; {record ? 'Active' : 'Not Active'}
            </Typography.Text>
        )
    },
    {
        title: 'Created By',
        dataIndex: 'created_by.agent_office_name',
        key: 'created_by.agent_office_name',
        ellipsis: true,
        width: '10%',
        render: (_, record) => record?.created_by?.agent_office_name || 'N/A'
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <Popconfirm
                    onConfirm={() => onDeleteRecord(record)}
                    title="Delete Confirmation"
                    description="Are you sure to delete this agent?"
                    okText="Yes"
                    cancelText="No">
                    <Typography.Link
                        style={{ color: '#ff0000' }}>
                        <DeleteOutlined />
                        Delete
                    </Typography.Link>
                </Popconfirm>
            );
        },
    },
];
