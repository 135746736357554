import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Typography} from 'antd';
import {ExportOutlined, UserAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import AddProduct from './add-product';
import {getProducts, setSelectedProduct, updateProduct} from 'store/products/actions';
import {tablePagination} from 'utils/constants/layout';
import {productColumns} from "./product-columns";
import {Table} from "ant-table-extensions";
import ProductDetails from "./product-details";


const Products = props => {
    const {
        loading,
        productList,
        onGetProducts,
        onSetSelectedProduct,
        onUpdateProduct
    } = props

    const [form, setForm] = useState({isOpen: false})
    const [detailView, setDetailView] = useState({show: {detail: false}, product: null})
    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    useEffect(() => {
        onGetProducts()
    }, [onGetProducts])

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleOnClose = () => setForm(prevState => ({...prevState, isOpen: !prevState?.isOpen, initialValues: {}}))
    const handleOnUpdateRecord = (record, data) => {
        const payload  = {...data}
        onUpdateProduct(record?.uid, payload, handleOnClose);
    }

    const handleOnDeleteRecord = (record) => {
        const payload = {archived: true}
        onUpdateProduct(record?.uid, payload);
    }

    const handleOnRecordSelected = (record, event) => {
        setDetailView(prevState => ({
            show: {
                detail: !prevState?.show?.detail
            },
            product: record
        }));
    }

    const handleOnEditRecord = record => {
        const payload = {...record}
        payload.categories = record?.categories?.map(({uid}) => uid)
        payload.color = record?.color?.map(({uid}) => uid)
        payload.size = record?.size?.map(({uid}) => uid)

        setForm(prevState => ({
            ...prevState,
            isOpen: !prevState?.isOpen,
            action: 'edit',
            initialValues: payload,
            title: "Edit Product",
            btnLabel: "Update",
            onCancel: handleOnClose,
            onSubmit: handleOnUpdateRecord
        }))
    }

    return (
        <Row>
            <Col>
                <Space
                    style={{
                        marginBottom: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Typography.Title
                        level={4}
                        style={{display: 'flex', alignItems: 'center'}}>
                        Products
                    </Typography.Title>
                    <Space>
                        <Button
                            onClick={() => setForm(prevState => ({
                                ...prevState,
                                action: 'create',
                                title: 'Add A New product',
                                isOpen: !prevState.isOpen,
                                onCancel: handleOnClose,
                                btnLabel: 'Save',
                            }))}
                            icon={<UserAddOutlined/>}>Add Product</Button>
                        {hasSelected && (
                            <Button onClick={() => {
                            }} icon={<ExportOutlined/>}>Export Data</Button>
                        )}
                    </Space>
                </Space>
                <Table
                    pagination={tablePagination}
                    rowKey={'uid'}
                    exportable

                    searchable
                    searchableProps={{
                        inputProps: {
                            placeholder: "Type a word to search...",
                            style:{width: '15%'}
                        }
                    }}
                    exportableProps={{
                        fields: {
                            name: {header: 'Name', formatter: (_v, _) => _v},
                            serial_number: {header: 'Name', formatter: (_v, _) => _v},
                            size: {header: 'Size', formatter: (_v, _) => _v?.map(c => c?.name)},
                            min_order_quantity: {header: 'Min. Order Qty', formatter: (_v, _) => _v},
                            categories: {header: 'Categories', formatter: (_v, _) => _v?.map(c => c?.name)},
                            color: {header: 'Color', formatter: (_v, _) => _v?.map(c => c?.name)}
                        },
                        fileName: 'Products',
                        showColumnPicker: true
                    }}
                    rowSelection={rowSelection}
                    columns={productColumns(sortedInfo, handleOnRecordSelected, handleOnEditRecord, handleOnDeleteRecord)}
                    dataSource={productList.filter(p => !p?.archived)}
                    onChange={handleChange}
                    loading={loading}
                />
            </Col>

            {form?.isOpen && (<AddProduct {...form} />)}
            {detailView?.show?.detail && (
                <ProductDetails
                    isVisible={detailView?.show?.detail}
                    product={detailView?.product}
                    onClose={handleOnRecordSelected}
                />
            )}
        </Row>
    )
}

Products.propTypes = {
    error: PropTypes.any,
    loading: PropTypes.any,
    productList: PropTypes.any,
    images: PropTypes.any,
    onGetProducts: PropTypes.any,
    onSetSelectedProduct: PropTypes.func,
    onUpdateProduct: PropTypes.func,
}

const mapStateToProps = ({
    products: {productList, loading, error, images}
}) => ({ error, loading, productList, images
})

const mapDispatchToProps = dispatch => ({
    onGetProducts: (params) => dispatch(getProducts(params)),
    onUpdateProduct: (productId, payload, callback) => dispatch(updateProduct(productId, payload, callback)),
    onSetSelectedProduct: product => dispatch(setSelectedProduct(product))
})

export default connect(mapStateToProps, mapDispatchToProps)(Products);
