import {Card, Col, Row, Statistic, Table, Typography} from 'antd';
import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import Chart from 'react-apexcharts'
import api from 'utils/api/config';

const { Text, Title, Link } = Typography;

const _style = {
	title: {
		fontSize: '20px',
		fontWeight: '600',
		textAlign: 'left',
		margin: '10px 0',
	},
	link: {
		cursor: 'pointer'
	}
}

const Dashboard = props => {

	const _history = useHistory()

	const [state, setState] = useState({
		loading: true,
		data: [],
		dates: [],
		latestAgents: [],
		latestTechnicians: [],
		top_award_points: [],
		technician_count: 0,
		agent_count: 0,
		total_sales: 0,
		total_awards_count: 0,
	})

	const handleChange = (pagination, filters, sorter) => {
	};

	const location = [
		{
			title: 'Region',
			dataIndex: 'region',
			key: 'region',
			render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.region?.name}</Typography.Link>)
		},
		{
			title: 'District',
			dataIndex: 'district',
			key: 'district',
			render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.district?.name}</Typography.Link>)
		},
		{
			title: 'Ward',
			dataIndex: 'ward',
			key: 'ward',
			render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.ward?.name || 'N/A'}</Typography.Link>)
		}
	]

	const technicianColumns = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
			key: 'first_name',
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			key: 'last_name',
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		...location
	]

	const agentColumns = [
		{
			title: 'Name',
			dataIndex: 'agent_office_name',
			key: 'agent_office_name',
			ellipsis: true,
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		{
			title: 'ID #',
			dataIndex: 'user_id',
			key: 'user_id',
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.match(/.{1,3}/g).join('-')}</Typography.Link>)
		},
		...location
	]

	const topAwardPointsColumns = [
		{
			title: 'First Name',
			dataIndex: 'first_name',
			key: 'first_name',
			ellipsis: true,
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		{
			title: 'Last Name',
			dataIndex: 'last_name',
			key: 'last_name',
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		{
			title: 'Award Points',
			dataIndex: 'award_points',
			key: 'award_points',
			render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
		},
		{
			title: 'Region',
			dataIndex: 'region',
			key: 'region',
			render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{
				record?.region?.name
			}</Typography.Link>)
		},
	]

	const getData = async () => {
		try {
			const response = await api(true).get('statistics/')
			if (response.status === 200) {
				setState({
					...state,
					loading: false,
					latestAgents: response.data.agents,
					latestTechnicians: response.data.technicians,
					data: response.data.amount,
					dates: response.data.dates,
					top_award_points: response.data.top_award_points,
					technician_count: response.data.all_technicians,
					agent_count: response.data.all_agents,
					total_sales: response.data.total_sales.sales_amount__sum,
					total_awards_count: response.data.total_awards_count
				})
			}
		} catch (error) {
			console.log(error)
			setState({ ...state, loading: false })
		}
	}

	useEffect(() => {
		getData()
	}, [])


	return (
		<React.Fragment>
			<Row>
			</Row>
			<Row gutter={16}>
				<Col span={6}>
					<Card bordered={false}
						style={_style.link}
						onClick={() => {
							_history.push(`/sales/`)
						}}>
						<Statistic
							title="Sales"
							value={state.total_sales || 0}
							precision={0}
							valueStyle={{
								color: '#3f8600',
							}}
							prefix={'TZS'}
							suffix=""
						/>
					</Card>
				</Col>
				<Col span={6}>
					<Card bordered={false}
						style={_style.link}
						onClick={() => {
							_history.push(`/agents/`)
						}}>
						<Statistic
							title="Agents"
							value={state.agent_count || 0}
							precision={0}
							valueStyle={{
								color: '#3f8600',
							}}
							prefix={null}
							suffix=""
						/>
					</Card>
				</Col>
				<Col span={6}>
					<Card bordered={false}
						style={_style.link}
						onClick={() => {
							_history.push(`/technicians/`)
						}}>
						<Statistic
							title="Technicians"
							value={state.technician_count}
							precision={0}
							valueStyle={{
								color: '#3f8600',
							}}
							prefix={null}
							suffix=""
						/>
					</Card>
				</Col>
				<Col span={6}>
					<Card bordered={false}
						style={_style.link}
						onClick={() => {
							_history.push(`/awards/`)
						}}>
						<Statistic
							title="Rewards"
							value={state.total_awards_count || 0}
							precision={0}
							valueStyle={{
								color: '#3f8600',
							}}
							prefix={null}
							suffix=""
						/>
					</Card>
				</Col>
			</Row>
			<Row style={{ marginTop: 20 }}>
				<Col lg={13} style={{}}>
					<div style={_style.title}>Latest Sales</div>
					<Card style={{ borderRadius: 16, height: '430px' }}>
						<Chart
							options={{
								chart: {
									id: 'trends'
								},
								xaxis: {
									categories: state.dates
								}
							}}
							series={[{
								name: 'Sales',
								data: state.data
							}]}
							type='area'
							width={'100%'}
							height={'400px'}
						/>
					</Card>
				</Col>
				<Col lg={10} offset={1} style={{ overflow: 'hidden' }}>
					<div style={_style.title}>Highest Technician Scores</div>
					<Card style={{ borderRadius: 16, height: '430px' }}>
						<Table
							pagination={false}
							columns={topAwardPointsColumns}
							dataSource={state.top_award_points}
							onChange={handleChange}
							loading={state.loading}
						/>
					</Card>
				</Col>
			</Row>
			<Row style={{ margin: '20px 0' }}>
				<Col span={24} lg={13}>
					<div style={_style.title}>Latest Registered Agents</div>
					<Card style={{ borderRadius: 16, boxShadow: 'none', height: '400px' }}>
						<Table
							pagination={false}
							columns={agentColumns}
							dataSource={state.latestAgents}
							onChange={handleChange}
							loading={state.loading} />
					</Card>
				</Col>
				<Col span={24} lg={10} offset={1}>
					<div style={_style.title}>Latest Registered Technicians</div>
					<Card style={{ borderRadius: 16, height: '400px' }}>
						<Table
							pagination={false}
							columns={technicianColumns}
							dataSource={state.latestTechnicians}
							loading={state.loading} />
					</Card>
				</Col>
			</Row>
		</React.Fragment>
	)
}

export default Dashboard;
