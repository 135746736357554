import {call, put, takeEvery} from 'redux-saga/effects';

import {
    createProfessionCategoryFail,
    createProfessionCategorySuccess,
    createUserFail,
    createUserSuccess,
    getProfessionCategoriesFail,
    getProfessionCategoriesSuccess,
    getUserSalesFail,
    getUserSalesSuccess,
    getUsersFail,
    getUsersSuccess,
    resetUserPasswordFail,
    resetUserPasswordSuccess, updateProfessionCategoryFail, updateProfessionCategorySuccess,
    updateUserFail,
    updateUserSuccess,
    verifyTechnicianFail,
    verifyTechnicianSuccess
} from './actions';
import {
    CREATE_PROFESSION_CATEGORY,
    CREATE_USER,
    GET_AGENTS_SUCCESS,
    GET_ALL_USERS_SUCCESS, GET_PROFESSION_CATEGORIES,
    GET_TECHNICIANS_SUCCESS, GET_USER_SALES,
    GET_USERS,
    GET_USERS_SUCCESS, RESET_USER_PASSWORD, UPDATE_PROFESSION_CATEGORY,
    UPDATE_USER,
    VERIFY_TECHNICIAN
} from './action-types';
import {
    addProfessionCategory,
    createUser,
    getPersonalSales,
    getProfessionCategories,
    getUserList,
    resetUserPassword, updateProfessionCategory,
    updateUser,
    verifyUser
} from 'utils/api';
import {getSalesFail, getSalesSuccess} from "../sales-rewards/actions";
import {extractErrorMessage} from "../../utils/lib/helpers";

function* doGetUsers(params) {
    console.log("Paylaod Received::", params)
  try {
    const response = yield call(getUserList, params.payload)
    let type = GET_AGENTS_SUCCESS
    if (params.payload.designation === 'administrator') type = GET_USERS_SUCCESS
    else if (params.payload.designation === 'agent') type = GET_AGENTS_SUCCESS
    else if (params.payload.designation === 'technician') type = GET_TECHNICIANS_SUCCESS
    else if (params.payload.designation == null) type = GET_ALL_USERS_SUCCESS
    yield put(getUsersSuccess(type, response.data));
  } catch (error) {
    yield put(getUsersFail(error));
  }
}

function* verifyTechnician({payload: {userId, payload, callback}}) {
  try{
    const response = yield call(verifyUser, {userId, payload});
    callback && callback();
    yield put(verifyTechnicianSuccess(response?.data))
  }catch (err) {
    yield put(verifyTechnicianFail(err))
  }
}

function* pathUserPassword({payload: {userId, callback}}) {
  try{
    const response = yield call(resetUserPassword, {userId});
    callback && callback();
    yield put(resetUserPasswordSuccess(response?.data))
  }catch (err) {
    yield put(resetUserPasswordFail(err))
  }
}

function* fetchTechnicianSales({ payload }) {
    try {
        console.log(payload)
        const response = yield call(getPersonalSales, payload);
        yield put(getUserSalesSuccess(response.data));
    } catch (error) {
        yield put(getUserSalesFail(extractErrorMessage(error)));
    }
}

function* putUser({payload: {payload, callback}}) {
  try{
    const response = yield call(createUser, payload);
    callback && callback();
    yield put(createUserSuccess(response?.data))
  }catch (err) {
    yield put(createUserFail(err))
  }
}

function* patchUser({payload: {userId, payload, userType, callback}}) {
  try{
    const response = yield call(updateUser, {userId, payload});
    callback && callback();
    yield put(updateUserSuccess({userType: userType, data: response?.data}))
  }catch (err) {
    yield put(updateUserFail(err))
  }
}

function* fetchProfessionCategories({ payload }) {
    try {
        console.log(payload)
        const response = yield call(getProfessionCategories, payload);
        yield put(getProfessionCategoriesSuccess(response.data?.results));
    } catch (error) {
        yield put(getProfessionCategoriesFail(extractErrorMessage(error)));
    }
}

function* putProfessionCategory({payload: {payload, callback}}) {
  try{
    const response = yield call(addProfessionCategory, payload);
    callback && callback();
    yield put(createProfessionCategorySuccess(response?.data))
  }catch (err) {
    yield put(createProfessionCategoryFail(err))
  }
}

function* patchProfessionCategory({payload: {categoryId, payload, callback}}) {
  try{
    const response = yield call(updateProfessionCategory, {categoryId, payload});
    callback && callback();
    yield put(updateProfessionCategorySuccess(response?.data))
  }catch (err) {
    yield put(updateProfessionCategoryFail(err))
  }
}

function* userSaga() {
  yield takeEvery(GET_USERS, doGetUsers);
  yield takeEvery(VERIFY_TECHNICIAN, verifyTechnician);
  yield takeEvery(GET_USER_SALES, fetchTechnicianSales);
  yield takeEvery(CREATE_USER, putUser);
  yield takeEvery(UPDATE_USER, patchUser);
  yield takeEvery(RESET_USER_PASSWORD, pathUserPassword);
  yield takeEvery(CREATE_PROFESSION_CATEGORY, putProfessionCategory);
  yield takeEvery(UPDATE_PROFESSION_CATEGORY, patchProfessionCategory);
  yield takeEvery(GET_PROFESSION_CATEGORIES, fetchProfessionCategories);
}

export default userSaga;
