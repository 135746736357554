import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Space, Table, Typography, Upload} from 'antd';
import {ExportOutlined, UserAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {createAd, getAds, updateAd} from 'store/adds/actions';
import FormModal from "../../shared/form-modal";
import {getAdColumns} from "./ad-columns";
import {convertUploadedFileToBase64} from "../../../utils/lib/helpers";
import {type} from "@testing-library/user-event/dist/type";


const Ads = props => {
    const {
        user,
        loading,
        error,
        ads,
        onGetAds,
        onUpdateAd, onCreateAd

    } = props

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [temData, setTempData] = useState({})

    const [form, setForm] = useState({isOpen: false});
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    useEffect(() => {
        onGetAds();
    }, [onGetAds])

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleOnFileSelect = async (file) => {
        // console.log("My File Object::", file)
        // const _file = await convertUploadedFileToBase64(file)
        // setTempData({ ...temData, image: _file });
        return false
    }

    const getFields = (formType) => {
        const sharedFields = [
            {name: "title", label: "Title", required: true, field: Input},
            {name: "description", label: "Description", fieldType: 'text-area', field: Input.TextArea},
        ]

        const fields = {
            create: [...sharedFields,
                {name: 'image', label: "Banner", fieldType: 'attachment', field: Upload.Dragger, onChange: handleOnFileSelect},],
            edit: [...sharedFields]
        }
        return fields[formType]
    }

    const handleOnClose = () => setForm(prevState => ({...prevState, isOpen: false, initialValues: {}}))
    const handleOnUpdateRecord = (record, data) => {
        onUpdateAd(record?.uid, data, handleOnClose);
    }

    const handleOnEditRecord = record => {
        setForm(prevState => ({
            ...prevState,
            isOpen: !prevState?.isOpen,
            action: 'edit',
            initialValues: record,
            title: "Edit Ad",
            btnLabel: "Update",
            fields: getFields('edit'),
            onCancel: handleOnClose,
            onSubmit: handleOnUpdateRecord
        }))
    }

    const handleOnDeleteRecord = (record) => {
        const payload = {archived: true}
        onUpdateAd(record?.uid, payload);
    }

    const handleOnSubmit = async (data) => {
        if(data?.image?.file === null || (typeof data?.image === undefined) || (typeof data?.image === 'undefined')){
            alert("You have to attach a banner image to save this ad.")
            return
        }
        const payload = data
        const _file = await convertUploadedFileToBase64(data?.image?.file)
        console.log("payload:: ", payload)
        payload.image = _file
        payload.created_by = user?.uid;
        onCreateAd(payload, handleOnClose);
    }

    return (
        <Row>
            <Col>
                <Space
                    style={{
                        marginBottom: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Typography.Title level={4} style={{display: 'flex', alignItems: 'center'}}>Ads</Typography.Title>
                    <Space>
                        <Button onClick={() => {
                            setForm(prevState => ({
                                ...prevState,
                                action: 'create',
                                isOpen: !prevState?.isOpen,
                                formType: 'create',
                                title: "New Ad",
                                btnLabel: "Save",
                                fields: getFields('create'),
                                onCancel: handleOnClose,
                                onSubmit: handleOnSubmit
                            }))
                        }} icon={<UserAddOutlined/>}>New Ad</Button>
                        {hasSelected && (
                            <Button onClick={() => {
                            }} icon={<ExportOutlined/>}>Export Data</Button>
                        )}
                    </Space>
                </Space>
                <Table
                    rowKey={'uid'}
                    rowSelection={rowSelection}
                    columns={getAdColumns(sortedInfo, handleOnEditRecord, handleOnDeleteRecord)}
                    dataSource={ads.filter(a => !a?.archived)}
                    onChange={handleChange}
                    loading={loading}/>
            </Col>
            {form?.isOpen && (<FormModal {...form} width={'25%'} loading={loading}/>)}
        </Row>
    )
}

Ads.propTypes = {
    user: PropTypes.any,
    error: PropTypes.any,
    loading: PropTypes.any,
    onGetAds: PropTypes.func,
    onUpdateAd: PropTypes.func,
    onCreateAd: PropTypes.func,
    ads: PropTypes.any
}

const mapStateToProps = ({
                             auth: {user},
                             adsReducer: {ads, loading, error}
                         }) => ({
    user, error, loading, ads
})

const mapDispatchToProps = dispatch => ({
    onGetAds: (params) => dispatch(getAds(params)),
    onCreateAd: (payload, callback) => dispatch(createAd(payload, callback)),
    onUpdateAd: (adId, payload, callback) => dispatch(updateAd(adId, payload, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Ads);
