import {
    CREATE_AD,
    CREATE_AD_FAIL,
    CREATE_AD_SUCCESS,
    GET_ADS,
    GET_ADS_FAIL,
    GET_ADS_SUCCESS,
    UPDATE_AD,
    UPDATE_AD_FAIL,
    UPDATE_AD_SUCCESS
} from './action-types';

// Products actions
export const getAds = payload => ({
  type: GET_ADS,
  payload,
});

export const getAdsSuccess = user => ({
  type: GET_ADS_SUCCESS,
  payload: user,
});

export const getAdsFail = error => ({
  type: GET_ADS_FAIL,
  payload: error,
});
export const createAd = (payload, callback) => ({
  type: CREATE_AD,
  payload: {payload, callback},
});

export const createAdSuccess = user => ({
  type: CREATE_AD_SUCCESS,
  payload: user,
});

export const createAdFail = error => ({
  type: CREATE_AD_FAIL,
  payload: error,
});

export const updateAd = (adId, payload, callback) => ({
  type: UPDATE_AD,
  payload: {adId, payload, callback},
});

export const updateAdSuccess = user => ({
  type: UPDATE_AD_SUCCESS,
  payload: user,
});

export const updateAdFail = error => ({
  type: UPDATE_AD_FAIL,
  payload: error,
});
