import {call, put, takeEvery} from 'redux-saga/effects';

import {changeLanguageFail, changeLanguageSuccess} from './actions';
import {setActiveLanguage} from '../../utils/locales/helpers/language';
import {CHANGE_LANGUAGE} from './action-types';

function* doChangeAppLanguage({payload}) {
  try {
    yield put(changeLanguageSuccess(payload));
    yield call(setActiveLanguage, payload);
  } catch (error) {
    yield put(changeLanguageFail(error));
  }
}

function* appSaga() {
  yield takeEvery(CHANGE_LANGUAGE, doChangeAppLanguage);
}

export default appSaga;
