import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Typography} from 'antd';
import {UserAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import AddProject from './add-project';
import {getProjects, updateProject} from 'store/projects/actions';
import {tablePagination} from 'utils/constants/layout';
import {getProjectColumns} from "./project-columns";
import {Table} from "ant-table-extensions";
import ProjectDetails from "./project-details";


const Projects = props => {

  document.title = 'Emirate Builders | Projects'
  const {
    loading,
    projectList,
    onGetProjects,
    onUpdateProject
  } = props

  const [form, setForm] = useState({isOpen: false})

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [detailView, setDetailView] = useState({show: {detail: false}, product: null})
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetProjects()
  }, [onGetProjects])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleOnEditRecord = record => {
    const payload = {...record};
    payload.categories = record?.categories?.map(c => c?.uid)
    payload.project_manager = record?.project_manager?.uid
    payload.region = record?.region?.uid
    payload.district = record?.district?.uid
    payload.created_by = record?.created_by?.uid

    setForm(prevState => ({
      ...prevState,
      isOpen: !prevState?.isOpen,
      action: 'edit',
      initialValues: payload,
      title: "Edit Project",
      btnLabel: "Update",
    }))
  }

  const handleOnRecordSelected = (record, event) => {
    setDetailView(prevState => ({
      show: {
        detail: !prevState?.show?.detail
      },
      product: record
    }));
  }

  const handleOnDeleteRecord = (record) => {
    const payload = {archived: true}
    onUpdateProject(record?.uid, payload);
  }

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Projects</Typography.Title>
          <Space>
            <Button onClick={() => {
              setForm(prevState => ({ ...form, isOpen: !prevState?.isOpen, action: 'create', title: 'Add Project', btnLabel: 'Save Project'}))
            }} icon={<UserAddOutlined />}>Add Project</Button>
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Type a word to search...",
                style:{width: '15%'}
              }
            }}
            exportable
            exportableProps={{showColumnPicker: true, fileName: 'projects'}}
            pagination={tablePagination}
            rowSelection={rowSelection}
            columns={getProjectColumns(sortedInfo, handleOnRecordSelected, handleOnEditRecord, handleOnDeleteRecord)}
            dataSource={projectList.filter(p => !p?.archived)}
            onChange={handleChange}
            loading={loading} />
      </Col>

      {form?.isOpen && (
          <AddProject
              {...form}
              onCancel={() => setForm({ ...form, isOpen: !form?.isOpen, initialValues: {}})}
          />
      )}
      {detailView?.show?.detail && (
          <ProjectDetails
              isVisible={detailView?.show?.detail}
              project={detailView?.product}
              onClose={handleOnRecordSelected}
          />
      )}
    </Row>
  )
}

Projects.propTypes = {
  error: PropTypes.any,
  loading: PropTypes.any,
  projectList: PropTypes.array,
  onSetSelectedProject: PropTypes.func,
  onGetProjects: PropTypes.func,
  onUpdateProject: PropTypes.func,
}

const mapStateToProps = ({
  projectsReducer: { projectList, loading, error },
}) => ({
   error, loading, projectList
})

const mapDispatchToProps = dispatch => ({
  onUpdateProject: (projectId, payload, callback) => dispatch(updateProject(projectId, payload, callback)),
  onGetProjects: (params) => dispatch(getProjects(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
