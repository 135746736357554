import React, {useEffect, useState} from 'react';
import {Button, Col, Input, InputNumber, Row, Space, Typography} from 'antd';
import {ExportOutlined, UserAddOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {addRewardSettings, getRewardSettings, updateRewardSettings} from 'store/sales-rewards/actions';
import api from 'utils/api/config';
import {GET_REWARD_POINT_VALUE} from 'store/sales-rewards/action-types';
import {UpdateRewardPointValue} from './reward-point';
import {tablePagination} from 'utils/constants/layout';
import FormModal from "../../shared/form-modal";
import {getRewardColumns} from "./reward-money-value-columns";
import {Table} from "ant-table-extensions";
import DrawerForm from "../../shared/drawer-form";
import {useTranslation} from "react-i18next";


const extraArgs =  {
  prefix: 'TZS',
  precision: 2,
  formatter: (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/\$\s?|(,*)/g, '')
}
const Rewards = props => {
  const {
    user,
    loading,
    error,
    rewardSettings,
    onGetRewardSettings,
    onGetRewardPointValue,
    rewardPointValue,
      onCreateRewardSetting,
    onUpdateRewardSetting
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    selectedRewardSetting: null,
    showRewardPointValueForm: false
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [form, setForm] = useState({isOpen: false});
  const {t} = useTranslation()
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getRewardPointValue = async () => {
    try {
      const response = await api(true).get('reward-value/get-value/')
      onGetRewardPointValue(response.data.monetary_value)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    onGetRewardSettings()
    getRewardPointValue()
  }, [onGetRewardSettings, rewardPointValue])


  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const getFields = (formType) => {
    const sharedFields = [
      {name: "points",
        label: "Points",
        required: true,
        fieldType: 'number',
        field: InputNumber,
        extraArgs: {defaultValue: 1}
        },
      {name: "sales_amount", label: "Sales Amount", required: true, fieldType: 'number', field: InputNumber, extraArgs: {...extraArgs}},

      {name: "accrued_points",
        label: "Accrued Points",
        required: true,
        fieldType: 'number',
        field: InputNumber,
        extraArgs: {defaultValue: 1}
      },
      {name: "reward_amount", label: "Reward Amount", required: true, fieldType: 'number', field: InputNumber, extraArgs: {...extraArgs}},
    ]

    const fields = {create: [...sharedFields], edit: [...sharedFields]}
    return fields[formType]
  }

  const handleOnClose = () => setForm(prevState => ({...prevState, isOpen: !prevState?.isOpen, initialValues: {}}))
  const handleOnUpdateRecord = (record, data) => { onUpdateRewardSetting(record?.uid, data, handleOnClose); }

  const handleOnEditRecord = record => {

    setForm(prevState => ({
      ...prevState,
      isOpen: !prevState?.isOpen,
      action: 'edit',
      initialValues: record,
      title: "Edit Reward Setting",
      btnLabel: "Update",
      label: "Update",
      fields: getFields('edit'),
      onClose: handleOnClose,
      onSubmit: handleOnUpdateRecord
    }))
  }

  const handleOnDeleteRecord = (record) => {
    const payload = {archived: true}
    onUpdateRewardSetting(record?.uid, payload);
  }

  const handleOnSubmit = (data) => {
    const payload = {...data}
    payload.created_by = user?.uid;
    onCreateRewardSetting(payload, handleOnClose);
  }

  return (
    <Row>
      <Col lg={24}>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Reward settings</Typography.Title>
          <Space>
            <Button onClick={() => {
              setForm(prevState => ({
                ...prevState,
                action: 'create',
                formType: 'create',
                title: "Add Reward Settings",
                btnLabel: "Save",
                isOpen: !prevState?.isOpen,
                label: t('save'),
                fields: getFields('create'),
                onClose: handleOnClose,
                onSubmit: handleOnSubmit,
              }))
            }} icon={<UserAddOutlined />}>Add Reward Setting</Button>
          </Space>
        </Space>
        <Table
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Type a word to search...",
                style:{width: '15%'}
              }
            }}
            exportable
            exportableProps={{showColumnPicker: true, fileName: 'reward-settings'}}
            pagination={tablePagination} rowSelection={rowSelection}
            columns={getRewardColumns(sortedInfo, handleOnEditRecord, handleOnDeleteRecord)}
            dataSource={rewardSettings.filter(rs => !rs?.archived)}
            onChange={handleChange}
            loading={loading} />
      </Col>

      {form?.isOpen && (
          <DrawerForm
              visible={form?.isOpen}
              fields={form?.fields}
              editing={form?.editing}
              title={form?.title}
              containerStyle={{
                marginTop: 0,
                marginBottom: 8,
              }}
              fieldSize="large"
              loading={loading}
              buttonLabel={form?.label}
              selectedRecord={form?.selectedRecord}
              onClose={form?.onClose}
              onSubmitForm={form?.onSubmit} />
      )}

      <UpdateRewardPointValue
        isOpen={state.showRewardPointValueForm}
        user={user}
        onCancel={() => setState({ ...state, showRewardPointValueForm: false })}
        onCompleteRequest={(v) => {
          setState({ ...state, showRewardPointValueForm: false })
          onGetRewardPointValue(v)
        }}
        rewardPointValue={rewardPointValue}
      />
    </Row>
  )
}

Rewards.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  rewardSettings: PropTypes.array,
  onGetRewardSettings: PropTypes.func,
  onGetRewardPointValue: PropTypes.func,
  onUpdateRewardSetting: PropTypes.func,
  onCreateRewardSetting: PropTypes.func,
  rewardPointValue: PropTypes.any
}

const mapStateToProps = ({
  auth: { user },
  rewardsAndSales: { loading, error, rewardSettings, rewardPointValue }
}) => ({
  user, error, loading, rewardSettings, rewardPointValue
})

const mapDispatchToProps = dispatch => ({
  onGetRewardSettings: params => dispatch(getRewardSettings(params)),
  onCreateRewardSetting: (payload, callback) => dispatch(addRewardSettings(payload, callback)),
  onUpdateRewardSetting: (rewardId, payload, callback) => dispatch(updateRewardSettings(rewardId, payload, callback)),
  onGetRewardPointValue: payload => dispatch({
    type: GET_REWARD_POINT_VALUE,
    payload
  })
})

export default connect(mapStateToProps, mapDispatchToProps)(Rewards);
