import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Space, Typography} from 'antd';
import {FileAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {createProductSize, getProductSizes, updateProductSize} from 'store/products/actions';
import {tablePagination} from 'utils/constants/layout';
import {getSizeColumns} from "./product-size-columns";
import FormModal from "../../shared/form-modal";
import {Table} from "ant-table-extensions";


const ProductSize = props => {
    const {
        user,
        loading,
        error,
        sizes,
        onGetProductSizes,
        onUpdateProductSize, onCreateProductSize
    } = props

    const [form, setForm] = useState({isOpen: false});

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    useEffect(() => {
        onGetProductSizes();
    }, [onGetProductSizes])

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const getFields = (formType) => {
        const sharedFields = [
            {name: "name", label: "Name", required: true, field: Input},
            {name: "dimensions", label: "Dimensions", required: true, field: Input},
            {
                name: "description",
                label: "Description",
                required: true,
                field: Input.TextArea,
            },
        ]

        const fields = {create: [...sharedFields], edit: [...sharedFields]}
        return fields[formType]
    }

    const handleOnClose = () => setForm(prevState => ({...prevState, isOpen: !prevState?.isOpen, initialValues: {}}))
    const handleOnUpdateRecord = (record, data) => {
        onUpdateProductSize(record?.uid, data, handleOnClose);
    }

    const handleOnEditRecord = record => {

        setForm(prevState => ({
            ...prevState,
            isOpen: !prevState?.isOpen,
            action: 'edit',
            initialValues: record,
            title: "Edit Product Size",
            btnLabel: "Update",
            fields: getFields('edit'),
            onCancel: handleOnClose,
            onSubmit: handleOnUpdateRecord
        }))
    }

    const handleOnSubmit = (data) => {
        const payload = {...data}
        payload.created_by = user?.uid;
        onCreateProductSize(payload, handleOnClose);
    }
    const handleOnDeleteRecord = (record) => {
        const payload = {archived: true}
        onUpdateProductSize(record?.uid, payload);
    }

    return (
        <Row>
            <Col>
                <Space
                    style={{
                        marginBottom: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Typography.Title level={4} style={{display: 'flex', alignItems: 'center'}}>Size</Typography.Title>
                    <Space>
                        <Button onClick={() => {
                            setForm(prevState => ({
                                ...prevState,
                                action: 'create',
                                isOpen: !prevState?.isOpen,
                                formType: 'create',
                                title: "Add Product Size",
                                btnLabel: "Save",
                                fields: getFields('create'),
                                onCancel: handleOnClose,
                                onSubmit: handleOnSubmit
                            }))
                        }} icon={<FileAddOutlined/>}>Add Size</Button>
                    </Space>
                </Space>
                <Table
                    rowKey={'uid'}
                    exportable
                    exportableProps={{showColumnPicker: true, fileName: 'product-sizes'}}
                    pagination={tablePagination}
                    rowSelection={rowSelection}
                    columns={getSizeColumns(sortedInfo, handleOnEditRecord, handleOnDeleteRecord)}
                    dataSource={sizes.filter(s => !s?.archived)}
                    onChange={handleChange}
                    loading={loading}/>
            </Col>

            {form?.isOpen && (<FormModal {...form} width={'25%'}/>)}
        </Row>
    )
}

ProductSize.propTypes = {
    sizes: PropTypes.array,
    loading: PropTypes.any,
    error: PropTypes.any,
    user: PropTypes.object,
    onGetProductSizes: PropTypes.func,
    onUpdateProductSize: PropTypes.func,
    onCreateProductSize: PropTypes.func
}


const mapStateToProps = ({
                             auth: {user},
                             products: {sizes, loading, error}
                         }) => ({
    user, error, loading, sizes
})

const mapDispatchToProps = dispatch => ({
    onGetProductSizes: (params) => dispatch(getProductSizes(params)),
    onCreateProductSize: (payload, callback) => dispatch(createProductSize(payload, callback)),
    onUpdateProductSize: (sizeId, payload, callback) => dispatch(updateProductSize(sizeId, payload, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductSize);
