export const ADD_PROJECT = 'ADD_PROJECT';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAIL = 'ADD_PROJECT_FAIL';

export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAIL = 'UPDATE_PROJECT_FAIL';

export const GET_PROJECTS = 'GET_PROJECT';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECT_SUCCESS';
export const GET_PROJECTS_FAIL = 'GET_PROJECT_FAIL';

export const ADD_PROJECT_CATEGORY = 'ADD_PROJECT_CATEGORY';
export const ADD_PROJECT_CATEGORY_SUCCESS = 'ADD_PROJECT_CATEGORY_SUCCESS';
export const ADD_PROJECT_CATEGORY_FAIL = 'ADD_PROJECT_CATEGORY_FAIL';

export const UPDATE_PROJECT_CATEGORY = 'UPDATE_PROJECT_CATEGORY';
export const UPDATE_PROJECT_CATEGORY_SUCCESS = 'UPDATE_PROJECT_CATEGORY_SUCCESS';
export const UPDATE_PROJECT_CATEGORY_FAIL = 'UPDATE_PROJECT_CATEGORY_FAIL';

export const GET_PROJECTS_CATEGORY = 'GET_PROJECTS_CATEGORY';
export const GET_PROJECTS_CATEGORY_SUCCESS = 'GET_PROJECTS_CATEGORY_SUCCESS';
export const GET_PROJECTS_CATEGORY_FAIL = 'GET_PROJECTS_CATEGORY_FAIL';


export const ADD_PROJECT_IMAGES = 'ADD_PROJECT_IMAGES';
export const ADD_PROJECT_IMAGES_SUCCESS = 'ADD_PROJECT_IMAGES_SUCCESS';
export const ADD_PROJECT_IMAGES_FAIL = 'ADD_PROJECT_IMAGES_FAIL';

export const GET_PROJECT_IMAGES = 'GET_PROJECT_IMAGES';
export const GET_PROJECT_IMAGES_SUCCESS = 'GET_PROJECT_IMAGES_SUCCESS';
export const GET_PROJECT_IMAGES_FAIL = 'GET_PROJECT_IMAGES_FAIL';

export const SET_SELECTED_PROJECT = 'SET_SELECTED_PROJECT'
