import {LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_USER,} from './action-types';

export const loginUser = (credentials, replace) => ({
  type: LOGIN_USER,
  payload: { credentials, replace },
});

export const loginUserSuccess = payload => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const loginUserFail = error => ({
  type: LOGIN_USER_FAIL,
  payload: error,
});

export const logoutUser = () => ({
  type: LOGOUT_USER
});
