import React, {useEffect, useState} from 'react';
import {Button, Col, notification, Row, Space, Table, Typography} from 'antd';
import {EditOutlined, UserAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {getUsers} from 'store/users/actions';
import AddUser from '../users/add-user';
import {getDistricts, getRegions} from 'store/locations/actions';
import api from 'utils/api/config';
import {getUserFullName} from 'utils/lib/helpers';
import {tablePagination} from 'utils/constants/layout';


const Users = props => {

  document.title = 'Emirate Builders | Users'
  const {
    onGetUserList,
    userList,
    user,
    regions,
    districts,
    onGetRegions,
    onGetDistricts,
    loading,
      pagination
  } = props

  const _notification = notification.useNotification()[0]
  const _notification_context_handler = notification.useNotification()[1]

  const [state, setState] = useState({
    showFormModal: false,
    selectedUser: null
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize, setPageSize] = useState(50)
  const [tblPagination, setTblPagination] = useState({limit: pageSize, offset: 0})

  const handleChange = (pagination, filters, sorter) => {
    // console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetUserList({designation: 'administrator', ...tblPagination})
    onGetRegions()
  }, [tblPagination])

  const handleOnPageChange = page => {
    setTblPagination(prevState => ({
      ...prevState,
      offset: (page - 1) * pageSize
    }))
  }

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      key: 'first_name',
      sorter: (a, b) => a.first_name.length < b.first_name.length,
      sortOrder: sortedInfo.columnKey === 'first_name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      key: 'last_name',
      sorter: (a, b) => a.last_name.length < b.last_name.length,
      sortOrder: sortedInfo.columnKey === 'last_name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true,
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone_number',
      key: 'phone_number',
      ellipsis: true,
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (
          <React.Fragment>
            <Typography.Link onClick={(e) => {
              setState({ ...state, showFormModal: true, selectedUser: record })
            }} style={{ color: '#cbb52f', marginRight: 10 }}
            >
              <EditOutlined /> Edit
            </Typography.Link>
            <Typography.Link onClick={() => {
              if (confirm(`Are you sure you want to delete ${getUserFullName(record)}`)) archiveUser(record.uid, () => onGetUserList('administrator'))
            }} style={{ color: '#ff0000' }}>
              Delete
            </Typography.Link>
          </React.Fragment>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    // console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Users</Typography.Title>
          <Space>
            <Button onClick={() => {
              setState({ ...state, showFormModal: true, selectedUser: null })
            }} icon={<UserAddOutlined />}>Add User</Button>
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
            pagination={{
              total: pagination?.count,
              defaultPageSize: 50,
              onChange: handleOnPageChange,
            }}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={userList?.filter( u => !u.archived)}
            onChange={handleChange}
            loading={loading} />
      </Col>
      <>
        {_notification_context_handler}
      </>
      <AddUser
        designation='administrator'
        isOpen={state.showFormModal}
        onCancel={() => setState({ ...state, showFormModal: false })}
        user={user}
        regions={regions}
        districts={districts}
        onGetDistricts={onGetDistricts}
        onCompleteRequest={(type) => {
          setState({ ...state, showFormModal: false })
          onGetUserList()
          _notification[type]({
            message: `Create Agent Success`,
            description:
              `You have successfully created the Agent successfully.`,
          });
        }}
        profileToEdit={state.selectedUser}
      />
    </Row>
  )
}

Users.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  userList: PropTypes.any,
  error: PropTypes.any,
  onGetUserList: PropTypes.any,
  onGetDistricts: PropTypes.any,
  onGetRegions: PropTypes.any,
  regions: PropTypes.any,
  pagination: PropTypes.any,
  districts: PropTypes.any
}

const mapStateToProps = ({
  auth: { user },
  users: { userList, agentList, error, loading , pagination},
  locations: { regions, districts }
}) => ({
  user, userList, error, loading, regions, districts, agentList, pagination
})

const mapDispatchToProps = dispatch => ({
  onGetUserList: (params) => dispatch(getUsers(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Users);


export async function archiveUser(uid, callback) {
  const response = await api(true).put(`user/${uid}/update/`, { is_active: false, archived: true })
  callback()
  return response.status;
}
