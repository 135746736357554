import {
    ADD_PRODUCT,
    ADD_PRODUCT_CATEGORY,
    ADD_PRODUCT_CATEGORY_FAIL,
    ADD_PRODUCT_CATEGORY_SUCCESS,
    ADD_PRODUCT_COLOR,
    ADD_PRODUCT_COLOR_FAIL,
    ADD_PRODUCT_COLOR_SUCCESS,
    ADD_PRODUCT_FAIL,
    ADD_PRODUCT_IMAGES,
    ADD_PRODUCT_IMAGES_FAIL,
    ADD_PRODUCT_IMAGES_SUCCESS,
    ADD_PRODUCT_SIZE,
    ADD_PRODUCT_SIZE_FAIL,
    ADD_PRODUCT_SIZE_SUCCESS,
    ADD_PRODUCT_SUCCESS,
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_CATEGORIES_FAIL,
    GET_PRODUCT_CATEGORIES_SUCCESS,
    GET_PRODUCT_COLORS,
    GET_PRODUCT_COLORS_FAIL,
    GET_PRODUCT_COLORS_SUCCESS,
    GET_PRODUCT_IMAGES,
    GET_PRODUCT_IMAGES_FAIL,
    GET_PRODUCT_IMAGES_SUCCESS,
    GET_PRODUCT_SIZES,
    GET_PRODUCT_SIZES_FAIL,
    GET_PRODUCT_SIZES_SUCCESS,
    GET_PRODUCTS,
    GET_PRODUCTS_FAIL,
    GET_PRODUCTS_SUCCESS,
    SET_SELECTED_PRODUCT,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_CATEGORY,
    UPDATE_PRODUCT_CATEGORY_FAIL,
    UPDATE_PRODUCT_CATEGORY_SUCCESS,
    UPDATE_PRODUCT_COLOR,
    UPDATE_PRODUCT_COLOR_FAIL,
    UPDATE_PRODUCT_COLOR_SUCCESS,
    UPDATE_PRODUCT_FAIL,
    UPDATE_PRODUCT_SIZE,
    UPDATE_PRODUCT_SIZE_FAIL,
    UPDATE_PRODUCT_SIZE_SUCCESS,
    UPDATE_PRODUCT_SUCCESS
} from './action-types';

// Products actions
export const getProducts = payload => ({
  type: GET_PRODUCTS,
  payload,
});

export const getProductsSuccess = user => ({
  type: GET_PRODUCTS_SUCCESS,
  payload: user,
});

export const getProductsFail = error => ({
  type: GET_PRODUCTS_FAIL,
  payload: error,
});

export const createProduct = (payload, callback) => ({
  type: ADD_PRODUCT,
  payload: {payload, callback},
});

export const createProductSuccess = user => ({
  type: ADD_PRODUCT_SUCCESS,
  payload: user,
});

export const createProductFail = error => ({
  type: ADD_PRODUCT_FAIL,
  payload: error,
});

export const updateProduct = (productId, payload, callback) => ({
  type: UPDATE_PRODUCT,
  payload: {productId, payload, callback},
});

export const updateProductSuccess = user => ({
  type: UPDATE_PRODUCT_SUCCESS,
  payload: user,
});

export const updateProductFail = error => ({
  type: UPDATE_PRODUCT_FAIL,
  payload: error,
});

// Product categories actions
export const createProductCategory = (payload, callback) => ({
  type: ADD_PRODUCT_CATEGORY,
  payload: {payload, callback},
});

export const createProductCategorySuccess = payload => ({
  type: ADD_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const createProductCategoryFail = payload => ({
  type: ADD_PRODUCT_CATEGORY_FAIL,
  payload,
});
export const updateProductCategory = (categoryId, payload, callback) => ({
  type: UPDATE_PRODUCT_CATEGORY,
  payload: {categoryId, payload, callback},
});

export const updateProductCategorySuccess = payload => ({
  type: UPDATE_PRODUCT_CATEGORY_SUCCESS,
  payload,
});

export const updateProductCategoryFail = payload => ({
  type: UPDATE_PRODUCT_CATEGORY_FAIL,
  payload,
});

export const getProductCategories = payload => ({
  type: GET_PRODUCT_CATEGORIES,
  payload,
});

export const getProductCategoriesSuccess = payload => ({
  type: GET_PRODUCT_CATEGORIES_SUCCESS,
  payload,
});

export const getProductCategoriesFail = payload => ({
  type: GET_PRODUCT_CATEGORIES_FAIL,
  payload,
});

export const getProductColors = payload => ({
  type: GET_PRODUCT_COLORS,
  payload,
});

export const getProductColorsSuccess = payload => ({
  type: GET_PRODUCT_COLORS_SUCCESS,
  payload,
});

export const getProductColorsFail = payload => ({
  type: GET_PRODUCT_COLORS_FAIL,
  payload,
});

export const getProductSizes = payload => ({
  type: GET_PRODUCT_SIZES,
  payload,
});

export const getProductSizesSuccess = payload => ({
  type: GET_PRODUCT_SIZES_SUCCESS,
  payload,
});

export const getProductSizesFail = payload => ({
  type: GET_PRODUCT_SIZES_FAIL,
  payload,
});

export const getProductImages = payload => ({
  type: GET_PRODUCT_IMAGES,
  payload,
});

export const getProductImagesSuccess = payload => ({
  type: GET_PRODUCT_IMAGES_SUCCESS,
  payload,
});

export const getProductImagesFail = payload => ({
  type: GET_PRODUCT_IMAGES_FAIL,
  payload,
});


export const createProductImage = (payload, callback) => ({
  type: ADD_PRODUCT_IMAGES,
  payload: {payload, callback},
});

export const createProductImageSuccess = payload => ({
  type: ADD_PRODUCT_IMAGES_SUCCESS,
  payload,
});

export const createProductImageFail = payload => ({
  type: ADD_PRODUCT_IMAGES_FAIL,
  payload,
});


export const createProductColor = (payload, callback) => ({
  type: ADD_PRODUCT_COLOR,
  payload: {payload, callback},
});

export const createProductColorSuccess = payload => ({
  type: ADD_PRODUCT_COLOR_SUCCESS,
  payload,
});

export const createProductColorFail = payload => ({
  type: ADD_PRODUCT_COLOR_FAIL,
  payload,
});



export const updateProductColor = (colorId, payload, callback) => ({
  type: UPDATE_PRODUCT_COLOR,
  payload: {colorId, payload, callback},
});

export const updateProductColorSuccess = payload => ({
  type: UPDATE_PRODUCT_COLOR_SUCCESS,
  payload,
});

export const updateProductColorFail = payload => ({
  type: UPDATE_PRODUCT_COLOR_FAIL,
  payload,
});



export const updateProductSize = (sizeId, payload, callback) => ({
  type: UPDATE_PRODUCT_SIZE,
  payload: {sizeId, payload, callback},
});

export const updateProductSizeSuccess = payload => ({
  type: UPDATE_PRODUCT_SIZE_SUCCESS,
  payload,
});

export const updateProductSizeFail = payload => ({
  type: UPDATE_PRODUCT_SIZE_FAIL,
  payload,
});


export const createProductSize =( payload, callback) => ({
  type: ADD_PRODUCT_SIZE,
  payload: {payload, callback},
});

export const createProductSizeSuccess = payload => ({
  type: ADD_PRODUCT_SIZE_SUCCESS,
  payload,
});

export const createProductSizeFail = payload => ({
  type: ADD_PRODUCT_SIZE_FAIL,
  payload,
});

export const setSelectedProduct = payload => ({
  type: SET_SELECTED_PRODUCT,
  payload
})
