import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Form, notification, Row, Space, Typography} from 'antd';
import {ExportOutlined, FilterOutlined, SearchOutlined} from '@ant-design/icons';
import {Table} from 'ant-table-extensions';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {exportUsers, getUsers, updateUser} from 'store/users/actions';
import {getDistricts, getRegions, getWards} from 'store/locations/actions';
import FilterForm from 'components/shared/filter-form';
import {getUserFullName} from 'utils/lib/helpers';
import {tablePagination} from 'utils/constants/layout';
import {getUserColumns} from "./user-columns";
import TechnicianProfile from "./profile";
import ImagePreview from "./image-preview";
import SalesList from "./sales-list";
import moment from "moment";
import Search from "antd/es/input/Search";
import {exportUserList} from "../../../utils/api";

const Technicians = props => {
  const {
    onGetUserList,
    techniciansList,
    user,
    regions,
    wards,
    districts,
    onGetRegions,
    onGetDistricts,
    onGetWards,
    loading,
      pagination,
      onUpdateTechnician
  } = props

  const _notification_context_handler = notification.useNotification()[1]

  const [state, setState] = useState({
    showFilter: false,
    selectedUser: null,
    showIdCard: false
  })

  const [showProfile, setShowProfile] = useState({show: {profile: false, idPreview: false}, technician: null})

  const canvasRef = useRef()

  const [filteredInfo, setFilteredInfo] = useState({});
  const [exporting, setExporting] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [pageSize, setPageSize] = useState(50)
  const [tblPagination, setTblPagination] = useState({limit: pageSize, offset: 0})
  const [params, setParams] = useState({designation: 'technician'})
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    if(params?.q){
      onGetUserList({...params})
    }else{
      onGetUserList({...params, ...tblPagination})
    }
    onGetRegions()
  }, [tblPagination, params])

  const exportUsers = (p) => {
    setExporting(true)
    exportUserList({...p, type: 'technician'})
        .then(response => {
          const type = response.headers['content-type']
          const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(blob)
          link.download = `users-export-${p?.startDate}-${p?.endDate}.xlsx`
          link.click()
          setExporting(false)
        })
  }

  const handleOnPageChange = page => {
    setTblPagination(prevState => ({
      ...prevState,
      offset: (page - 1) * pageSize
    }))
  }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const handleOnRecordSelected = (record, event) => {
    console.log("Record:::", record)
    setShowProfile(prevState => ({
        show: {
          [event]: !prevState?.show[event]
        },
        technician: record
    }));
  }

  const handleOnClose = (key) => {
    setShowProfile(prevState => ({
      ...prevState,
      show: {
        [key]: !prevState?.show[key]
      },
      technician: null
    }))
  }

  const handleOnDeleteRecord = record => {
    const payload = {is_active: false, archived: true}
    onUpdateTechnician(record?.uid, payload, 'technician')
  }

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Technicians</Typography.Title>
          <Space>
            <Search  onSearch={(v, e) => setParams(prevState => ({...prevState, q: v}))} placeholder="Type and press ENTER to search..." style={{width: 350}} loading={loading}/>
            <Button onClick={() => { setState({ ...state, showFilter: true, title: "Filter", action: 'FILTER' }) }} icon={<FilterOutlined />}>Filter</Button>
            <Button onClick={() => { setState({ ...state, showFilter: true, title: "Export", action: 'EXPORT',  }) }} icon={<ExportOutlined />} loading={exporting}>{exporting ? 'Exporting...' : 'Export'}</Button>
          </Space>
        </Space>
        <Table
          pagination={{
            total: pagination?.count,
            defaultPageSize: pageSize,
            onChange: handleOnPageChange,
          }}
          page
          rowKey={'uid'}
          rowSelection={rowSelection}
          columns={getUserColumns(sortedInfo, handleOnRecordSelected, handleOnDeleteRecord)}
          dataSource={techniciansList.filter( t => !t.archived)}
          onChange={handleChange}
          loading={loading}
        />
      </Col>
      <FilterForm
        filterLocation
        filterDates
        title={`${state?.title} Technicians`}
        regions={regions}
        districts={districts}
        wards={wards}
        onGetDistricts={onGetDistricts}
        onGetWards={onGetWards}
        openDrawer={state.showFilter}
        onCloseDrawer={() => setState({ ...state, showFilter: false })}
        onSubmitFilterOptions={(data) => {
          setState({ ...state, showFilter: false });
          let payload = {
            ...data
          }
          if(payload.date) {
            payload = {...payload,
              startDate: moment(data.date[0].$d.toLocaleDateString()).format('DD/MM/YYYY'),
              endDate: moment(data.date[1].$d.toLocaleDateString()).format('DD/MM/YYYY'),}
          }
          delete data.date
          if(state?.action === 'FILTER') {
            setParams(prevState => ({...prevState, ...payload}))
          }else if(state?.action === 'EXPORT') {
            // setExportParams(prevState => ({...prevState, ...payload}))
            exportUsers(payload)
          }
        }}
      />

      {showProfile?.show?.idPreview && (
          <ImagePreview
            imgSrc={showProfile?.technician?.id_card_image}
            canDownload
            fileName={getUserFullName(showProfile?.technician)}
            visible={showProfile?.show?.idPreview}
            onVisibleChange={handleOnClose}
          />)}

      {showProfile?.show?.profile && (
          <TechnicianProfile
              isVisible={showProfile?.show?.profile}
              technician={showProfile?.technician}
              onClose={handleOnClose}
          />
      )}

      {showProfile?.show?.sales && (
          <SalesList
              title={'Technician'}
              isVisible={showProfile?.show?.sales}
              user={showProfile?.technician}
              onClose={handleOnClose}
          />
      )}
    </Row>
  )
}

Technicians.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  techniciansList: PropTypes.any,
  error: PropTypes.any,
  onGetUserList: PropTypes.any,
  onUpdateTechnician: PropTypes.any,
  onGetDistricts: PropTypes.any,
  onGetWards: PropTypes.any,
  onGetRegions: PropTypes.any,
  regions: PropTypes.any,
  pagination: PropTypes.any,
  districts: PropTypes.any,
  wards: PropTypes.any
}

const mapStateToProps = ({
  auth: { user },
  users: { techniciansList, pagination, error, loading },
  locations: { regions, districts, wards }
}) => ({
  user, error, loading, regions, districts, wards, techniciansList, pagination
})

const mapDispatchToProps = dispatch => ({
  onGetUserList: (params) => dispatch(getUsers(params)),
  // onExportUserList: (params) => dispatch(exportUsers(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
  onGetWards: params => dispatch(getWards(params)),
  onUpdateTechnician: (userId, payload, userType, callback) => dispatch(updateUser(userId, payload, userType, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Technicians);
