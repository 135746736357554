import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Typography} from 'antd';
import {Table} from 'ant-table-extensions';
import {ExportOutlined, FilterOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {getSales} from 'store/sales-rewards/actions';
import FilterForm from 'components/shared/filter-form';
import {getDistricts, getRegions, getWards} from 'store/locations/actions';
import {tablePagination} from 'utils/constants/layout';
import {salesColumns} from "./sales-columns";
import moment from "moment";
import {exportSalesList, exportUserList} from "../../../utils/api";


const Sales = props => {

  document.title = 'Emirate Builders | Sales'
  const {
    user,
    onGetSales,
    sales,
    loading,
    error,
    districts,
    regions,
    onGetDistricts,
    onGetRegions,
      pagination,
      wards,
      onGetWards
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    showFilter: false
  })

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pageSize, setPageSize] = useState(50)
    const [tblPagination, setTblPagination] = useState({limit: pageSize, offset: 0})
    const [params, setParams] = useState({})
    const [exporting, setExporting] = useState(false);
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
  };

  useEffect(() => {
    onGetSales({...tblPagination, ...params})
    onGetRegions()
  }, [onGetSales, onGetRegions, tblPagination, params])

    const handleOnPageChange = page => {
        setTblPagination(prevState => ({
            ...prevState,
            offset: (page - 1) * pageSize
        }))
    }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;



    const exportSales = (p) => {
        setExporting(true)
        exportSalesList(p)
            .then(response => {
                const type = response.headers['content-type']
                const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `sales-export-${p?.startDate}-${p?.endDate}.xlsx`
                link.click()
                setExporting(false)
            })
    }

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Sales</Typography.Title>
          <Space>
              <Button onClick={() => { setState({ ...state, showFilter: true, title: "Filter", action: 'FILTER' }) }} icon={<FilterOutlined />}>Filter</Button>
              <Button onClick={() => { setState({ ...state, showFilter: true, title: "Export", action: 'EXPORT',  }) }} icon={<ExportOutlined />} loading={exporting}>{exporting ? 'Exporting...' : 'Export'}</Button>
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
          pagination={{
              total: pagination?.count,
              defaultPageSize: pageSize,
              onChange: handleOnPageChange,
          }}
          rowSelection={rowSelection}
          columns={salesColumns}
          dataSource={sales}
          onChange={handleChange}
          loading={loading}
        />
      </Col>
      <FilterForm
        filterLocation
        filterDates
        regions={regions}
        wards={wards}
        districts={districts}
        onGetDistricts={onGetDistricts}
        onGetWards={onGetWards}
        title='Sales'
        openDrawer={state.showFilter}
        onCloseDrawer={() => setState({ ...state, showFilter: false })}
        onSubmitFilterOptions={(v) => {
          setState({ ...state, showFilter: false })
          let payload = {}
          if (v.date) payload = {
            ...payload,
            startDate: moment(v.date[0].$d.toLocaleDateString()).format('DD/MM/YYYY'),
            endDate: moment(v.date[1].$d.toLocaleDateString()).format('DD/MM/YYYY'),
          }
          if (v.region) payload = {
            ...payload,
            region: v.region
          }
          if (v.district) payload = {
            ...payload,
            district: v.district
          }

            if(state?.action === 'FILTER') {
                setParams(prevState => ({...prevState, ...payload}))
            }else if(state?.action === 'EXPORT') {
                exportSales(payload)
            }
        }}
      />
    </Row>
  )
}

Sales.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  sales: PropTypes.array,
  onGetSales: PropTypes.func,
  regions: PropTypes.any,
  wards: PropTypes.any,
  districts: PropTypes.any,
  onGetDistricts: PropTypes.any,
  onGetRegions: PropTypes.any,
  onGetWards: PropTypes.any,
  pagination: PropTypes.any,
}

const mapStateToProps = ({
  auth: { user },
  rewardsAndSales: { loading, error, sales, pagination },
  locations: { regions, districts, wards }
}) => ({
  user, error, loading, sales, regions, districts, wards, pagination
})

const mapDispatchToProps = dispatch => ({
  onGetSales: params => dispatch(getSales(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
  onGetWards: params => dispatch(getWards(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Sales);
