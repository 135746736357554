import {call, put, takeEvery} from 'redux-saga/effects';
import {
    ADD_PRODUCT,
    ADD_PRODUCT_CATEGORY,
    ADD_PRODUCT_COLOR,
    ADD_PRODUCT_IMAGES,
    ADD_PRODUCT_SIZE,
    GET_PRODUCT_CATEGORIES,
    GET_PRODUCT_COLORS,
    GET_PRODUCT_IMAGES,
    GET_PRODUCT_SIZES,
    GET_PRODUCTS,
    UPDATE_PRODUCT,
    UPDATE_PRODUCT_CATEGORY,
    UPDATE_PRODUCT_COLOR,
    UPDATE_PRODUCT_SIZE,
} from './action-types';
import {
    createProductCategoryFail,
    createProductCategorySuccess,
    createProductColorFail,
    createProductColorSuccess,
    createProductFail,
    createProductImageFail,
    createProductImageSuccess,
    createProductSizeFail,
    createProductSizeSuccess,
    createProductSuccess,
    getProductCategoriesFail,
    getProductCategoriesSuccess,
    getProductColorsFail,
    getProductColorsSuccess,
    getProductImagesFail,
    getProductImagesSuccess,
    getProductsFail,
    getProductSizesFail,
    getProductSizesSuccess,
    getProductsSuccess,
    updateProductCategoryFail,
    updateProductCategorySuccess,
    updateProductColorFail,
    updateProductColorSuccess,
    updateProductFail,
    updateProductSizeFail,
    updateProductSizeSuccess,
    updateProductSuccess,
} from './actions';
import {extractErrorMessage} from '../../utils/lib/helpers';
import {
    addProduct,
    addProductCategory,
    addProductColor,
    addProductImages,
    addProductSize,
    getProductCategories,
    getProductColors,
    getProductImages,
    getProducts,
    getProductSizes,
    updateProduct,
    updateProductCategory,
    updateProductColor,
    updateProductSize
} from 'utils/api';

function* fetchProducts({ payload }) {
  try {
    const response = yield call(getProducts, payload);
    yield put(getProductsSuccess(response.data.results));
  } catch (error) {
    yield put(getProductsFail(extractErrorMessage(error)));
  }
}

function* createProduct({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProduct, payload);
    yield put(createProductSuccess(response.data));
    callback && callback()
  } catch (error) {
    yield put(createProductFail(extractErrorMessage(error)));
  }
}

function* putProduct({ payload: {productId, payload, callback} }) {
  try {
    const response = yield call(updateProduct, {productId, payload});
    yield put(updateProductSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProductFail(extractErrorMessage(error)));
  }
}

function* fetchProductCategories({ payload }) {
  try {
    const response = yield call(getProductCategories, payload);
    yield put(getProductCategoriesSuccess(response.data.results));
  } catch (error) {
    yield put(getProductCategoriesFail(extractErrorMessage(error)));
  }
}

function* createProductCategory({ payload: {payload, callback}}) {
  try {
    const response = yield call(addProductCategory, payload);
    yield put(createProductCategorySuccess(response.data));
    callback && callback()
  } catch (error) {
    yield put(createProductCategoryFail(extractErrorMessage(error)));
  }
}

function* fetchProductColors({ payload }) {
  try {
    const response = yield call(getProductColors, payload);
    yield put(getProductColorsSuccess(response.data.results));
  } catch (error) {
    yield put(getProductColorsFail(extractErrorMessage(error)));
  }
}

function* fetchProductImages({ payload }) {
  try {
    const response = yield call(getProductImages, payload);
    yield put(getProductImagesSuccess(response.data.results));
  } catch (error) {
    yield put(getProductImagesFail(extractErrorMessage(error)));
  }
}

function* createProductColor({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProductColor, payload);
    yield put(createProductColorSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProductColorFail(extractErrorMessage(error)));
  }
}

function* createProductImages({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProductImages, payload);
    yield put(createProductImageSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProductImageFail(extractErrorMessage(error)));
  }
}

function* putProductColor({ payload: {colorId, payload, callback} }) {
  try {
    const response = yield call(updateProductColor, {colorId, payload});
    yield put(updateProductColorSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProductColorFail(extractErrorMessage(error)));
  }
}

function* putProductSize({ payload: {sizeId, payload, callback} }) {
  try {
    const response = yield call(updateProductSize, {sizeId, payload});
    yield put(updateProductSizeSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProductSizeFail(extractErrorMessage(error)));
  }
}

function* putProductCategory({ payload: {categoryId, payload, callback} }) {
  try {
    const response = yield call(updateProductCategory, {categoryId, payload});
    yield put(updateProductCategorySuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProductCategoryFail(extractErrorMessage(error)));
  }
}

function* fetchProductSizes({ payload }) {
  try {
    const response = yield call(getProductSizes, payload);
    yield put(getProductSizesSuccess(response.data.results));
  } catch (error) {
    yield put(getProductSizesFail(extractErrorMessage(error)));
  }
}

function* createProductSize({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProductSize, payload);
    yield put(createProductSizeSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProductSizeFail(extractErrorMessage(error)));
  }
}

function* productSaga() {
  yield takeEvery(GET_PRODUCTS, fetchProducts);
  yield takeEvery(ADD_PRODUCT, createProduct);
  yield takeEvery(GET_PRODUCT_CATEGORIES, fetchProductCategories);
  yield takeEvery(ADD_PRODUCT_CATEGORY, createProductCategory);
  yield takeEvery(ADD_PRODUCT_COLOR, createProductColor);
  yield takeEvery(UPDATE_PRODUCT_COLOR, putProductColor);
  yield takeEvery(UPDATE_PRODUCT, putProduct);
  yield takeEvery(UPDATE_PRODUCT_SIZE, putProductSize);
  yield takeEvery(UPDATE_PRODUCT_CATEGORY, putProductCategory);
  yield takeEvery(ADD_PRODUCT_SIZE, createProductSize);
  yield takeEvery(GET_PRODUCT_COLORS, fetchProductColors);
  yield takeEvery(GET_PRODUCT_SIZES, fetchProductSizes);
  yield takeEvery(GET_PRODUCT_IMAGES, fetchProductImages);
  yield takeEvery(ADD_PRODUCT_IMAGES, createProductImages);
}

export default productSaga;
