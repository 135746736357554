import {
    ADD_REWARD_SETTINGS,
    ADD_REWARD_SETTINGS_FAIL,
    ADD_REWARD_SETTINGS_SUCCESS,
    GET_AWARDED_TECHNICIANS,
    GET_AWARDED_TECHNICIANS_FAIL,
    GET_AWARDED_TECHNICIANS_SUCCESS,
    GET_REWARD_SETTINGS,
    GET_REWARD_SETTINGS_FAIL,
    GET_REWARD_SETTINGS_SUCCESS,
    GET_SALES,
    GET_SALES_FAIL,
    GET_SALES_SUCCESS,
    UPDATE_REWARD_SETTING,
    UPDATE_REWARD_SETTING_FAIL,
    UPDATE_REWARD_SETTING_SUCCESS,
} from './action-types';

// fetch Reward settings actions
export const getRewardSettings = payload => ({
  type: GET_REWARD_SETTINGS,
  payload,
});

export const getRewardSettingsSuccess = user => ({
  type: GET_REWARD_SETTINGS_SUCCESS,
  payload: user,
});

export const getRewardSettingsFail = error => ({
  type: GET_REWARD_SETTINGS_FAIL,
  payload: error,
});

// add Reward settings actions
export const addRewardSettings = (payload, callback) => ({
  type: ADD_REWARD_SETTINGS,
  payload: {payload, callback},
});

export const addRewardSettingsSuccess = user => ({
  type: ADD_REWARD_SETTINGS_SUCCESS,
  payload: user,
});

export const addRewardSettingsFail = error => ({
  type: ADD_REWARD_SETTINGS_FAIL,
  payload: error,
});
export const updateRewardSettings = (rewardId, payload, callback) => ({
  type: UPDATE_REWARD_SETTING,
  payload: {rewardId, payload, callback},
});

export const updateRewardSettingsSuccess = reward => ({
  type: UPDATE_REWARD_SETTING_SUCCESS,
  payload: reward,
});

export const updateRewardSettingsFail = error => ({
  type: UPDATE_REWARD_SETTING_FAIL,
  payload: error,
});

// fetch sales actions
export const getSales = payload => ({
  type: GET_SALES,
  payload,
});

export const getSalesSuccess = user => ({
  type: GET_SALES_SUCCESS,
  payload: user,
});

export const getSalesFail = error => ({
  type: GET_SALES_FAIL,
  payload: error,
});

// Awarded Technicians
export const getAwards = payload => ({
  type: GET_AWARDED_TECHNICIANS,
  payload,
});

export const getAwardsSuccess = user => ({
  type: GET_AWARDED_TECHNICIANS_SUCCESS,
  payload: user,
});

export const getAwardsFail = error => ({
  type: GET_AWARDED_TECHNICIANS_FAIL,
  payload: error,
});
