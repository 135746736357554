import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import React from 'react';
import {Image, Space} from 'antd';
import PropTypes from "prop-types";


const ImagePreview = props => {
    const {canDownload, imgSrc, visible, onVisibleChange, fileName} = props;

    const handleOnDownload = () => {
        fetch(imgSrc, {
            method: 'GET',
        })
        .then((response) => {
            response.arrayBuffer().then(function (buffer) {
                const url = window.URL.createObjectURL(new Blob([buffer]));
                const link = document.createElement("a");
                link.href = url;
                link.download = `${fileName}.png`;
                link.click();
            });
        })
        .catch((err) => console.log("Error::", err))
    }
    return(
        <Image
            width={400}
            style={{display: 'none'}}
            src={imgSrc}
            preview={{
                toolbarRender: (
                    _,
                    {
                        transform: { scale },
                        actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
                    },
                ) => (
                    <Space size={12} className="toolbar-wrapper">
                        {canDownload && <DownloadOutlined onClick={handleOnDownload} /> }
                        <SwapOutlined rotate={90} onClick={onFlipY} />
                        <SwapOutlined onClick={onFlipX} />
                        <RotateLeftOutlined onClick={onRotateLeft} />
                        <RotateRightOutlined onClick={onRotateRight} />
                        <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
                        <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
                    </Space>
                ),
                scaleStep: 0.1,
                visible: visible,
                onVisibleChange: v => onVisibleChange(v)
            }}
        />
    )
}

ImagePreview.propTypes = {
    onVisibleChange: PropTypes.func,
    onDownload: PropTypes.func,
    visible: PropTypes.bool,
    canDownload: PropTypes.bool,
    fileName: PropTypes.string,
    imgSrc: PropTypes.string.isRequired
}

export default ImagePreview;

