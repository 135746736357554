import {call, put, takeEvery} from 'redux-saga/effects';
import {
    ADD_PROJECT,
    ADD_PROJECT_CATEGORY,
    ADD_PROJECT_IMAGES,
    GET_PROJECT_IMAGES,
    GET_PROJECTS,
    GET_PROJECTS_CATEGORY,
    UPDATE_PROJECT,
    UPDATE_PROJECT_CATEGORY,
} from './action-types';
import {
    createProjectCategoryFail,
    createProjectCategorySuccess,
    createProjectFail,
    createProjectImageFail,
    createProjectImageSuccess,
    createProjectSuccess,
    getProjectCategoriesFail,
    getProjectCategoriesSuccess,
    getProjectImagesFail,
    getProjectImagesSuccess,
    getProjectsFail,
    getProjectsSuccess,
    updateProjectCategoryFail,
    updateProjectCategorySuccess,
    updateProjectFail,
    updateProjectSuccess,
} from './actions';
import {extractErrorMessage} from '../../utils/lib/helpers';
import {
    addProject,
    addProjectCategory,
    addProjectImages,
    getProjectCategories,
    getProjectImages,
    getProjects,
    updateProject,
    updateProjectCategory
} from 'utils/api';

function* fetchProjects({ payload }) {
  try {
    const response = yield call(getProjects, payload);
    yield put(getProjectsSuccess(response.data.results));
  } catch (error) {
    yield put(getProjectsFail(extractErrorMessage(error)));
  }
}

function* createProject({ payload: {callback, payload} }) {
  try {
    const response = yield call(addProject, payload);
    yield put(createProjectSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProjectFail(extractErrorMessage(error)));
  }
}

function* fetchProjectCategories({ payload }) {
  try {
    const response = yield call(getProjectCategories, payload);
    yield put(getProjectCategoriesSuccess(response.data.results));
  } catch (error) {
    yield put(getProjectCategoriesFail(extractErrorMessage(error)));
  }
}

function* createProjectCategory({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProjectCategory, payload);
    yield put(createProjectCategorySuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProjectCategoryFail(extractErrorMessage(error)));
  }
}

function* patchProjectCategory({ payload: {categoryId, payload, callback} }) {
  try {
    const response = yield call(updateProjectCategory, {categoryId, payload});
    yield put(updateProjectCategorySuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProjectCategoryFail(extractErrorMessage(error)));
  }
}

function* patchProject({ payload: {projectId, payload, callback} }) {
  try {
    const response = yield call(updateProject, {projectId, payload});
    yield put(updateProjectSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateProjectFail(extractErrorMessage(error)));
  }
}

function* fetchProjectImages({ payload }) {
  try {
    const response = yield call(getProjectImages, payload);
    yield put(getProjectImagesSuccess(response.data.results));
  } catch (error) {
    yield put(getProjectImagesFail(extractErrorMessage(error)));
  }
}

function* createProjectImages({ payload: {payload, callback} }) {
  try {
    const response = yield call(addProjectImages, payload);
    yield put(createProjectImageSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createProjectImageFail(extractErrorMessage(error)));
  }
}

function* projectSaga() {
  yield takeEvery(GET_PROJECTS, fetchProjects);
  yield takeEvery(ADD_PROJECT, createProject);
  yield takeEvery(GET_PROJECTS_CATEGORY, fetchProjectCategories);
  yield takeEvery(ADD_PROJECT_CATEGORY, createProjectCategory);
  yield takeEvery(UPDATE_PROJECT_CATEGORY, patchProjectCategory);
  yield takeEvery(UPDATE_PROJECT, patchProject);
  yield takeEvery(GET_PROJECT_IMAGES, fetchProjectImages);
  yield takeEvery(ADD_PROJECT_IMAGES, createProjectImages);
}

export default projectSaga;
