import React, {useEffect, useRef, useState} from "react";
import {Avatar, Col, Divider, Drawer, Row, Typography} from "antd";
import {connect} from "react-redux";
import {formatNumber, getUserFullName} from "../../../utils/lib/helpers";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {CheckCircleFilled} from "@ant-design/icons";
import ImagePreview from "./image-preview";
import {getUserSales, verifyTechnician} from "../../../store/users/actions";
import Sales from "../sales";
import {getSales} from "../../../store/sales-rewards/actions";
import {tablePagination} from "../../../utils/constants/layout";
import {Table} from "ant-table-extensions";
import {salesColumns} from "../sales/sales-columns";


const SalesList = props => {
    const {onClose, isVisible, loading, error, user, onGetSales, sales, title} = props;
    const {t} = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
    };


    useEffect(() => {
        onGetSales({userId: user?.user_id});
    }, [onGetSales]);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    return(
        <Drawer
            title={`${title} Sales`}
            placement={'right'}
            closable={true}
            size={'large'}
            width={1000}
            onClose={() => onClose('sales')}
            open={isVisible}>
            <Row>
                <Col lg={20}>
                    <Typography.Title level={4} style={{margin: 0}}>{getUserFullName(user)} - {user?.user_id?.match(/.{1,3}/g).join('-')} </Typography.Title>
                    <Typography.Text>{user?.district?.name} - {user?.region?.name}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Avatar
                        src={user?.photo}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                    />
                </Col>
            </Row>
            <Divider />
            <Table
                rowKey={'uid'}
                pagination={tablePagination}
                rowSelection={rowSelection}
                columns={salesColumns}
                dataSource={sales}
                onChange={handleChange}
                loading={loading}
                exportableProps={{
                    fileName: 'Sales',
                    showColumnPicker: true
                }}
            />

        </Drawer>
    )
}

SalesList.propTypes = {
    title: PropTypes.string,
    user: PropTypes.object,
    sales: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onGetSales: PropTypes.func,
}

const mapStateToProps = ({users: {loading, error, sales}}) => ({ loading, error, sales});

const mapDispatchToProps = dispatch => ({
    onGetSales: payload => dispatch(getUserSales(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(SalesList);
