import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Input, Modal, Row, Select} from 'antd';
import PropTypes from 'prop-types'
import api from 'utils/api/config';

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '90%',
  }
}

const AddUser = ({
  isOpen,
  onCancel,
  designation,
  user,
  regions,
  districts,
  onGetDistricts,
  onCompleteRequest,
  profileToEdit
}) => {

  const [form] = Form.useForm();
  useEffect(() => {
    if (profileToEdit) {
      form.setFieldsValue(profileToEdit)
    } else form.resetFields()
  }, [profileToEdit])

  const [state, setState] = useState({
    loading: false,
    region: null,
    district: null,
    filteredDistricts: districts,
    filteredRegions: regions
  })

  const handleOnValuesChange = (payload) => {
  }

  const submitData = async (values) => {
    setState({ ...state, loading: true })
    let payload = values
    payload.created_by = user.uid
    payload.designation = designation
    try {
      const response = profileToEdit ? await api(true).put(`user/${profileToEdit.uid}/update/`, payload) : await api(true).post('user/create/', payload)
      if (response.status === 201 || response.status === 200) {
        onCompleteRequest('success')
      } else {
        onCompleteRequest('error')
      }
      setState({ ...state, loading: false })
    } catch (e) {
      setState({ ...state, loading: false });
      onCompleteRequest('error')
    }
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F" }}>
          <Col span={12} offset={1} lg={10} md={12} sm={12}>
            <span style={{ fontSize: 25, textTransform: 'capitalize' }}>{`Add a new ${designation}`}</span>
          </Col>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'60vw'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}
    >
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        onValuesChange={handleOnValuesChange}
        onFinish={v => submitData(v)}
      >
        <Row style={{ width: '60vw', justifyContent: 'space-evenly' }}>
          <Col lg={10} md={12} sm={12}>
            <Form.Item label="First Name" required style={_style.label} name='first_name' htmlFor='first_name'>
              <Input style={_style.input} required placeholder="Enter first name" size='large' name='first_name' />
            </Form.Item>
            <Form.Item initialValue={state.email} label="Email Address" style={_style.label} name='email' htmlFor='email' required={designation === 'administrator'} >
              <Input style={_style.input} placeholder="Email address" size='large' name='email' required={designation === 'administrator'} type='email' />
            </Form.Item>
            <Form.Item label="Alternative Phone Number" style={_style.label} name='alternative_number'>
              <Input style={_style.input} placeholder="alternative phone number" size='large' name='alternative_number' />
            </Form.Item>
            {
              designation !== 'administrator' &&
              <>
                <Form.Item label="Region" required style={{ fontWeight: 'bold' }} htmlFor='region' name='region' >
                  <Select placeholder="Select Region"
                    showSearch={true}
                    id='region' size='large'
                    style={{ width: '90%' }}
                    onSearch={v => {
                      state.filteredRegions.filter(_item => { return _item.name.toLowerCase().includes(v.toLowerCase()) })
                    }}
                    onSelect={(v) => {
                      form.setFieldValue('district', null)
                      onGetDistricts({ region: v })
                    }}
                  >
                    {state.filteredRegions.map((opt, idx) => (
                      <Select.Option value={opt.uid} key={`region-${idx}`}>{opt.name}</Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Ofice Name" style={_style.label} name='agent_office_name'>
                  <Input style={_style.input} placeholder="Enter office name" size='large' name='agent_office_name' />
                </Form.Item>
              </>
            }
          </Col>
          <Col lg={10} md={12} sm={12}>
            <Form.Item label="Last Name" required style={_style.label} name='last_name' htmlFor='last_name'>
              <Input style={_style.input} required placeholder="Enter last name" size='large' name='last_name' />
            </Form.Item>
            <Form.Item label="Phone Number" required style={_style.label} name='phone_number'>
              <Input style={_style.input} required placeholder="phone number" size='large' name='phone_number' />
            </Form.Item>
            {
              designation !== 'administrator' && (
                <>
                  {
                    designation === 'agent' && <Form.Item label="TIN Number" required style={_style.label} name='user_id'>
                      <Input style={_style.input} required={designation === 'agent'} placeholder="Enter TIN #" size='large' name='user_id' type='text' />
                    </Form.Item>
                  }
                  {
                    designation === 'technician' && <Form.Item label="Experience in years" required style={_style.label} name='professional_experience'>
                      <Input style={_style.input} placeholder="Number of years" size='large' name='professional_experience' type='number' />
                    </Form.Item>
                  }
                </>
              )
            }

            {
              designation !== 'administrator' && <Form.Item label="District" htmlFor='district' required style={{ fontWeight: 'bold' }} name='district' >
                <Select placeholder="Select District"
                  onSearch={v => {
                    districts.filter(_item => { return _item.name.toLowerCase().includes(v.toLowerCase()) })
                  }}
                  id='district' size='large'
                  showSearch={true}
                  style={{ width: '90%' }}
                  onChange={v => {
                    setState({ ...state, district: v })
                  }}
                >
                  {districts.map((opt, idx) => (
                    <Select.Option value={opt.uid} key={`districts-${idx}`}>{opt.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>
            }
          </Col>
          <Col lg={12} offset={6} sm={12} md={12} style={{ alignItems: 'flex-end' }}>
            <Row style={{ justifyContent: 'end', width: '100%' }}>
              <Col span={12} sm={6}>
                <Button key="back" size='large' onClick={() => {
                  setState({ ...state, loading: false });
                  form.resetFields()
                  onCancel()
                }}>
                  Cancel
                </Button>
              </Col>
              <Col sm={6}>
                <Form.Item >
                  <Button size='large' type='primary' htmlType='submit' loading={state.loading}>
                    {profileToEdit ? `Update ${designation}` : `Create ${designation}`}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AddUser.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  designation: PropTypes.string,
  user: PropTypes.any,
  regions: PropTypes.any,
  districts: PropTypes.any,
  onGetDistricts: PropTypes.any,
  onCompleteRequest: PropTypes.func,
  profileToEdit: PropTypes.any
}

export default AddUser;
