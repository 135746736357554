import {Button, Card, Col, Form, Input, Row} from 'antd';
import React, {useEffect} from 'react';
import {withRouter} from 'react-router-dom';
import Logo from '../../../assets/images/logo.svg'
import {EyeInvisibleOutlined, EyeTwoTone, IdcardOutlined, LockOutlined} from '@ant-design/icons'
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {useTranslation} from 'react-i18next';
import {loginUser, loginUserFail, logoutUser} from 'store/auth/actions';
import {validateEmail} from 'utils/lib/helpers';

const Login = props => {
	document.title = `Emirates Builders | Login`
	const [form] = Form.useForm();
	const { onLogin, onLogout, loading, history, error, onUserLoginFail } = props;
	const { t } = useTranslation();

	const handleOnValuesChange = (payload) => {
	}

	useEffect(() => {
		localStorage.removeItem('emirate:authToken')
		onLogout()
	}, [])

	return (
		<Row style={{ width: '100%' }}>
			<Col sm={10} lg={6} xl={6} offset={9} span={7}>
				<Card style={{ borderRadius: 16, top: 0, bottom: 0, margin: 'auto' }}>
					<div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
						<img src={Logo} style={{ width: 170 }} />
					</div>
					<Form
						style={{ marginTop: 16 }}
						layout={'vertical'}
						form={form}
						onValuesChange={handleOnValuesChange}
						onFinish={v => {
							if (validateEmail(v.username)) onLogin(v, history.replace)
							else {
								onUserLoginFail('Please enter a valid email address!')
							}
						}}>
						<Form.Item label="Email Address" required style={{ fontWeight: 'bold' }} name='username' htmlFor='username'>
							<Input required prefix={<IdcardOutlined style={{ color: '#bdbdbd' }} />} placeholder="Enter your email address" size='large' name='username' id='username' />
						</Form.Item>
						<Form.Item label="Password" required style={{ fontWeight: 'bold' }} name={'password'} htmlFor='password'>
							<Input.Password type='email-address' required prefix={<LockOutlined style={{ color: '#bdbdbd' }} />} id='password' placeholder="Enter your Password" size='large'
								iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)} />
						</Form.Item>
						{
							error && <p className='error-message'>{error}</p>
						}
						<Form.Item>
							<Button type="primary" size='large' htmlType='submit' loading={loading} style={{ width: '100%' }}>
								Login
							</Button>
						</Form.Item>
					</Form>
				</Card>
			</Col>
		</Row>
	)
}

Login.propTypes = {
	history: PropTypes.any,
	onLogin: PropTypes.func,
	onLogout: PropTypes.func,
	loading: PropTypes.bool,
	error: PropTypes.any,
	onUserLoginFail: PropTypes.func
}

const mapStateToProps = ({ auth: { user, loading, isLoggedIn, error } }) => ({
	user, loading, isLoggedIn, error
})

const mapDispatchToProps = dispatch => ({
	onLogin: (credentials, replace) => dispatch(loginUser(credentials, replace)),
	onUserLoginFail: (error) => dispatch(loginUserFail(error)),
	onLogout: () => dispatch(logoutUser()),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Login));
