export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAIL = 'GET_USERS_FAIL';

export const EXPORT_USERS = 'EXPORT_USERS';
export const EXPORT_USERS_SUCCESS = 'EXPORT_USERS_SUCCESS';
export const EXPORT_USERS_FAIL = 'EXPORT_USERS_FAIL';


export const GET_AGENTS_SUCCESS = 'GET_AGENTS_SUCCESS';
export const GET_TECHNICIANS_SUCCESS = 'GET_TECHNICIANS_SUCCESS';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const VERIFY_TECHNICIAN_SUCCESS = 'VERIFY_TECHNICIAN_SUCCESS';
export const VERIFY_TECHNICIAN_FAIL = 'VERIFY_TECHNICIAN_FAIL';
export const VERIFY_TECHNICIAN = 'VERIFY_TECHNICIAN';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_FAIL = 'RESET_USER_PASSWORD_FAIL';
export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const GET_USER_SALES_SUCCESS = 'GET_USER_SALES_SUCCESS';
export const GET_USER_SALES_FAIL = 'GET_USER_SALES_FAIL';
export const GET_USER_SALES = 'GET_USER_SALES';

/*
CREATE_USER
 */
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAIL = 'CREATE_USER_FAIL';
export const CREATE_USER = 'CREATE_USER';

/*
UPDATE_PROFESSION_CATEGORY
 */
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER = 'UPDATE_USER';

export const GET_PROFESSION_CATEGORIES_SUCCESS = 'GET_PROFESSION_CATEGORIES_SUCCESS';
export const GET_PROFESSION_CATEGORIES_FAIL = 'GET_PROFESSION_CATEGORIES_FAIL';
export const GET_PROFESSION_CATEGORIES = 'GET_PROFESSION_CATEGORIES';

/*
CREATE_PROFESSION_CATEGORY
 */
export const CREATE_PROFESSION_CATEGORY_SUCCESS = 'CREATE_PROFESSION_CATEGORY_SUCCESS';
export const CREATE_PROFESSION_CATEGORY_FAIL = 'CREATE_PROFESSION_CATEGORY_FAIL';
export const CREATE_PROFESSION_CATEGORY = 'CREATE_PROFESSION_CATEGORY';

/*
UPDATE_PROFESSION_CATEGORY
 */
export const UPDATE_PROFESSION_CATEGORY = 'UPDATE_PROFESSION_CATEGORY';
export const UPDATE_PROFESSION_CATEGORY_FAIL = 'UPDATE_PROFESSION_CATEGORY_FAIL';
export const UPDATE_PROFESSION_CATEGORY_SUCCESS = 'UPDATE_PROFESSION_CATEGORY_SUCCESS';
