export const GET_REWARD_SETTINGS = 'GET_REWARD_SETTINGS';
export const GET_REWARD_SETTINGS_SUCCESS = 'GET_REWARD_SETTINGS_SUCCESS';
export const GET_REWARD_SETTINGS_FAIL = 'GET_REWARD_SETTINGS_FAIL';

export const ADD_REWARD_SETTINGS = 'ADD_REWARD_SETTINGS';
export const ADD_REWARD_SETTINGS_SUCCESS = 'ADD_REWARD_SETTINGS_SUCCESS';
export const ADD_REWARD_SETTINGS_FAIL = 'ADD_REWARD_SETTINGS_FAIL';

export const UPDATE_REWARD_SETTING = 'UPDATE_REWARD_SETTING';
export const UPDATE_REWARD_SETTING_SUCCESS = 'UPDATE_REWARD_SETTING_SUCCESS';
export const UPDATE_REWARD_SETTING_FAIL = 'UPDATE_REWARD_SETTING_FAIL';

export const GET_AWARDED_TECHNICIANS = 'GET_SALES';
export const GET_AWARDED_TECHNICIANS_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_AWARDED_TECHNICIANS_FAIL = 'GET_SALES_FAIL';

export const GET_SALES = 'GET_SALES';
export const GET_SALES_SUCCESS = 'GET_SALES_SUCCESS';
export const GET_SALES_FAIL = 'GET_SALES_FAIL';

export const GET_REWARD_POINT_VALUE = 'GET_REWARD_POINT_VALUE'