import {
    GET_DISTRICTS,
    GET_DISTRICTS_FAIL,
    GET_DISTRICTS_SUCCESS,
    GET_REGIONS,
    GET_REGIONS_FAIL,
    GET_REGIONS_SUCCESS,
    GET_WARDS,
    GET_WARDS_FAIL,
    GET_WARDS_SUCCESS,
    SET_SELECTED_REGION
} from './action-types';

export const getRegions = payload => ({
  type: GET_REGIONS,
  payload,
});

export const getRegionsSuccess = user => ({
  type: GET_REGIONS_SUCCESS,
  payload: user,
});

export const getRegionsFail = error => ({
  type: GET_REGIONS_FAIL,
  payload: error,
});
export const getWards = payload => ({
  type: GET_WARDS,
  payload,
});

export const getWardsSuccess = wards => ({
  type: GET_WARDS_SUCCESS,
  payload: wards,
});

export const getWardsFail = error => ({
  type: GET_WARDS_FAIL,
  payload: error,
});

export const getDistricts = payload => ({
  type: GET_DISTRICTS,
  payload,
});

export const getDistrictsSuccess = payload => ({
  type: GET_DISTRICTS_SUCCESS,
  payload,
});

export const getDistrictsFail = error => ({
  type: GET_DISTRICTS_FAIL,
  payload: error,
});

export const setSelectedRegion = payload => ({
  type: SET_SELECTED_REGION,
  payload
});
