import {call, put, takeEvery} from 'redux-saga/effects';
import {GET_DISTRICTS, GET_REGIONS, GET_WARDS,} from './action-types';

import {
    getDistrictsFail,
    getDistrictsSuccess,
    getRegionsFail,
    getRegionsSuccess,
    getWardsFail,
    getWardsSuccess,
} from './actions';
import {extractErrorMessage} from '../../utils/lib/helpers';
import {getDistricts, getRegions, getWards} from 'utils/api';

function* fetchRegions({ payload }) {
  try {
    const response = yield call(getRegions, payload);
    yield put(getRegionsSuccess(response.data.results));
  } catch (error) {
    yield put(getRegionsFail(extractErrorMessage(error)));
  }
}

function* fetchWards({ payload }) {
  try {
    const response = yield call(getWards, payload);
    yield put(getWardsSuccess(response.data));
  } catch (error) {
    yield put(getWardsFail(extractErrorMessage(error)));
  }
}

function* fetchDistricts({ payload }) {
  try {
    const response = yield call(getDistricts, payload);
    yield put(getDistrictsSuccess(response.data));
  } catch (error) {
    yield put(getDistrictsFail(extractErrorMessage(error)));
  }
}

function* locationSaga() {
  yield takeEvery(GET_REGIONS, fetchRegions);
  yield takeEvery(GET_DISTRICTS, fetchDistricts);
  yield takeEvery(GET_WARDS, fetchWards);
}

export default locationSaga;
