import React, {useEffect, useState} from 'react';
import {Button, Form, Input, Modal, Row} from 'antd';
import PropTypes from 'prop-types'
import api from 'utils/api/config';

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '100%',
  }
}

const UpdateRewardPointValue = ({
  isOpen,
  onCancel,
  user,
  onCompleteRequest,
  rewardPointValue
}) => {

  const [form] = Form.useForm();

  const [state, setState] = useState({
    loading: false,
    region: null
  })

  const handleOnValuesChange = (payload) => {
  }

  const submitData = async (values) => {
    setState({ ...state, loading: true })
    const payload = values
    payload.updated_by = user.uid
    try {
      const response = await api(true).post('reward-value/set-value/', payload)
      if (response.status === 200) {
        onCompleteRequest(response.data.monetary_value)
      } else {
        onCompleteRequest('error')
      }
      setState({ ...state, loading: false, categories: [], image: null })
    } catch (e) {
      setState({ ...state, loading: false });
      onCompleteRequest('error')
    }
  }

  useEffect(() => {
    form.setFieldValue('monetary_value', rewardPointValue)
  }, [])

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F", width: '100%' }}>
          <span style={{ fontSize: 30, textTransform: 'capitalize' }}>{`1 (One) Reward Point Value:`}</span>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'40vw'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}
    >
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        onValuesChange={handleOnValuesChange}
        onFinish={v => submitData(v)}
      >
        <Row style={{ width: '90%' }}>
          <Form.Item label="Amount of Money" name='monetary_value' required htmlFor='monetary_value' style={{ width: '90%', fontWeight: 'bold' }}>
            <Input style={_style.input} required placeholder="Enter Amount" size='large' name='monetary_value' type='number' />
          </Form.Item>

          <Button key="back" size='large' style={{ width: '40%' }}
            onClick={() => { setState({ ...state, loading: false }); onCancel() }}>
            Cancel
          </Button>
          <Button size='large' type='primary' htmlType='submit' loading={state.loading}
            style={{ textTransform: 'capitalize', marginLeft: '10%', width: '40%' }}>
            Save
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

UpdateRewardPointValue.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  onCompleteRequest: PropTypes.func,
  rewardPointValue: PropTypes.any
}

export { UpdateRewardPointValue };
