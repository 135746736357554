import {LOGIN_USER, LOGIN_USER_FAIL, LOGIN_USER_SUCCESS, LOGOUT_USER,} from './action-types';

const INIT_STATE = {
  user: {},
  accessToken: null,
  refreshToken: null,
  isLoggedIn: null,
  error: null,
  loading: false
};

const auth = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true, user: null };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user,
        accessToken: action.payload.access,
        refreshToken: action.payload.refresh,
        isLoggedIn: true,
        error: null,
        loading: false,
      };
    case LOGIN_USER_FAIL:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        error: action.payload,
        loading: false,
        success: null,
      };
    case LOGOUT_USER:
      return {
        ...state,
        user: null,
        isLoggedIn: false,
        loading: false,
        success: null,
      };
    default:
      return state;
  }
};

export default auth;
