import {all, fork} from 'redux-saga/effects';
import appSaga from '../app/saga';
import authSaga from 'store/auth/saga';
import userSaga from 'store/users/saga';
import locationSaga from 'store/locations/saga';
import productSaga from 'store/products/saga';
import adsSaga from 'store/adds/saga';
import rewardsAndSalesSaga from 'store/sales-rewards/saga';
import projectSaga from 'store/projects/saga';

export default function* rootSaga() {
  yield all([
      fork(appSaga),
    fork(productSaga),
    fork(authSaga),
    fork(userSaga),
    fork(locationSaga),
    fork(adsSaga),
    fork(rewardsAndSalesSaga),
    fork(projectSaga)
  ]);
}
