import React, {useEffect, useState} from 'react';
import {Button, Col, Input, Row, Space, Typography} from 'antd';
import {UserAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {createProjectCategory, getProjectCategories, updateProjectCategory} from 'store/projects/actions';
import {tablePagination} from 'utils/constants/layout';
import FormModal from "../../shared/form-modal";
import {getCategoryColumns} from "../products/category-columns";
import {Table} from "ant-table-extensions";


const ProjectCategories = props => {

  document.title = 'Emirate Builders | Categories'
  const {
    user,
    loading,
    error,
    onGetProjectCategories,
    projectCategories,
      onUpdateProjectCategory,
    onCreateProjectCategory
  } = props

  const [form, setForm] = useState({isOpen: false});
  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetProjectCategories()
  }, [onGetProjectCategories])

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  const getFields = (formType) => {
    const sharedFields = [
      {name: "name", label: "Name", required: true, field: Input},
    ]

    const fields = {create: [...sharedFields], edit: [...sharedFields]}
    return fields[formType]
  }

  const handleOnClose = () => setForm(prevState => ({...prevState, isOpen: !prevState?.isOpen, initialValues: {}}))
  const handleOnUpdateRecord = (record, data) => { onUpdateProjectCategory(record?.uid, data, handleOnClose); }

  const handleOnEditRecord = record => {

    setForm(prevState => ({
      ...prevState,
      isOpen: !prevState?.isOpen,
      action: 'edit',
      initialValues: record,
      title: "Edit Project Category",
      btnLabel: "Update",
      fields: getFields('edit'),
      onCancel: handleOnClose,
      onSubmit: handleOnUpdateRecord
    }))
  }

  const handleOnDeleteRecord = (record) => {
    const payload = {archived: true, name: record?.name}
    onUpdateProjectCategory(record?.uid, payload);
  }

  const handleOnSubmit = (data) => {
    const payload = {...data}
    payload.created_by = user?.uid;
    onCreateProjectCategory(payload, handleOnClose);
  }

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Categories</Typography.Title>
          <Space>
            <Button onClick={() => {
              setForm(prevState => ({...prevState,
                action: 'create',
                isOpen: !prevState?.isOpen,
                formType: 'create',
                title: "Add Project Category",
                btnLabel: "Save",
                fields: getFields('create'),
                onCancel: handleOnClose,
                onSubmit: handleOnSubmit
              }))
            }} icon={<UserAddOutlined />}>Add Category</Button>
            {/*{hasSelected && (*/}
            {/*  <Button onClick={() => { }} icon={<ExportOutlined />}>Export Data</Button>*/}
            {/*)}*/}
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
            exportable
            exportableProps={{showColumnPicker: true, fileName: 'project-categories'}}
            pagination={tablePagination}
            rowSelection={rowSelection}
            columns={getCategoryColumns(sortedInfo, handleOnEditRecord, handleOnDeleteRecord)}
            dataSource={projectCategories?.filter(c => !c?.archived)} onChange={handleChange} loading={loading} />
      </Col>

      {form?.isOpen && (<FormModal {...form} width={'25%'} />)}
    </Row>
  )
}

ProjectCategories.propTypes = {
  user: PropTypes.any,
  error: PropTypes.any,
  loading: PropTypes.any,
  projectCategories: PropTypes.any,
  onGetProjectCategories: PropTypes.func,
  onUpdateProjectCategory: PropTypes.func,
  onCreateProjectCategory: PropTypes.func,
}

const mapStateToProps = ({
  auth: { user },
  projectsReducer: { projectCategories, loading, error }
}) => ({
  user, error, loading, projectCategories
})

const mapDispatchToProps = dispatch => ({
  onGetProjectCategories: (params) => dispatch(getProjectCategories(params)),
  onCreateProjectCategory: (payload, callback) => dispatch(createProjectCategory(payload, callback)),
  onUpdateProjectCategory: (categoryId, payload, callback) => dispatch(updateProjectCategory(categoryId, payload, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCategories);
