import React, {useState} from 'react';
import {Button, DatePicker, Drawer, Form, Select} from 'antd'
import PropTypes from 'prop-types'
import {useForm} from 'antd/es/form/Form';

const { Option } = Select;


const FilterForm = props => {
	const {
		onCloseDrawer,
		openDrawer,
		title,
		regions,
		onSubmitFilterOptions,
		districts,
		wards,
		onGetDistricts,
		onGetWards,
		filterLocation,
		filterDates
	} = props;
	const [form] = useForm();

	const [state, setState] = useState({
		date: null
	})

	return (
		<Drawer
			// style={{width: 500}}
			title={`${title}`}
			placement={'right'}
			closable={true}
			onClose={onCloseDrawer}
			open={openDrawer}
			key={'right'}>
			<Form
				style={{ marginTop: 16 }}
				layout={'vertical'}
				form={form}
				onFinish={(values) => onSubmitFilterOptions({...values, date: state.date,})}>
				{filterLocation && <React.Fragment>
						<Form.Item label="Region" style={{ fontWeight: 'bold' }} name={'region'}>
							<Select placeholder="All regions" size='large'
								onChange={(v) => {
									onGetDistricts({ region: v })
									form.setFieldValue('district', null)
								}} allowClear showSearch>
								{regions?.length > 0 && regions?.map((opt, idx) => (
									<Option key={idx} value={opt.uid}>{opt.name}</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="District" style={{ fontWeight: 'bold' }} name={'district'}>
							<Select placeholder="All districts" size='large' onChange={v => {
								onGetWards({district: v});
							}} allowClear showSearch>
								{districts?.length > 0 && districts?.map((opt, idx) => (
									<Option key={idx} value={opt.uid}>{opt.name}</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Ward" style={{ fontWeight: 'bold' }} name={'ward'}>
							<Select placeholder="All wards" size='large' allowClear showSearch>
								{wards?.length > 0 && wards?.map((opt, idx) => (
									<Option key={idx} value={opt.uid}>{opt.name}</Option>
								))}
							</Select>
						</Form.Item>

						<Form.Item label="Sort by" style={{ fontWeight: 'bold' }} name={'sort_by'}>
							<Select placeholder="All" size='large'>
								{[{ label: 'Sales', value: 'sales' }, { label: 'Recently Added', value: 'created_at' }].map((opt, idx) => (
									<Option key={`sort-by${idx}`} value={opt.value}>{opt.label}</Option>
								))}
							</Select>
						</Form.Item>
					</React.Fragment>
				}
				{
					filterDates && <Form.Item label="Date Range" style={{ fontWeight: 'bold' }} >
						<DatePicker.RangePicker size='large' style={{ width: '100%' }}
							value={state.date}
							onChange={e => {
								setState({
									...state,
									date: e
								})
							}}
							format="DD-MM-YYYY"
						/>
					</Form.Item>
				}
				<Form.Item>
					<Button type="primary" size='large' htmlType='submit' onClick={() => { }}>
						Submit
					</Button>
				</Form.Item>
			</Form>
		</Drawer>
	)
}

FilterForm.propTypes = {
	onCloseDrawer: PropTypes.func,
	openDrawer: PropTypes.bool,
	title: PropTypes.string,
	regions: PropTypes.array,
	districts: PropTypes.array,
	wards: PropTypes.array,
	onGetDistricts: PropTypes.func,
	onGetWards: PropTypes.func,
	onSubmitFilterOptions: PropTypes.func,
	filterDates: PropTypes.bool,
	filterLocation: PropTypes.bool
}

export default FilterForm;
