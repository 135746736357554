import {
    CREATE_PROFESSION_CATEGORY,
    CREATE_PROFESSION_CATEGORY_FAIL, CREATE_PROFESSION_CATEGORY_SUCCESS,
    CREATE_USER,
    CREATE_USER_FAIL,
    CREATE_USER_SUCCESS, EXPORT_USERS, EXPORT_USERS_SUCCESS,
    GET_ALL_USERS_SUCCESS, GET_PROFESSION_CATEGORIES, GET_PROFESSION_CATEGORIES_FAIL, GET_PROFESSION_CATEGORIES_SUCCESS,
    GET_USER_SALES,
    GET_USER_SALES_FAIL,
    GET_USER_SALES_SUCCESS,
    GET_USERS,
    RESET_USER_PASSWORD,
    RESET_USER_PASSWORD_FAIL,
    RESET_USER_PASSWORD_SUCCESS, UPDATE_PROFESSION_CATEGORY,
    UPDATE_PROFESSION_CATEGORY_FAIL, UPDATE_PROFESSION_CATEGORY_SUCCESS,
    UPDATE_USER,
    UPDATE_USER_FAIL,
    UPDATE_USER_SUCCESS,
    VERIFY_TECHNICIAN,
    VERIFY_TECHNICIAN_FAIL,
    VERIFY_TECHNICIAN_SUCCESS
} from './action-types';

export const getUsers = (payload) => ({
  type: GET_USERS,
  payload
});

export const getUsersSuccess = (type = GET_ALL_USERS_SUCCESS, payload) => {
  return {
    type,
    payload
  }
};

export const getUsersFail = ({ type, error }) => ({
  type,
  payload: error,
});

export const exportUsers = (payload) => ({
  type: EXPORT_USERS,
  payload
});

export const exportUsersSuccess = (type = EXPORT_USERS_SUCCESS, payload) => {
  return {
    type,
    payload
  }
};

export const exportUsersFail = ({ type, error }) => ({
  type,
  payload: error,
});

export const verifyTechnician = (userId, payload, callback) => ({
  type: VERIFY_TECHNICIAN,
  payload: {userId, payload, callback}
});

export const verifyTechnicianSuccess = (payload) => {
    return {
        type: VERIFY_TECHNICIAN_SUCCESS,
        payload
    }
};

export const verifyTechnicianFail = (error) => ({
    type: VERIFY_TECHNICIAN_FAIL,
    payload: error,
});

export const resetUserPassword = (userId, callback) => ({
  type: RESET_USER_PASSWORD,
  payload: {userId, callback}
});

export const resetUserPasswordSuccess = (payload) => {
    return {
        type: RESET_USER_PASSWORD_SUCCESS,
        payload
    }
};

export const resetUserPasswordFail = (error) => ({
    type: RESET_USER_PASSWORD_FAIL,
    payload: error,
});

export const createUser = (payload, callback) => ({
  type: CREATE_USER,
  payload: {payload, callback}
});

export const createUserSuccess = (payload) => {
  return {
    type: CREATE_USER_SUCCESS,
    payload
  }
};

export const createUserFail = (error) => ({
  type: CREATE_USER_FAIL,
  payload: error,
});

export const updateUser = (userId, payload, userType, callback) => ({
  type: UPDATE_USER,
  payload: {userId, payload, userType, callback}
});

export const updateUserSuccess = (payload) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload
  }
};

export const updateUserFail = (error) => ({
  type: UPDATE_USER_FAIL,
  payload: error,
});

export const createProfessionCategory = (payload, callback) => ({
  type: CREATE_PROFESSION_CATEGORY,
  payload: {payload, callback}
});

export const createProfessionCategorySuccess = (payload) => {
  return {
    type: CREATE_PROFESSION_CATEGORY_SUCCESS,
    payload
  }
};

export const createProfessionCategoryFail = (error) => ({
  type: CREATE_PROFESSION_CATEGORY_FAIL,
  payload: error,
});

export const updateProfessionCategory = (categoryId, payload, callback) => ({
  type: UPDATE_PROFESSION_CATEGORY,
  payload: {categoryId, payload, callback}
});

export const updateProfessionCategorySuccess = (payload) => {
  return {
    type: UPDATE_PROFESSION_CATEGORY_SUCCESS,
    payload
  }
};

export const updateProfessionCategoryFail = (error) => ({
  type: UPDATE_PROFESSION_CATEGORY_FAIL,
  payload: error,
});

export const getProfessionCategories = (payload) => ({
  type: GET_PROFESSION_CATEGORIES,
  payload
});

export const getProfessionCategoriesSuccess = (payload) => {
  return {
    type: GET_PROFESSION_CATEGORIES_SUCCESS,
    payload
  }
};

export const getProfessionCategoriesFail = (error) => ({
  type: GET_PROFESSION_CATEGORIES_FAIL,
  payload: error,
});

export const getUserSales = (payload) => ({
  type: GET_USER_SALES,
  payload
});

export const getUserSalesSuccess = (payload) => {
  return {
    type: GET_USER_SALES_SUCCESS,
    payload
  }
};

export const getUserSalesFail = (error) => ({
  type: GET_USER_SALES_FAIL,
  payload: error,
});
