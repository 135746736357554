import React, {useEffect, useState} from 'react';
import {Button, Col, Row, Space, Table, Typography} from 'antd';
import {UserAddOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {getDistricts, getRegions} from 'store/locations/actions';
import api from 'utils/api/config';
import {AddNotification} from './add-notification';
import {tablePagination} from 'utils/constants/layout';
import {getNotificationColumns} from "./notification-columns";
import NotificationDetails from "./notification-details";


const Notifications = props => {

  document.title = 'Emirate Builders | Notifications'
  // const history = useHistory()
  const {
    user,
    regions,
    districts,
    onGetDistricts,
    onGetRegions
  } = props

  const [state, setState] = useState({
    loading: true,
    notifications: [],
    showCreateForm: false
  })

  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [detailView, setDetailView] = useState({show: {detail: false}, notification: null})
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setSortedInfo(sorter);
  };

  const getNotifications = async () => {
    try {
      const response = await api(true).get('notification/list/')
      console.log(response.data.results)
      if (response.status === 200) setState({ ...state, loading: false, notifications: response.data.results })
      else setState({ ...state, loading: false })
    } catch (error) {
      console.log(error)
      setState({ ...state, loading: false })
    }
  }

  useEffect(() => {
    getNotifications()
    onGetRegions()
  }, [onGetRegions])

  const handleOnRecordSelected = (record, event) => {
    setDetailView(prevState => ({
      show: {
        detail: !prevState?.show?.detail
      },
      notification: record
    }));
  }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>
            Notifications Sent
          </Typography.Title>
          <Space>
            <Button onClick={() => { setState({ ...state, showCreateForm: true, objectToCreate: 'district' }) }}
              icon={<UserAddOutlined />}>New Notification</Button>
          </Space>
        </Space>
        <Table rowKey={'uid'} pagination={tablePagination} rowSelection={rowSelection} columns={getNotificationColumns(handleOnRecordSelected)} dataSource={state.notifications} onChange={handleChange} loading={state.loading} />
      </Col>

      <AddNotification
        isOpen={state.showCreateForm}
        user={user}
        onCancel={() => setState({ ...state, showCreateForm: false })}
        onCompleteRequest={(_new) => {
          setState({ ...state, showCreateForm: false, notifications: [_new, ...state.notifications] })
        }}
        regions={regions}
        districts={districts}
        onGetDistricts={onGetDistricts}
      />

      {detailView?.show?.detail && (
          <NotificationDetails
              isVisible={detailView?.show?.detail}
              notification={detailView?.notification}
              onClose={handleOnRecordSelected}
          />
      )}
    </Row>
  )
}

Notifications.propTypes = {
  user: PropTypes.any,
  regions: PropTypes.array,
  districts: PropTypes.array,
  onGetDistricts: PropTypes.func,
  onGetRegions: PropTypes.func
}

const mapStateToProps = ({
  auth: { user },
  locations: { regions, districts }
}) => ({
  user, regions, districts
})

const mapDispatchToProps = dispatch => ({
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
