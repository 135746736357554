import React, {useEffect, useState} from 'react';
import {Col, Row, Space, Table, Typography} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {useHistory} from 'react-router-dom'
import {AddLocation} from './add-location';
import {getRegions, setSelectedRegion} from 'store/locations/actions';
import {tablePagination} from 'utils/constants/layout';


const Region = props => {

  document.title = 'Emirate Builders | Regions'
  const history = useHistory()
  const {
    user,
    onGetRegions,
    onGetDistricts,
    regions,
    districts,
    loading,
    error,
    onSelectRegion
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    objectToCreate: 'region'
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetRegions()
  }, [])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      render: (_, record) => {
        return (
          <Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => {
            onSelectRegion(record)
            history.push(`/regions/${record.uid}/`)
          }}>{record.name}</Typography.Link>
        )
      }
    },
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      // filters: [],
      // filteredValue: filteredInfo.code || null,
      // onFilter: (value, record) => record.code.includes(value),
      sorter: (a, b) => a.code.length - b.code.length,
      sortOrder: sortedInfo.columnKey === 'code' ? sortedInfo.order : null,
      ellipsis: true,
      render: (_, record) => {
        return (
          <Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => {
            onSelectRegion(record)
            history.push(`/regions/${record.uid}/`)
          }}>{record.code}</Typography.Link>
        )
      }
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Regions</Typography.Title>
          <Space>
            {/* <Button onClick={() => { setState({ ...state, showCreateForm: true, objectToCreate: 'region' }) }}
              icon={<UserAddOutlined />}>Add Region</Button> */}
            {/* {hasSelected && (
              <Button onClick={() => { }} icon={<ExportOutlined />}>Export Data</Button>
            )} */}
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
            pagination={tablePagination} rowSelection={rowSelection} columns={columns} dataSource={regions} onChange={handleChange} loading={loading} />
      </Col>

      <AddLocation
        isOpen={state.showCreateForm}
        user={user}
        onCancel={() => setState({ ...state, showCreateForm: false })}
        objectToCreate={state.objectToCreate}
        onCompleteRequest={() => {
          setState({ ...state, showCreateForm: false })
          if (state.objectToCreate === 'region') onGetRegions()
        }}
        regions={regions}
      />
    </Row>
  )
}

Region.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  onGetRegions: PropTypes.func,
  onGetDistricts: PropTypes.func,
  regions: PropTypes.array,
  districts: PropTypes.array,
  onSelectRegion: PropTypes.func
}

const mapStateToProps = ({
  auth: { user },
  locations: { regions, districts, loading, error }
}) => ({
  user, error, loading, regions, districts
})

const mapDispatchToProps = dispatch => ({
  onGetRegions: params => dispatch(getRegions(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onSelectRegion: payload => dispatch(setSelectedRegion(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Region);
