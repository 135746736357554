import React, {useState} from 'react';
import {Button, Form, Input, Modal, Row} from 'antd';
import PropTypes from 'prop-types'
import api from 'utils/api/config';

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '100%',
  }
}

const AddLocation = ({
  isOpen,
  onCancel,
  user,
  onCompleteRequest,
  objectToCreate,
  selectedRegion
}) => {

  const [form] = Form.useForm();

  const [state, setState] = useState({
    loading: false,
    region: null
  })

  const handleOnValuesChange = (payload) => {
  }

  const submitData = async (values) => {
    setState({ ...state, loading: true })
    const payload = values
    payload.added_by = user.uid
    if (selectedRegion) payload.region = selectedRegion.uid
    // console.log(payload); return;
    let url = objectToCreate === 'region' ? 'regions/create/' : 'districts/create/'
    try {
      const response = await api(true).post(url, payload)
      if (response.status === 201) {
        onCompleteRequest('success')
      } else {
        onCompleteRequest('error')
      }
      setState({ ...state, loading: false, categories: [], image: null })
    } catch (e) {
      setState({ ...state, loading: false });
      onCompleteRequest('error')
    }
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F", width: '100%' }}>
          <span style={{ fontSize: 25 }}>{selectedRegion ? `Add ${selectedRegion.name}'s district` : `Add a new region`}</span>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'40vw'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}
    >
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        onValuesChange={handleOnValuesChange}
        onFinish={v => submitData(v)}
      >
        <Form.Item label={`${objectToCreate} Name`} name='name' required htmlFor='name'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Input style={_style.input} placeholder={`Enter ${objectToCreate}'s name`} size='large' name='name' />
        </Form.Item>
        <Form.Item label={`${objectToCreate} Code`} name='code' required htmlFor='code'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Input style={_style.input} placeholder={`Enter ${objectToCreate}'s code`} size='large' name='code' />
        </Form.Item>

        <Row style={{ width: '100%' }}>
          <Button key="back" size='large' style={{ width: '40%' }}
            onClick={() => { setState({ ...state, loading: false }); onCancel() }}>
            Cancel
          </Button>
          <Button size='large' type='primary' htmlType='submit' loading={state.loading}
            style={{ textTransform: 'capitalize', marginLeft: '10%', width: '40%' }}>
            Add {objectToCreate}
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

AddLocation.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  onCompleteRequest: PropTypes.func,
  regions: PropTypes.array,
  objectToCreate: PropTypes.string,
  selectedRegion: PropTypes.any
}

export { AddLocation };
