import {call, put, takeEvery} from 'redux-saga/effects';
import {
  createAdFail,
  createAdSuccess,
  getAdsFail,
  getAdsSuccess,
  updateAdFail,
  updateAdSuccess
} from './actions';
import {extractErrorMessage} from '../../utils/lib/helpers';
import {createAd, getAds, updateAd} from 'utils/api';
import {CREATE_AD, GET_ADS, UPDATE_AD} from './action-types';

function* fetchAds({ payload }) {
  try {
    const response = yield call(getAds, payload);
    yield put(getAdsSuccess(response.data.results));
  } catch (error) {
    yield put(getAdsFail(extractErrorMessage(error)));
  }
}

function* putAd({ payload: {payload, callback} }) {
  try {
    const response = yield call(createAd, payload);
    yield put(createAdSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(createAdFail(extractErrorMessage(error)));
  }
}

function* patchAd({ payload: {adId, payload, callback} }) {
  try {
    const response = yield call(updateAd, {adId, payload});
    yield put(updateAdSuccess(response.data));
    callback && callback();
  } catch (error) {
    yield put(updateAdFail(extractErrorMessage(error)));
  }
}


function* adsSaga() {
  yield takeEvery(GET_ADS, fetchAds);
  yield takeEvery(CREATE_AD, putAd);
  yield takeEvery(UPDATE_AD, patchAd);
}

export default adsSaga;
