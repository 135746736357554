import React from "react"
import {Redirect} from "react-router-dom"
import PageNotFound from "../components/pages/errors/page-not-found";
import Login from "components/pages/auth/login";
import Dashboard from "components/pages/dashboard";
import Agents from "components/pages/agents";
import Technicians from "components/pages/technicians";
import Regions from "components/pages/locations";
import Users from "components/pages/users";
import {ProductCategories, Products} from "components/pages/products";
import Ads from "components/pages/ads";
import Rewards from "components/pages/rewards";
import Sales from "components/pages/sales";
import Districts from "components/pages/locations/districts";
import {ProjectCategories, Projects} from "components/pages/projects";
import Notifications from "components/pages/notifications";
import Awards from "components/pages/rewards/awards";
import ProductSize from "../components/pages/products/product-sizes";
import ProductColor from "../components/pages/products/product-colors";
import Wards from "../components/pages/locations/wards";
import ProfessionCategories from "../components/pages/technicians/categories";

const userRoutes = [
    {
        path: "/",
        exact: true,
        component: () => <Redirect to="/dashboard/" />,
    },
    { path: "/dashboard/", component: Dashboard },
    { path: "/agents/", component: Agents },
    { path: '/technicians/', component: Technicians },
    { path: '/technicians/:uid', component: Technicians },
    { path: '/users/', component: Users },
    { path: '/products/', component: Products },
    { path: '/products-categories/', component: ProductCategories },
    { path: '/profession-categories/', component: ProfessionCategories },
    { path: '/products-colors/', component: ProductColor },
    { path: '/products-sizes/', component: ProductSize },
    { path: '/projects/', component: Projects },
    { path: '/projects-categories/', component: ProjectCategories },
    { path: '/regions/', component: Regions },
    { path: '/regions/:uid', component: Districts, exact: false },
    { path: '/districts/:uid', component: Wards, exact: false },
    { path: '/ads/', component: Ads },
    { path: '/rewards/', component: Rewards },
    { path: '/awards/', component: Awards },
    { path: '/sales/', component: Sales },
    { path: '/notifications/', component: Notifications },
    { path: "*", component: PageNotFound },
]

const authRoutes = [
    { path: "/login/", component: Login },
]

export { userRoutes, authRoutes }
