import PropTypes from "prop-types"
import React, {useState} from "react"
import {Dropdown, Layout, theme} from 'antd';

import {connect} from "react-redux"
import {Link, withRouter} from "react-router-dom"

import Logo from '../../assets/images/logo-black.svg'
import Pawsoft from '../../assets/images/pawsoft-color.png'
import Sidebar from "./sidebar";
import {UserOutlined} from "@ant-design/icons";

const { Header, Sider, Footer, Content } = Layout;

const MainContentLayout = (props) => {
  const [isMobile, setIsMobile] = useState(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent))

  const capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }

  const { children, user } = props

  const items = [
    {
      label: (
        <Link to={'/account/profile/'} className='profile-top-nav'>
          {user.username}
        </Link>
      ),
      key: '0',
    },
    {
      type: 'divider',
    },
    {
      label: <Link to={'/login/'} className='profile-top-nav'>
        Logout
      </Link>,
      key: '3',
    },
  ];

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const toggleMenuCallback = () => { }

  return (
    <Layout style={{ minHeight: '100vh', backgroundColor: 'transparent' }}>
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#000000'
        }}>
        <div className="emirate-logo-container">
          <img src={Logo} style={{ width: 300 }} />
        </div>
        <Dropdown menu={{ items: items }} id='emirate-user-menu'>
          <a className="profile-top-nav" style={{ padding: '0 20px' }} onClick={(e) => e.preventDefault()}>
            <UserOutlined /> {user.first_name}
          </a>
        </Dropdown>
      </Header>
      <Layout>
        <Sider style={{ backgroundColor: '#fff' }} width={250}>
          <div style={{
            height: 'calc(100vh - 90px)',
            overflow: 'auto',
            position: 'absolute',
            top: '0px',
            paddingBottom: '60px'
          }}>
            <Sidebar />
          </div>
          <div style={{
            position: 'absolute',
            bottom: '0px',
            color: '#fff',
            textAlign: 'center',
            width: '250px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#000',
            fontSize: '14px'
          }}>
            {/* <a href="https://pawsoft.africa/" className="not-decorated"> */}
            Powered by PawSoft
            <img src={Pawsoft} style={{ height: '30px', width: '30px', marginLeft: '10px' }} />
            {/* </a> */}
          </div>
        </Sider>
        <Layout
          style={{
            padding: '25px',
            margin: 0,
            borderRadius: 8,
            height: 'calc(100vh - 70px)',
            overflow: 'auto',
            width: 'calc(100vw - 260px)'
          }}>
          <Content>
            {children}
          </Content>
        </Layout>
      </Layout>
    </Layout>
  )
}

MainContentLayout.propTypes = {
  children: PropTypes.object,
  layoutWidth: PropTypes.any,
  location: PropTypes.object,
  user: PropTypes.any
}

const mapStatetoProps = ({ state, auth: { user } }) => ({ ...state, user })
export default connect(mapStatetoProps, {})(withRouter(MainContentLayout))
