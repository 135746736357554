import React from 'react'
import store, {history} from './store'
import {PersistGate} from 'redux-persist/integration/react';
import persistor from './store/persistor'
import {Provider} from 'react-redux';
import {Switch} from 'react-router'
import {ConnectedRouter} from 'connected-react-router';
import {authRoutes, userRoutes} from 'routes';
import Middleware from 'routes/middleware';
import AppInnerLayout from 'components/shared/app-inner-layout';
import MainContentLayout from 'components/shared/main-content-layout';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            {authRoutes.map((route, idx) => (
              <Middleware
                path={route.path}
                layout={AppInnerLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
                exact
              />
            ))}

            {userRoutes.map((route, idx) => (
              <Middleware
                path={route.path}
                layout={MainContentLayout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                exact
              />
            ))}
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
