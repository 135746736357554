import axios from 'axios';
import {authToken, refreshToken} from './authToken';

const debug_mode = false;
const env = {
  development: 'http://192.168.18.3:8000/v1/',
  production: 'https://api.emiratebuilders.co.tz/v1/'
};

const api = (authenticate) => {
  const config = axios.create({
    baseURL: debug_mode ? env.development : env.production
  });

  if (authenticate) { config.defaults.headers.common['Authorization'] = `Bearer ${authToken()}`; }
  config.defaults.headers.post['Content-Type'] = 'application/json';

  config.interceptors.response.use(
      response => response,
      error => {
        const originalRequest = error.config;

        if (error.response.status === 401 && error.response.statusText === "Unauthorized") {
          const refresh_token = refreshToken();

          return config
              .post('/auth/token/refresh/', {refresh: refresh_token})
              .then((response) => {

                localStorage.setItem('emirate:authToken', response.data.access);
                if(response.data.refresh){
                  localStorage.setItem('emirate:authToken', response.data.refresh);
                }

                config.defaults.headers['Authorization'] = "Bearer " + response.data.access;
                originalRequest.headers['Authorization'] = "Bearer " + response.data.access;

                return config(originalRequest);
              })
              .catch(err => {
                console.log(err)
              });
        }
        return Promise.reject(error);
      }
  );

  return config;
};

export default api;
