export const GET_REGIONS = 'GET_REGIONS';
export const GET_REGIONS_SUCCESS = 'GET_REGIONS_SUCCESS';
export const GET_REGIONS_FAIL = 'GET_REGIONS_FAIL';

export const GET_DISTRICTS = 'GET_DISTRICTS';
export const GET_DISTRICTS_SUCCESS = 'GET_DISTRICTS_SUCCESS';
export const GET_DISTRICTS_FAIL = 'GET_DISTRICTS_FAIL';

export const GET_WARDS = 'GET_WARDS';
export const GET_WARDS_SUCCESS = 'GET_WARDS_SUCCESS';
export const GET_WARDS_FAIL = 'GET_WARDS_FAIL';

export const SET_SELECTED_REGION = 'SET_SELECTED_REGION'
