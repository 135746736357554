import {
    CREATE_AD,
    CREATE_AD_FAIL,
    CREATE_AD_SUCCESS,
    GET_ADS,
    GET_ADS_FAIL,
    GET_ADS_SUCCESS,
    UPDATE_AD,
    UPDATE_AD_FAIL,
    UPDATE_AD_SUCCESS
} from './action-types';

const INIT_STATE = {
  ads: [],
  loading: false,
  error: null,
};

const adsReducer = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case GET_ADS:
    case CREATE_AD:
    case UPDATE_AD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_ADS_SUCCESS:
      return {
        ...state,
        ads: payload,
        loading: false,
        error: null
      };
    case CREATE_AD_SUCCESS:
      return {...state, ads: [payload, ...state.ads], loading: false}
    case UPDATE_AD_SUCCESS:
      return {...state, ads: state.ads.map(ad => ad?.uid === payload?.uid ? payload : ad), loading: false, error: null}
    case GET_ADS_FAIL:
    case CREATE_AD_FAIL:
    case UPDATE_AD_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default adsReducer;
