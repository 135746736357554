import React from "react";
import {Popconfirm, Typography} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


export const getAttributeColumns = (sortedInfo, onRecordSelected, onDeleteRecord) => [
        {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.length - b?.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },{
      title: 'Color',
      dataIndex: 'color',
      key: 'color',
      sorter: (a, b) => a?.color?.length - b?.color.length,
      sortOrder: sortedInfo.columnKey === 'color' ? sortedInfo.order : null,
      ellipsis: true,
            render: (text, record) => <div style={{width: 20, height: 20, backgroundColor: record?.color, borderRadius: 20}}/>
        },

    {
      title: 'Action',
      render: (_, record) => {
        return (
            <React.Fragment>
                <Typography.Link onClick={() => onRecordSelected(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                    <EditOutlined /> Edit
                </Typography.Link>

                <Popconfirm
                    onConfirm={() => onDeleteRecord(record)}
                    title="Delete Confirmation"
                    description="Are you sure to delete this product color?"
                    okText="Yes"
                    cancelText="No">
                    <Typography.Link
                        style={{ color: '#ff0000' }}>
                        <DeleteOutlined />
                        Delete
                    </Typography.Link>
                </Popconfirm>
            </React.Fragment>
        );
      },
    },
    ]
