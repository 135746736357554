import {
    ADD_PROJECT,
    ADD_PROJECT_CATEGORY,
    ADD_PROJECT_CATEGORY_SUCCESS,
    ADD_PROJECT_FAIL,
    ADD_PROJECT_IMAGES,
    ADD_PROJECT_IMAGES_FAIL,
    ADD_PROJECT_IMAGES_SUCCESS,
    ADD_PROJECT_SUCCESS,
    GET_PROJECT_IMAGES,
    GET_PROJECT_IMAGES_FAIL,
    GET_PROJECT_IMAGES_SUCCESS,
    GET_PROJECTS,
    GET_PROJECTS_CATEGORY,
    GET_PROJECTS_CATEGORY_FAIL,
    GET_PROJECTS_CATEGORY_SUCCESS,
    GET_PROJECTS_FAIL,
    GET_PROJECTS_SUCCESS,
    SET_SELECTED_PROJECT,
    UPDATE_PROJECT,
    UPDATE_PROJECT_CATEGORY,
    UPDATE_PROJECT_CATEGORY_FAIL,
    UPDATE_PROJECT_CATEGORY_SUCCESS,
    UPDATE_PROJECT_FAIL,
    UPDATE_PROJECT_SUCCESS,
} from './action-types';

const INIT_STATE = {
  projectList: [],
  projectCategories: [],
  images: [],
  selectedProject: null,
  loading: false,
  error: null,
};

const projectsReducer = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case GET_PROJECTS:
    case GET_PROJECTS_CATEGORY:
    case ADD_PROJECT:
    case UPDATE_PROJECT:
    case ADD_PROJECT_CATEGORY:
    case UPDATE_PROJECT_CATEGORY:
    case GET_PROJECT_IMAGES:
    case ADD_PROJECT_IMAGES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PROJECTS_SUCCESS:
      return {
        ...state,
        projectList: payload,
        loading: false,
      };
    case GET_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        images: payload,
        loading: false,
      };
    case ADD_PROJECT_IMAGES_SUCCESS:
      return {
        ...state,
        images: [payload, ...state.images],
        loading: false,
      };
    case GET_PROJECTS_CATEGORY_SUCCESS:
      return {
        ...state,
        projectCategories: payload,
        loading: false,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        projectList: state.projectList.map(project => project?.uid === payload?.uid ? payload : project), loading: false, success: "project has been updated", error: null
      }
    case UPDATE_PROJECT_CATEGORY_SUCCESS:
      return {
        ...state,
        projectCategories: state.projectCategories.map(category => category?.uid === payload?.uid ? payload : category), loading: false, success: "category has been updated", error: null
      }
    case ADD_PROJECT_SUCCESS:
      return {...state, projectList: [payload, ...state.projectList], loading: false, error: null}
    case ADD_PROJECT_CATEGORY_SUCCESS:
      return {...state, projectCategories: [payload, ...state.projectCategories], loading: false, error: null}

    case GET_PROJECTS_FAIL:
    case GET_PROJECTS_CATEGORY_FAIL:
    case ADD_PROJECT_FAIL:
    case UPDATE_PROJECT_FAIL:
    case UPDATE_PROJECT_CATEGORY_FAIL:
    case ADD_PROJECT_IMAGES_FAIL:
    case GET_PROJECT_IMAGES_FAIL:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case SET_SELECTED_PROJECT:
      return {
        ...state,
        selectedProject: payload,
        loading: false
      }

    default:
      return state;
  }
};

export default projectsReducer;
