import {
    ADD_PROJECT,
    ADD_PROJECT_CATEGORY,
    ADD_PROJECT_CATEGORY_FAIL,
    ADD_PROJECT_CATEGORY_SUCCESS,
    ADD_PROJECT_FAIL,
    ADD_PROJECT_IMAGES,
    ADD_PROJECT_IMAGES_FAIL,
    ADD_PROJECT_IMAGES_SUCCESS,
    ADD_PROJECT_SUCCESS,
    GET_PROJECT_IMAGES,
    GET_PROJECT_IMAGES_FAIL,
    GET_PROJECT_IMAGES_SUCCESS,
    GET_PROJECTS,
    GET_PROJECTS_CATEGORY,
    GET_PROJECTS_CATEGORY_FAIL,
    GET_PROJECTS_CATEGORY_SUCCESS,
    GET_PROJECTS_FAIL,
    GET_PROJECTS_SUCCESS,
    SET_SELECTED_PROJECT,
    UPDATE_PROJECT,
    UPDATE_PROJECT_CATEGORY,
    UPDATE_PROJECT_CATEGORY_FAIL,
    UPDATE_PROJECT_CATEGORY_SUCCESS,
    UPDATE_PROJECT_FAIL,
    UPDATE_PROJECT_SUCCESS
} from './action-types';

// Projects actions
export const getProjects = payload => ({
  type: GET_PROJECTS,
  payload,
});

export const getProjectsSuccess = user => ({
  type: GET_PROJECTS_SUCCESS,
  payload: user,
});

export const getProjectsFail = error => ({
  type: GET_PROJECTS_FAIL,
  payload: error,
});

export const createProject = (payload, callback) => ({
  type: ADD_PROJECT,
  payload: {payload, callback},
});

export const createProjectSuccess = user => ({
  type: ADD_PROJECT_SUCCESS,
  payload: user,
});

export const createProjectFail = error => ({
  type: ADD_PROJECT_FAIL,
  payload: error,
});

// Project categories actions
export const createProjectCategory = (payload, callback) => ({
  type: ADD_PROJECT_CATEGORY,
  payload: {payload, callback},
});

export const createProjectCategorySuccess = payload => ({
  type: ADD_PROJECT_CATEGORY_SUCCESS,
  payload,
});

export const createProjectCategoryFail = payload => ({
  type: ADD_PROJECT_CATEGORY_FAIL,
  payload,
});
export const updateProjectCategory = (categoryId, payload, callback) => ({
  type: UPDATE_PROJECT_CATEGORY,
  payload: {categoryId, payload, callback},
});

export const updateProjectCategorySuccess = payload => ({
  type: UPDATE_PROJECT_CATEGORY_SUCCESS,
  payload,
});

export const updateProjectCategoryFail = payload => ({
  type: UPDATE_PROJECT_CATEGORY_FAIL,
  payload,
});
export const updateProject = (projectId, payload, callback) => ({
  type: UPDATE_PROJECT,
  payload: {projectId, payload, callback},
});

export const updateProjectSuccess = payload => ({
  type: UPDATE_PROJECT_SUCCESS,
  payload,
});

export const updateProjectFail = payload => ({
  type: UPDATE_PROJECT_FAIL,
  payload,
});

export const getProjectCategories = payload => ({
  type: GET_PROJECTS_CATEGORY,
  payload,
});

export const getProjectCategoriesSuccess = payload => ({
  type: GET_PROJECTS_CATEGORY_SUCCESS,
  payload,
});

export const getProjectCategoriesFail = payload => ({
  type: GET_PROJECTS_CATEGORY_FAIL,
  payload,
});

export const setSelectedProject = payload => ({
  type: SET_SELECTED_PROJECT,
  payload
})

export const getProjectImages = payload => ({
  type: GET_PROJECT_IMAGES,
  payload,
});

export const getProjectImagesSuccess = payload => ({
  type: GET_PROJECT_IMAGES_SUCCESS,
  payload,
});

export const getProjectImagesFail = payload => ({
  type: GET_PROJECT_IMAGES_FAIL,
  payload,
});


export const createProjectImage = (payload, callback) => ({
  type: ADD_PROJECT_IMAGES,
  payload: {payload, callback},
});

export const createProjectImageSuccess = payload => ({
  type: ADD_PROJECT_IMAGES_SUCCESS,
  payload,
});

export const createProjectImageFail = payload => ({
  type: ADD_PROJECT_IMAGES_FAIL,
  payload,
});
