import React, {useRef, useState} from "react";
import {Avatar, Col, Divider, Drawer, Row, Typography} from "antd";
import {connect} from "react-redux";
import {getUserFullName} from "../../../utils/lib/helpers";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {CheckCircleFilled, LoadingOutlined} from "@ant-design/icons";
import ImagePreview from "./image-preview";
import {resetUserPassword, verifyTechnician} from "../../../store/users/actions";
import {regenerateIDCard} from "../../../utils/api";


const TechnicianProfile = props => {
    const {technician, onClose, isVisible, loading, error, user, onVerifyUser, updates, onResetUserPassword} = props;
    const {t} = useTranslation();
    const [preview, setPreview] = useState({show: false, imgSrc: null})
    const imgPreviewRef = useRef();
    const [regenerating, setRegenerating] = useState(false);

    const handleOnAccountVerification = () => {
        const payload = {user_id: technician?.user_id, verified_by: user?.uid}
        onVerifyUser(technician?.uid, payload, onClose)
    }

    const handleIdCardRegenerate = async () => {
        const payload = {user_id: technician?.uid}
        setRegenerating(true)
        await regenerateIDCard(payload).then(response => {
            const idCard = response.data.id_card_image;
            setPreview(prevState => ({show: true, imgSrc: idCard}))
            setRegenerating(false)
        }).catch(err => {
            console.log(err);
            setRegenerating(false);
        });
    }

    return(
        <Drawer
            title={"Technician Profile"}
            placement={'right'}
            closable={true}
            onClose={onClose}
            open={isVisible}>
            <Row>
                <Col lg={18}>
                    <Typography.Title level={4} style={{margin: 0}}>{getUserFullName(technician)}</Typography.Title>
                    <Typography.Text>{technician?.district?.name} - {technician?.region?.name}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Avatar
                        src={technician?.photo}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                        onClick={() => setPreview(prevState => ({show: !prevState?.show, imgSrc: technician?.photo}))}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {['user_id', 'phone_number', 'dob', 'alternative_phone_number', 'created_by'].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k)}</Typography.Title>
                        <Typography.Text>{k === 'user_id' ? technician[k].match(/.{1,3}/g).join('-') : k === 'created_by' ? technician[k]?.agent_office_name : technician[k]}</Typography.Text>
                    </Col>
                )))}
            </Row>
            <Divider />
            <Row>
                {['id_card_image','nida_id_photo', 'qr_code'].map((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k)}</Typography.Title>
                        {k !== 'qr_code' ? (
                            <Row>
                                <Col lg={6}>
                                    <Typography.Link
                                        style={{color: '#CAB52F', fontSize: 13}}
                                        onClick={() => setPreview(prevState => ({show: !prevState?.show, imgSrc: technician[k], canDownload: k === 'id_card_image', fileName: getUserFullName(technician)}))}>
                                        Click to View
                                    </Typography.Link>
                                </Col>
                                {k === 'id_card_image' && (
                                    <Col lg={18}>
                                        <Typography.Link
                                            style={{color: 'green', fontSize: 13, marginLeft: 16, fontWeight: '500'}}
                                            onClick={handleIdCardRegenerate}>
                                            {regenerating && <LoadingOutlined style={{marginRight: 8}}/> }
                                            {regenerating ? 'Regenerating ID Card ...' : 'Click to Regenerate' }
                                        </Typography.Link>
                                    </Col>
                                )}
                            </Row>
                        ) : (<img src={technician[k]} alt={getUserFullName(technician)} width={100} height={100}/> )}
                    </Col>
                ))}
            </Row>
            <Divider />
            <Row>
                {['is_active', 'is_account_verified'].map((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(`${k}_title`)}</Typography.Title>
                        {technician[k] ? (
                            <Typography.Text style={{color: '#CAB52F'}}>
                                <CheckCircleFilled name={'check-circle'}/>
                                 &nbsp; {t(k)}
                            </Typography.Text>
                        ) : (
                            <Typography.Link style={{color: '#CAB52F', fontSize: 13}} onClick={() => k === 'is_account_verified' ? handleOnAccountVerification() : {}}>Click to  {t(`${k}_action`)}</Typography.Link>
                        )}
                    </Col>
                ))}
            </Row>
            <Row>
                <Col lg={24} style={{marginTop: 10}}>
                    <Typography.Title level={5} style={{margin: 0}}>Password Reset</Typography.Title>
                    <Typography.Link style={{color: '#CAB52F', fontSize: 13}} onClick={() => onResetUserPassword(technician?.uid)}>Click to Reset</Typography.Link>
                    {updates?.message && (
                        <Typography.Title level={5} style={{marginTop: 10}}>{updates?.message}</Typography.Title>
                    )}
                </Col>
            </Row>

            {preview?.show && <ImagePreview
                ref={imgPreviewRef}
                imgSrc={preview?.imgSrc}
                visible={preview?.show}
                canDownload={preview?.canDownload}
                fileName={preview?.fileName}
                onVisibleChange={() => setPreview(prevState => ({show:  !prevState?.show, imgSrc: null, canDownload: false, fileName: null}))}/>}
        </Drawer>
    )
}

TechnicianProfile.propTypes = {
    user: PropTypes.object,
    technician: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    updates: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onVerifyUser: PropTypes.func,
    onResetUserPassword: PropTypes.func,
}

const mapStateToProps = ({users: {loading, error, updates}, auth: {user}}) => ({ loading, error, user, updates});

const mapDispatchToProps = dispatch => ({
    onVerifyUser: (userId, payload, callback) => dispatch(verifyTechnician(userId, payload, callback)),
    onResetUserPassword: (userId, callback) => dispatch(resetUserPassword(userId, callback))
});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianProfile);
