import {Typography} from "antd";
import React from "react";

export const getNotificationColumns = (onRecordSelected)  => [
    {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        ellipsis: true,
        render: (text, record) => (<Typography.Link onClick={() =>onRecordSelected(record, 'detail')} style={{ color: '#000', fontWeight: 'bold' }}>{text}</Typography.Link>)
    },
    {
        title: 'Message',
        dataIndex: 'message',
        key: 'message',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Notification Type',
        dataIndex: 'type',
        key: 'type',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Target Audience',
        dataIndex: 'target',
        key: 'target',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Region',
        dataIndex: 'region',
        key: 'region',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'District',
        dataIndex: 'district',
        key: 'district',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    }
];
