import React, {useEffect, useState} from 'react';
import {Col, Row, Space, Table, Typography} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {Link, useHistory} from 'react-router-dom'
import {AddLocation} from './add-location';
import {getDistricts} from 'store/locations/actions';
import {tablePagination} from 'utils/constants/layout';


const Districts = props => {

  document.title = 'Emirate Builders | Districts'
  const history = useHistory()
  const {
    user,
    onGetDistricts,
    regions,
    districts,
    loading,
    error,
    selectedRegion
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    objectToCreate: 'region'
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetDistricts({ region: selectedRegion?.uid })
  }, [onGetDistricts, selectedRegion])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // filters: [],
      // filteredValue: filteredInfo.name || null,
      // onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record, row) => (<Link to={`/districts/${row?.uid}/`} style={{ color: '#000', fontWeight: 'bold' }}>{record}</Link>)
    },
    {
      title: 'Region',
      dataIndex: 'region',
      key: 'region',
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>
            {`${selectedRegion?.name}`} <span style={{ fontWeight: 'normal' }}>&nbsp;&nbsp;districts.</span>
          </Typography.Title>
          <Space>
            {/* <Button onClick={() => { setState({ ...state, showCreateForm: true, objectToCreate: 'district' }) }}
              icon={<UserAddOutlined />}>Add District</Button>
            {hasSelected && (
              <Button onClick={() => { }} icon={<ExportOutlined />}>Export Data</Button>
            )} */}
          </Space>
        </Space>
        <Table
            rowKey={'uid'}
            pagination={tablePagination} rowSelection={rowSelection} columns={columns} dataSource={districts} onChange={handleChange} loading={loading} />
      </Col>

      <AddLocation
        isOpen={state.showCreateForm}
        user={user}
        onCancel={() => setState({ ...state, showCreateForm: false })}
        objectToCreate={state.objectToCreate}
        onCompleteRequest={() => {
          setState({ ...state, showCreateForm: false })
          if (state.objectToCreate === 'region') onGetRegions()
          else if (state.objectToCreate === 'district') onGetDistricts({ region: selectedRegion.uid })
        }}
        selectedRegion={selectedRegion}
      />
    </Row>
  )
}

Districts.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  onGetRegions: PropTypes.func,
  onGetDistricts: PropTypes.func,
  regions: PropTypes.array,
  districts: PropTypes.array,
  selectedRegion: PropTypes.any
}

const mapStateToProps = ({
  auth: { user },
  locations: { regions, districts, loading, error, selectedRegion }
}) => ({
  user, error, loading, regions, districts, selectedRegion
})

const mapDispatchToProps = dispatch => ({
  onGetDistricts: params => dispatch(getDistricts(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Districts);
