import React, {useEffect, useState} from 'react';
import {Button, Col, ColorPicker, Input, Row, Space, Typography} from 'antd';
import {ExportOutlined, FileAddOutlined,} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {tablePagination} from 'utils/constants/layout';
import {getAttributeColumns} from './attribute-columns';
import FormModal from "../../shared/form-modal";
import {createProductColor, getProductColors, updateProductColor} from "../../../store/products/actions";
import {Table} from "ant-table-extensions";

const ProductColor = props => {
    const {
        user,
        loading,
        colors,
        onGetProductColors,
        onCreateProductColor,
        onUpdateProductColor
    } = props

    const [form, setForm] = useState({isOpen: false, title: 'Add Product Color', action: '', record: null, btnLabel: 'Save'})

    const [filteredInfo, setFilteredInfo] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [color, setColor] = useState('')

    useEffect(() => {
        onGetProductColors();
    }, [onGetProductColors]);

    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const handleOnChange = (_, value) => setColor(value);

    const getFields = (formType) => {
        const sharedFields = [
            {name: "name", label: "Name", required: true, field: Input},
            {
                name: "description",
                label: "Description",
                required: true,
                field: Input.TextArea,
            },
            {name: "color", label: "Color", required: true, field: ColorPicker, fieldWidth: '10%', extraArgs: {showText: true}, onChange: handleOnChange},
        ]

        const fields = {create: [...sharedFields], edit: [...sharedFields]}
        return fields[formType]
    }
    const handleOnClose = () => { setForm(prevState => ({
        isOpen: !prevState.isOpen
    }))};

    const handleOnUpdateRecord = (record, data) => {
        const payload  = {...data}
        payload.color = color;
        onUpdateProductColor(record?.uid, payload, handleOnClose);
    }

    const handleOnDeleteRecord = (record) => {
        const payload = {archived: true}
        onUpdateProductColor(record?.uid, payload);
    }

    const handleOnEditRecord = record => {
        setForm(prevState => ({
            ...prevState,
            isOpen: !prevState?.isOpen,
            action: 'edit',
            initialValues: record,
            title: "Edit Color",
            btnLabel: "Update",
            fields: getFields('edit'),
            onCancel: handleOnClose,
            onSubmit: handleOnUpdateRecord
        }))
    }
    const handleOnSubmit = (data) => {
        const payload = {...data}
        payload.color = color;
        onCreateProductColor(payload, handleOnClose);
    }

    return (
        <Row>
            <Col>
                <Space
                    style={{
                        marginBottom: 16,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Typography.Title level={4} style={{display: 'flex', alignItems: 'center'}}>Product
                        Colors</Typography.Title>
                    <Space>
                        <Button onClick={() => {
                            setForm(prevState => ({...prevState,
                                action: 'create',
                                isOpen: !prevState?.isOpen,
                                formType: 'create',
                                title: "Add Product Color",
                                btnLabel: "Save",
                                fields: getFields('create'),
                                onCancel: handleOnClose,
                                onSubmit: handleOnSubmit
                            }))
                        }} icon={<FileAddOutlined/>}>Add Color</Button>
                    </Space>
                </Space>
                <Table
                    rowKey={'uid'}
                    exportable
                    exportableProps={{showColumnPicker: true, fileName: 'product-colors'}}
                    pagination={tablePagination}
                    rowSelection={rowSelection}
                   columns={getAttributeColumns(sortedInfo, handleOnEditRecord, handleOnDeleteRecord)}
                   dataSource={colors.filter(c => !c?.archived)}
                   onChange={handleChange}
                   loading={loading}/>
            </Col>
            {form?.isOpen && (<FormModal {...form} width={'25%'} />)}
        </Row>
    )
}

ProductColor.propTypes = {
    colors: PropTypes.array,
    user: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    onCreateProductColor: PropTypes.func,
    onGetProductColors: PropTypes.func,
    onUpdateProductColor: PropTypes.func,
}


const mapStateToProps = ({
                             auth: {user},
                             products: {colors, loading, error}
                         }) => ({
    user, error, loading, colors
})

const mapDispatchToProps = dispatch => ({
    onGetProductColors: (params) => dispatch(getProductColors(params)),
    onCreateProductColor: (payload, callback) => dispatch(createProductColor(payload, callback)),
    onUpdateProductColor: (colorId, payload, callback) => dispatch(updateProductColor(colorId, payload, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductColor);
