import {
  CREATE_PROFESSION_CATEGORY, CREATE_PROFESSION_CATEGORY_FAIL, CREATE_PROFESSION_CATEGORY_SUCCESS,
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_SUCCESS,
  GET_AGENTS_SUCCESS,
  GET_ALL_USERS_SUCCESS,
  GET_PROFESSION_CATEGORIES, GET_PROFESSION_CATEGORIES_FAIL, GET_PROFESSION_CATEGORIES_SUCCESS,
  GET_TECHNICIANS_SUCCESS,
  GET_USER_SALES,
  GET_USER_SALES_FAIL,
  GET_USER_SALES_SUCCESS,
  GET_USERS,
  GET_USERS_FAIL,
  GET_USERS_SUCCESS,
  RESET_USER_PASSWORD,
  RESET_USER_PASSWORD_FAIL,
  RESET_USER_PASSWORD_SUCCESS,
  UPDATE_PROFESSION_CATEGORY, UPDATE_PROFESSION_CATEGORY_FAIL, UPDATE_PROFESSION_CATEGORY_SUCCESS,
  UPDATE_USER,
  UPDATE_USER_FAIL,
  UPDATE_USER_SUCCESS,
  VERIFY_TECHNICIAN,
  VERIFY_TECHNICIAN_FAIL, VERIFY_TECHNICIAN_SUCCESS
} from './action-types';

const INIT_STATE = {
  agentList: [],
  techniciansList: [],
  userList: [],
  sales: [],
  allUsers: [],
  error: null,
  updates: {},
  loading: false
};

const users = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USERS:
    case VERIFY_TECHNICIAN:
    case CREATE_USER:
    case GET_USER_SALES:
    case UPDATE_USER:
    case RESET_USER_PASSWORD:
    case GET_PROFESSION_CATEGORIES:
    case CREATE_PROFESSION_CATEGORY:
    case UPDATE_PROFESSION_CATEGORY:
      return { ...state, loading: true, error: null, updates: null };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        userList: action?.payload?.results,
        categories: action?.payload?.results,
        pagination: {count: action?.payload?.count},
        error: null,
        loading: false,
      };
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        agentList: action?.payload?.results,
        pagination: {count: action?.payload?.count},
        error: null,
        loading: false,
      };
    case GET_PROFESSION_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action?.payload,
        error: null,
        loading: false,
      };
    case CREATE_PROFESSION_CATEGORY_SUCCESS:
      return {
        ...state,
        categories: [action?.payload, ...state.categories],
        error: null,
        loading: false,
      };
    case CREATE_USER_SUCCESS:
      return {...state, agentList: [action.payload, ...state.agentList], loading: false, error: null}
    case RESET_USER_PASSWORD_SUCCESS:
      return {...state, updates: action.payload, error: null, loading: false}
    case UPDATE_USER_SUCCESS:
      const _keys = {technician: 'techniciansList', agent: 'agentsList'}
        const obj_key = _keys[action.payload?.userType]
        console.log("Object Key", obj_key)
        console.log("Payload Data", action?.payload?.data)
      return {...state, agentList: state[obj_key].map(u => u?.uid === action?.payload?.data?.uid ? action?.payload?.data : u), loading: false, error: null}
    case UPDATE_PROFESSION_CATEGORY_SUCCESS:
      return {...state, categories: state.categories.map(category => category?.uid === action?.payload?.uid ? action?.payload : category), loading: false, error: null}
    case GET_TECHNICIANS_SUCCESS:
      const {count, results} = action?.payload
      return {
        ...state,
        techniciansList: results,
        pagination: {count},
        error: null,
        loading: false,
      };
    case GET_USER_SALES_SUCCESS:
      return {
        ...state,
        sales: action.payload,
        error: null,
        loading: false,
      };
    case VERIFY_TECHNICIAN_SUCCESS:
      return {
        ...state,
        techniciansList: state.techniciansList.map(t => t?.uid === action?.payload?.uid ? action?.payload : t),
        error: null,
        loading: false,
      };
    case GET_ALL_USERS_SUCCESS:
      return {
        ...state,
        allUsers: action.payload,
        error: null,
        loading: false,
      };
    case VERIFY_TECHNICIAN_FAIL:
    case CREATE_USER_FAIL:
    case GET_USERS_FAIL:
    case UPDATE_USER_FAIL:
    case GET_USER_SALES_FAIL:
    case RESET_USER_PASSWORD_FAIL:
    case UPDATE_PROFESSION_CATEGORY_FAIL:
    case CREATE_PROFESSION_CATEGORY_FAIL:
    case GET_PROFESSION_CATEGORIES_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default users;
