import {
    ADD_REWARD_SETTINGS,
    ADD_REWARD_SETTINGS_FAIL,
    ADD_REWARD_SETTINGS_SUCCESS,
    GET_REWARD_POINT_VALUE,
    GET_REWARD_SETTINGS,
    GET_REWARD_SETTINGS_FAIL,
    GET_REWARD_SETTINGS_SUCCESS,
    GET_SALES,
    GET_SALES_FAIL,
    GET_SALES_SUCCESS,
    UPDATE_REWARD_SETTING,
    UPDATE_REWARD_SETTING_FAIL,
    UPDATE_REWARD_SETTING_SUCCESS
} from './action-types';

const INIT_STATE = {
  rewardSettings: [],
  sales: [],
  loading: false,
  error: null,
  rewardPointValue: 0
};

const rewardsAndSales = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case GET_REWARD_SETTINGS:
    case GET_SALES:
    case ADD_REWARD_SETTINGS:
    case UPDATE_REWARD_SETTING:

      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_REWARD_SETTINGS_SUCCESS:
      return {
        ...state,
        rewardSettings: payload,
        loading: false,
      };

    case ADD_REWARD_SETTINGS_SUCCESS:
      return {...state, rewardSettings: [payload, ...state.rewardSettings], loading: false, error: null}
    case UPDATE_REWARD_SETTING_SUCCESS:
      return {...state, rewardSettings: state.rewardSettings.map(rs => rs?.uid === payload?.uid ? payload : rs), loading: false, error: null}
    case GET_SALES_SUCCESS:
        const {count, results} = payload;
      return {
        ...state,
        sales: results,
        pagination: {count},
        loading: false,
      };
    case GET_REWARD_POINT_VALUE:
      return {
        ...state,
        rewardPointValue: payload,
        loading: false, error: null
      }

    case ADD_REWARD_SETTINGS_FAIL:
    case UPDATE_REWARD_SETTING_FAIL:
    case GET_REWARD_SETTINGS_FAIL:
    case GET_SALES_FAIL:
      return {
        ...state,
        loading: false,
        error: payload
      };

    default:
      return state;
  }
};

export default rewardsAndSales;
