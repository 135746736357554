import React, {useEffect, useRef, useState} from "react";
import {Avatar, Col, Divider, Drawer, Row, Typography} from "antd";
import {connect} from "react-redux";
import {formatNumber, getUserFullName} from "../../../utils/lib/helpers";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {CheckCircleFilled} from "@ant-design/icons";
import {getUsers, getUserSales, updateUser, verifyTechnician} from "../../../store/users/actions";
import Sales from "../sales";
import {getSales} from "../../../store/sales-rewards/actions";
import {tablePagination} from "../../../utils/constants/layout";
import {Table} from "ant-table-extensions";
import {salesColumns} from "../sales/sales-columns";
import {getUserColumns} from "../technicians/user-columns";
import FilterForm from "../../shared/filter-form";
import TechnicianProfile from "../technicians/profile";
import SalesList from "../technicians/sales-list";
import ImagePreview from "../technicians/image-preview";


const TechnicianList = props => {
    const {onClose, isVisible, loading, error, user, onGetUsers, users, onUpdateTechnician} = props;
    const {t} = useTranslation();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [showProfile, setShowProfile] = useState({show: {profile: false, idPreview: false}, technician: null})

    const [sortedInfo, setSortedInfo] = useState({});
    const handleOnRecordSelected = (record, event) => {
        console.log("Record:::", record)
        setShowProfile(prevState => ({
            show: {
                [event]: !prevState?.show[event]
            },
            technician: record
        }));
    }

    const handleOnClose = (key) => {
        setShowProfile(prevState => ({
            ...prevState,
            show: {
                [key]: !prevState?.show[key]
            },
            technician: null
        }))
    }
    const handleChange = (pagination, filters, sorter) => {
        console.log('Various parameters', pagination, filters, sorter);
    };


    useEffect(() => {
        onGetUsers({q: user?.uid, designation: 'technician'});
    }, [onGetUsers]);

    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };

    const handleOnDeleteRecord = record => {
        const payload = {is_active: false, archived: true}
        onUpdateTechnician(record?.uid, payload, 'technician')
    }

    return(
        <Drawer
            title={`Agent Technicians`}
            placement={'right'}
            closable={true}
            size={'large'}
            width={'80%'}
            onClose={() => onClose('technicians')}
            open={isVisible}>
            <Row>
                <Col lg={20}>
                    <Typography.Title level={4} style={{margin: 0}}>{getUserFullName(user)} - {user?.user_id?.match(/.{1,3}/g).join('-')} </Typography.Title>
                    <Typography.Text>{user?.district?.name} - {user?.region?.name}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Avatar
                        src={user?.photo}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                    />
                </Col>
            </Row>
            <Divider />
            <Table
                pagination={tablePagination}
                rowKey={'uid'}
                exportable={true}
                exportableProps={{
                    fileName: 'Technicians',
                    showColumnPicker: true
                }}
                rowSelection={rowSelection}
                columns={getUserColumns(sortedInfo, handleOnRecordSelected, handleOnDeleteRecord)}
                dataSource={users}
                onChange={handleChange}
                loading={loading}
            />

            {showProfile?.show?.idPreview && (
                <ImagePreview
                    imgSrc={showProfile?.technician?.id_card_image}
                    canDownload
                    fileName={getUserFullName(showProfile?.technician)}
                    visible={showProfile?.show?.idPreview}
                    onVisibleChange={handleOnClose}
                />)}

            {showProfile?.show?.profile && (
                <TechnicianProfile
                    isVisible={showProfile?.show?.profile}
                    technician={showProfile?.technician}
                    onClose={handleOnClose}
                />
            )}

            {showProfile?.show?.sales && (
                <SalesList
                    title={'Technician'}
                    isVisible={showProfile?.show?.sales}
                    user={showProfile?.technician}
                    onClose={handleOnClose}
                />
            )}

        </Drawer>
    )
}

TechnicianList.propTypes = {
    user: PropTypes.object,
    users: PropTypes.array,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onGetUsers: PropTypes.func,
    onUpdateTechnician: PropTypes.func,
}

const mapStateToProps = ({users: {loading, error, techniciansList: users}}) => ({ loading, error, users});

const mapDispatchToProps = dispatch => ({
    onGetUsers: payload => dispatch(getUsers(payload)),
    onUpdateTechnician: (userId, payload, userType, callback) => dispatch(updateUser(userId, payload, userType, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TechnicianList);
