
export const formatNumber = (str, separator = ',') => {
  let x = `${str}`
  try {
    if (str !== null && str !== undefined) {
      try { x = str.split(separator).join('') } catch { }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    }
    else return ""
  } catch (error) {
    console.log(error)
  }
};


export const formatString = (text) => {
  if (text === null) return;
  return text
    .toLowerCase()
    .split(/ /g)
    .map((word) => `${word.substring(0, 1).toUpperCase()}${word.substring(1)}`)
    .join(' ');
};

export const getUserFullName = (user) => {
  return formatString(`${user?.first_name} ${user?.last_name}`);
};

export const calculateDiff = (a, b) => {
  return getPercentage(a - b, a + b);
};

export const getPercentage = (count, total) => {
  if (count === 0 && total === 0) return 0;
  return Math.round((parseInt(count) / parseInt(total)) * 100 * 10) / 10;
};

export const getQueryStringParams = (query) => {
  return query
    ? (/^[?#]/.test(query) ? query.slice(1) : query)
      .split('&')
      .reduce((params, param) => {
        let [key, value] = param.split('=');
        params[key] = value
          ? decodeURIComponent(value.replace(/\+/g, ' '))
          : '';
        return params;
      }, {})
    : {};
};

export const extractErrorMessage = (error) => {
  const jsonObject = JSON.parse(error.request.response)
  return Object.values(jsonObject)[0][0]
}

export const convertUploadedFileToBase64 = (file) => new Promise((resolve, reject) => {
  const fileReader = new FileReader()
  fileReader.readAsDataURL(file)

  fileReader.onload = () => {
    resolve(fileReader.result)
  }

  fileReader.onerror = (error) => {
    reject(error)
  }
})

export const validateEmail = email => {
  const pattern =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return email.match(pattern);
};