import {Popconfirm, Typography} from "antd";
import {formatNumber} from "../../../utils/lib/helpers";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";

export const getRewardColumns = (sortedInfo, onEditRecord, onDeleteRecord) => [
    {
        title: 'Points',
        dataIndex: 'points',
        key: 'points'
    },
    {
        title: 'Sales Amount',
        dataIndex: 'sales_amount',
        key: 'sales_amount'
    },
    {
        title: 'Accrued Points',
        dataIndex: 'accrued_points',
        key: 'accrued_points',
    },
    {
        title: 'Reward Amount',
        dataIndex: 'reward_amount',
        key: 'reward_amount'
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <React.Fragment>
                    <Typography.Link onClick={() => onEditRecord(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                        <EditOutlined /> Edit
                    </Typography.Link>
                    <Popconfirm
                        onConfirm={() => onDeleteRecord(record)}
                        title="Delete Confirmation"
                        description="Are you sure to delete this settings?"
                        okText="Yes"
                        cancelText="No">
                        <Typography.Link
                            style={{ color: '#ff0000' }}>
                            <DeleteOutlined />
                            Delete
                        </Typography.Link>
                    </Popconfirm>
                </React.Fragment>
            );
        },
    },
];
