import {CHANGE_LANGUAGE, CHANGE_LANGUAGE_FAIL, CHANGE_LANGUAGE_SUCCESS,} from './action-types';

export const changeLanguage = language => ({
  type: CHANGE_LANGUAGE,
  payload: language,
});

export const changeLanguageSuccess = user => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  payload: user,
});

export const changeLanguageFail = error => ({
  type: CHANGE_LANGUAGE_FAIL,
  payload: error,
});
