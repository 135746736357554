import React from "react";
import {Avatar, Col, Divider, Drawer, Row, Typography} from "antd";
import {connect} from "react-redux";
import {formatNumber, getUserFullName} from "../../../utils/lib/helpers";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {CheckCircleFilled} from "@ant-design/icons";
import userImg from '../../../assets/images/user.png';
import {resetUserPassword} from "../../../store/users/actions";

const AgentProfile = props => {
    const {agent, onClose, isVisible, loading, error, user, updates, onResetUserPassword} = props;
    const {t} = useTranslation();

    return(
        <Drawer
            title={"Agent Profile"}
            placement={'right'}
            closable={true}
            onClose={onClose}
            open={isVisible}>
            <Row>
                <Col lg={18}>
                    <Typography.Title level={4} style={{margin: 0}}>{getUserFullName(agent)}</Typography.Title>
                    <Typography.Text>{agent?.district?.name} - {agent?.region?.name}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Avatar
                        src={agent?.photo || userImg}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                        onClick={() => {}}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {['user_id', 'agent_office_name', 'phone_number', 'total_sales'].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k === 'user_id' ? `agent_${k}` : k)}</Typography.Title>
                        <Typography.Text>{k === 'user_id' ? agent[k].match(/.{1,3}/g).join('-') : k === 'total_sales' ? `TZS${formatNumber(agent[k])}` : agent[k]}</Typography.Text>
                    </Col>
                )))}
            </Row>
            <Divider />
            <Row>
                {['is_active'].map((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(`${k}_title`)}</Typography.Title>
                        {agent[k] ? (
                            <Typography.Text style={{color: '#CAB52F'}}>
                                <CheckCircleFilled name={'check-circle'}/>
                                 &nbsp; {t(k)}
                            </Typography.Text>
                        ) : (
                            <Typography.Link style={{color: '#CAB52F', fontSize: 13}} onClick={() => k === 'is_account_verified' ? handleOnAccountVerification() : {}}>Click to  {t(`${k}_action`)}</Typography.Link>
                        )}
                    </Col>
                ))}
            </Row>
            <Row>
                <Col lg={24} style={{marginTop: 10}}>
                    <Typography.Title level={5} style={{margin: 0}}>Password Reset</Typography.Title>
                    <Typography.Link style={{color: '#CAB52F', fontSize: 13}} onClick={() => onResetUserPassword(agent?.uid)}>Click to Reset</Typography.Link>
                    {updates?.message && (
                        <Typography.Title level={5} style={{marginTop: 10}}>{updates?.message}</Typography.Title>
                    )}
                </Col>
            </Row>
        </Drawer>
    )
}

AgentProfile.propTypes = {
    user: PropTypes.object,
    agent: PropTypes.object,
    updates: PropTypes.any,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onResetUserPassword: PropTypes.func,
}

const mapStateToProps = ({users: {loading, error, updates}, auth: {user}}) => ({ loading, error, user, updates});

const mapDispatchToProps = dispatch => ({
    onResetUserPassword: (userId, callback) => dispatch(resetUserPassword(userId, callback))
})

export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile);
