import React, {useEffect, useState} from "react";
import {Col, Divider, Drawer, Image, Row, Tag, Typography} from "antd";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {useTranslation} from "react-i18next";
import {PlusOutlined} from "@ant-design/icons";
import {createProductImage, getProductImages} from "../../../store/products/actions";
import ImagesForm from "../../shared/images-form";

const ProductDetail = props => {
    const {product, onClose, isVisible, loading, error, user, images, onGetProductImages, onCreateProductImages} = props;
    const {t} = useTranslation();
    const [state, setState] = useState({addImage: false})

    useEffect(() => {
        onGetProductImages({product: product?.uid});
    }, [onGetProductImages]);

    return(
        <Drawer
            title={t('detail', {record: product?.name})}
            placement={'right'}
            closable={true}
            onClose={onClose}
            open={isVisible}>
            <Row>
                <Col lg={18}>
                    <Typography.Title level={4} style={{margin: 0}}>{product?.name}</Typography.Title>
                    <Typography.Text style={{color: '#cbb52f'}}>{product?.serial_number}</Typography.Text>
                </Col>
                <Col lg={4}>
                    <Image
                        src={product?.image}
                        style={{width: 55, height: 55, cursor: 'pointer'}}
                        onClick={() => {}}
                    />
                </Col>
            </Row>
            <Divider />
            <Row>
                {['name', 'description', 'serial_number', 'min_order_quantity'].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(k === 'user_id' ? `product_${k}` : k)}</Typography.Title>
                        <Typography.Text>{product[k]}</Typography.Text>
                    </Col>
                )))}
            </Row>
            <Divider />
            <Row>
                {['categories', 'color', 'size'].map(((k, index) => (
                    <Col lg={24} key={index} style={{marginTop: index !== 0 ? 10 : 0}}>
                        <Typography.Title level={5} style={{margin: 0}}>{t(`product_${k}`)}</Typography.Title>
                        {product[k]?.length > 0 ? product[k]?.map(({name, uid}) => (
                            <Tag key={uid} color={'#cbb52f'}>{name}</Tag>
                        )) : 'N/A'}
                    </Col>
                )))}
            </Row>
            <Divider />
            <Row>
                <Col lg={24}>
                    <Typography.Title level={5} style={{margin: 0}}>Product Images</Typography.Title>
                    <Image.PreviewGroup
                        preview={{ onChange: (current, prev) => { }, }}>
                        {images?.map((_img, index) => (<Image key={`images-${index}`} height={120} src={_img.image} style={{ padding: '0  20px 10px 0px' }} /> ))}
                    </Image.PreviewGroup>
                    <div className='upload-button' style={{ padding: '0 0 0 0px' }}
                         onClick={() => setState(prevState => ({ ...state, addImage: !prevState?.addImage }))}>
                        <PlusOutlined />
                        <div style={{ marginTop: 10, fontSize: 13 }}>Add Image</div>
                    </div>
                </Col>
            </Row>
            {state?.addImage && (
                <ImagesForm
                    isOpen={state?.addImage}
                    onCancel={() => setState(prevState => ({ ...state, addImage: !prevState?.addImage }))}
                    user={user}
                    imageList={images}
                    objectName={'product'}
                    objectRefId={product?.uid}
                    onSubmit={onCreateProductImages}
                />
            )}
        </Drawer>
    )
}

ProductDetail.propTypes = {
    user: PropTypes.object,
    product: PropTypes.object,
    loading: PropTypes.bool,
    error: PropTypes.any,
    isVisible: PropTypes.bool,
    onClose: PropTypes.func,
    onGetProductImages: PropTypes.func,
    images: PropTypes.array,
    onCreateProductImages: PropTypes.func,
}

const mapStateToProps = ({auth: {user}, products: {images, loading, error}}) => ({ loading, error, images, user});

const mapDispatchToProps = dispatch => ({
    onGetProductImages: (params) => dispatch(getProductImages(params)),
    onCreateProductImages: (payload, callback) => dispatch(createProductImage(payload, callback)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
