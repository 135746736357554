export const GET_ADS = 'GET_ADS';
export const GET_ADS_SUCCESS = 'GET_ADS_SUCCESS';
export const GET_ADS_FAIL = 'GET_ADS_FAIL';

export const CREATE_AD = 'CREATE_AD';
export const CREATE_AD_SUCCESS = 'CREATE_AD_SUCCESS';
export const CREATE_AD_FAIL = 'CREATE_AD_FAIL';

export const UPDATE_AD = 'UPDATE_AD';
export const UPDATE_AD_SUCCESS = 'UPDATE_AD_SUCCESS';
export const UPDATE_AD_FAIL = 'UPDATE_AD_FAIL';
