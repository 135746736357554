import {applyMiddleware, createStore} from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';

import {composeWithDevTools} from 'redux-devtools-extension';

import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {persistReducer} from 'redux-persist';
import logger from 'redux-logger';
import {createHashHistory} from 'history';

import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

export const history = createHashHistory({
  hashType: 'slash',
});

const persistConfig = {
  key: 'emirate',
  storage,
  blacklist: ['router'],
};

const initialState = {};
const middleware = [sagaMiddleware, thunk, logger];
const persistedReducer = persistReducer(persistConfig, rootReducer(history));

const store = createStore(
  persistedReducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware)),
);

sagaMiddleware.run(rootSaga);

export default store;
