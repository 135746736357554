import React from 'react';
import {Button, Col, Divider, Form, Modal, Row} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types'

const FormModal = (props) => {

    const {isOpen, loading, layout, width, initialValues, btnLabel, fields, title, onCancel, onSubmit, attachment} = props;
    const [form] = Form.useForm();

    return (
        <Modal
            title={
                <Row style={{ justifyContent: 'flex-start', color: "#CAB52F" }}>
                    <Col span={22} lg={22} md={22} sm={22}>
                        <span style={{ fontSize: 16, textTransform: 'capitalize' }}>
                          {title}
                        </span>
                    </Col>
                    <Divider/>
                </Row>
            }
            open={isOpen}
            onCancel={onCancel}
            width={width || '40%'}
            okButtonProps={{
                type: 'primary',
                disabled: loading
            }}
            style={{ top: '20px'}}
            footer={[]}>
            <Form
                layout={layout || 'vertical'}
                form={form}
                initialValues={initialValues}
                onFinish={(v) => initialValues && Object.keys(initialValues)?.length > 0 ? onSubmit(initialValues, v) : onSubmit(v)}>
                {fields.map(({name, label, options, fieldType, field: Field, onChange, extraArgs, fieldWidth}, index) => (
                    <Form.Item label={label} name={name}  htmlFor={name} key={index} style={{fontWeight: '600'}} required >
                        {fieldType !== 'attachment' && ( <Field placeholder={label} size={'large'} {...extraArgs} onChange={onChange}  style={{width: fieldWidth || '100%'}}/>)}
                        {fieldType === 'attachment' && (
                            <React.Fragment>
                                { attachment || initialValues?.image && <img
                                    alt={name}
                                    style={{ height: '150px', width: '250px', resize: 'initial' }}
                                    src={attachment || initialValues?.image }
                                /> }
                                {
                                    !attachment &&
                                    <Field
                                        id={name}
                                        style={{ width: '100%' }}
                                        onChange={(e) => { }}
                                        beforeUpload={onChange}
                                        multiple={false}
                                        onRemove={() => { }}
                                        accept='png,jpeg,jpg'
                                        action={null}>
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p style={{ fontSize: 16, fontWeight: '400' }}>
                                            Click or drag file to this area to upload Image<br />
                                            <span style={{ color: 'red' }}>Maximum upload size: 1MB</span>
                                        </p>
                                    </Field>
                                }
                            </React.Fragment>
                        )}
                    </Form.Item>
                ))}
                <Divider />
                <Row>
                    <Col lg={24} sm={12} md={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                        <Button key="back" size='large' onClick={onCancel}>
                            Cancel
                        </Button>
                        <Form.Item >
                            <Button size='large' type='primary' htmlType='submit' loading={loading}>
                                {btnLabel}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

FormModal.propTypes = {
    width: PropTypes.string,
    layout: PropTypes.string,
    title: PropTypes.string,
    attachment: PropTypes.string,
    btnLabel: PropTypes.string,
    action: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    initialValues: PropTypes.object,
}
export default FormModal
