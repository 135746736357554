import {combineReducers} from 'redux';
import app from '../app/reducer';
import auth from 'store/auth/reducer';
import users from 'store/users/reducer';
import locations from 'store/locations/reducer';
import products from 'store/products/reducer';
import adsReducer from 'store/adds/reducer';
import rewardsAndSales from 'store/sales-rewards/reducer';
import projectsReducer from 'store/projects/reducer';
import {connectRouter} from 'connected-react-router';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app,
    auth,
    users,
    locations,
    products,
    adsReducer,
    rewardsAndSales,
    projectsReducer
  });
export default rootReducer;
