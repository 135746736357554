import {CHANGE_LANGUAGE, CHANGE_LANGUAGE_FAIL, CHANGE_LANGUAGE_SUCCESS,} from './action-types';

const INIT_STATE = {
  name: 'emirate',
  version: '0.0.1',
  language: 'en',
  error: null,
  loading: false,
};

const app = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {...state, loading: true};
    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: action.payload,
        error: null,
        loading: false,
      };
    case CHANGE_LANGUAGE_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: null,
      };

    default:
      return state;
  }
};

export default app;
