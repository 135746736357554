import React, {useState} from 'react';
import {Button, Col, Image, Modal, Row, Upload} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types'
import {convertUploadedFileToBase64} from 'utils/lib/helpers';


const ImagesForm = (props) => {
  const {
    isOpen,
    onCancel,
    user,
    onSubmit,
      imageList,
      loading,
      error,
      objectRefId,
    objectName
  } = props;

  const [images, setImages] = useState([])

  const handleOnSubmit = async (values) => {
    const payload = {
      [objectName]: objectRefId,
      images: images
    }
    onSubmit(payload, onCancel)
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F" }}>
          <Col span={12} lg={10} md={12} sm={12}>
            <span style={{ fontSize: 18, textTransform: 'capitalize' }}>{`Add Product Images`}</span>
          </Col>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'20vw'}
      okButtonProps={{
        type: 'primary',
        disabled: loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}>
      <Row>
        <Col span={24} lg={24} md={24} sm={24}>
          <Image.PreviewGroup preview={true}>
            {
              images?.map((img, idx) => {
                return (
                  <Image key={`add-images-${idx}`} src={img} width={110} />
                )
              })
            }
          </Image.PreviewGroup>
          <div style={{ height: '30px' }} />
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            accept={'image/png, image/jpg'}
            beforeUpload={(file) => {
              convertUploadedFileToBase64(file).then(
                _file => setImages(prevState => ([...prevState, _file]))
              ).catch(e => console.log(e))
              return false
            }}
            onChange={() => { }}>
            <div>
              <PlusOutlined />
              <div style={{ marginTop: 8 }}>Add</div>
            </div>
          </Upload>
        </Col>
        <Row style={{width: '100%'}}>
          <Col lg={24} sm={24} md={24} style={{ marginTop: '30px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignContent: 'space-between'}}>
            <Button key="back" size='large' onClick={onCancel}>
              Cancel
            </Button>
            <Button size='large' type='primary' loading={loading} onClick={handleOnSubmit}>
              Publish
            </Button>
          </Col>
        </Row>
      </Row>
    </Modal>
  );
};

ImagesForm.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  images: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  onSubmit: PropTypes.func,
  objectRefId: PropTypes.string,
  objectName: PropTypes.string,
  imageList: PropTypes.array
}

export default ImagesForm
