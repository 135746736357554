export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const ADD_PRODUCT_FAIL = 'ADD_PRODUCT_FAIL';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCT_SUCCESS';
export const UPDATE_PRODUCT_FAIL = 'UPDATE_PRODUCT_FAIL';


export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAIL = 'GET_PRODUCTS_FAIL';

export const GET_PRODUCT_COLORS = 'GET_PRODUCT_COLORS';
export const GET_PRODUCT_COLORS_SUCCESS = 'GET_PRODUCT_COLORS_SUCCESS';
export const GET_PRODUCT_COLORS_FAIL = 'GET_PRODUCT_COLORS_FAIL';

export const GET_PRODUCT_SIZES = 'GET_PRODUCT_SIZES';
export const GET_PRODUCT_SIZES_SUCCESS = 'GET_PRODUCT_SIZES_SUCCESS';
export const GET_PRODUCT_SIZES_FAIL = 'GET_PRODUCT_SIZES_FAIL';

export const ADD_PRODUCT_CATEGORY = 'ADD_PRODUCT_CATEGORY';
export const ADD_PRODUCT_CATEGORY_SUCCESS = 'ADD_PRODUCT_CATEGORY_SUCCESS';
export const ADD_PRODUCT_CATEGORY_FAIL = 'ADD_PRODUCT_CATEGORY_FAIL';

export const UPDATE_PRODUCT_CATEGORY = 'UPDATE_PRODUCT_CATEGORY';
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS';
export const UPDATE_PRODUCT_CATEGORY_FAIL = 'UPDATE_PRODUCT_CATEGORY_FAIL';

export const ADD_PRODUCT_SIZE = 'ADD_PRODUCT_SIZE';
export const ADD_PRODUCT_SIZE_SUCCESS = 'ADD_PRODUCT_SIZE_SUCCESS';
export const ADD_PRODUCT_SIZE_FAIL = 'ADD_PRODUCT_SIZE_FAIL';

export const ADD_PRODUCT_COLOR = 'ADD_PRODUCT_COLOR';
export const ADD_PRODUCT_COLOR_SUCCESS = 'ADD_PRODUCT_COLOR_SUCCESS';
export const ADD_PRODUCT_COLOR_FAIL = 'ADD_PRODUCT_COLOR_FAIL';

export const ADD_PRODUCT_IMAGES = 'ADD_PRODUCT_IMAGES';
export const ADD_PRODUCT_IMAGES_SUCCESS = 'ADD_PRODUCT_IMAGES_SUCCESS';
export const ADD_PRODUCT_IMAGES_FAIL = 'ADD_PRODUCT_IMAGES_FAIL';

export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const GET_PRODUCT_IMAGES_SUCCESS = 'GET_PRODUCT_IMAGES_SUCCESS';
export const GET_PRODUCT_IMAGES_FAIL = 'GET_PRODUCT_IMAGES_FAIL';

export const UPDATE_PRODUCT_COLOR = 'UPDATE_PRODUCT_COLOR';
export const UPDATE_PRODUCT_COLOR_SUCCESS = 'UPDATE_PRODUCT_COLOR_SUCCESS';
export const UPDATE_PRODUCT_COLOR_FAIL = 'UPDATE_PRODUCT_COLOR_FAIL';


export const UPDATE_PRODUCT_SIZE = 'UPDATE_PRODUCT_SIZE';
export const UPDATE_PRODUCT_SIZE_SUCCESS = 'UPDATE_PRODUCT_SIZE_SUCCESS';
export const UPDATE_PRODUCT_SIZE_FAIL = 'UPDATE_PRODUCT_SIZE_FAIL';

export const GET_PRODUCT_CATEGORIES = 'GET_PRODUCT_CATEGORIES';
export const GET_PRODUCT_CATEGORIES_SUCCESS = 'GET_PRODUCT_CATEGORIES_SUCCESS';
export const GET_PRODUCT_CATEGORIES_FAIL = 'GET_PRODUCT_CATEGORIES_FAIL';

export const SET_SELECTED_PRODUCT = 'SET_SELECTED_PRODUCT'
