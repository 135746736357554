import React, {useEffect} from 'react';
import {Button, Drawer, Form, Input, InputNumber, Select,} from 'antd';
import PropTypes from 'prop-types';
import {getUserFullName} from '../../utils/lib/helpers';

const DrawerForm = props => {
  const {
    placement,
    onClose,
    onSubmitForm,
    visible,
    fields,
    onValuesChange,
    inputStyle,
    containerStyle,
    fieldSize,
    title,
    loading,
    buttonLabel,
    selectedRecord,
      onDrawerMount,
      customOptions
  } = props;
  const [form] = Form.useForm();

  const getField = type => {
    const _f = {
      textArea: Input.TextArea,
      password: Input.Password,
      number: InputNumber,
    };
    return _f[type];
  };

  useEffect(() => {
    customOptions()
  }, []);

  return (
    <Drawer
        afterOpenChange={(open) => onDrawerMount ? onDrawerMount() : {}}
      title={title}
      placement={placement}
      closable={true}
      onClose={onClose}
      open={visible}
      key={placement}>
      <Form
        layout={'vertical'}
        form={form}
        onValuesChange={onValuesChange}
        initialValues={selectedRecord}
        onFinish={values => onSubmitForm(values, onClose, selectedRecord)}>
        {fields?.map(
          (
            {
              label,
              name,
              placeholder,
              fieldType,
              inputType,
              options,
              user,
              onChange,
                extraArgs
            },
            index,
          ) => {
            const FormField =
              fieldType && fieldType !== 'select' ? getField(fieldType) : Input;
            return (
              <Form.Item
                key={index}
                label={label}
                name={name}
                required
                htmlFor={name}
                // initialValue={selectedRecord}
                style={{...containerStyle, color: '#000000', fontWeight: '600'}}>
                {fieldType !== 'select' ? (
                  <FormField
                    style={{width: '100%', ...inputStyle}}
                    placeholder={placeholder}
                    size={fieldSize}
                    name={name}
                    type={inputType}
                    {...extraArgs}
                  />
                ) : (
                  <Select
                    showSearch
                    allowClear
                    style={{...inputStyle}}
                    placeholder={placeholder}
                    size={fieldSize}
                    onChange={(v, _) => (onChange ? onChange(name, v) : {})}
                    name={name}>
                    {options?.map(option => (
                      <Select.Option key={option?.uid || option?.id}>
                        {user
                          ? `${getUserFullName(option)} - ${
                              option?.branch?.name
                            }`
                          : option?.name}
                        {option?.number && `- ${option?.number}`}
                      </Select.Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            );
          },
        )}
        <Form.Item style={{marginTop: 30}}>
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={loading}
            style={{width: '100%'}}>
            {buttonLabel}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};

DrawerForm.propTypes = {
  fields: PropTypes.array.isRequired,
  onValuesChange: PropTypes.func,
  onSubmitForm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  placement: PropTypes.string,
  inputStyle: PropTypes.object,
  containerStyle: PropTypes.object,
  fieldSize: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  buttonLabel: PropTypes.string,
  hasSection: PropTypes.bool,
  selectedRecord: PropTypes.object,
  customOptions: PropTypes.any,
  onDrawerMount: PropTypes.func,
};

export default DrawerForm;
