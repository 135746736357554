import React from "react";
import {Popconfirm, Space, Typography} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";


export const getSizeColumns = (sortedInfo, onRecordSelected, onDeleteRecord) =>  [
        {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a?.name?.length - b?.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
    },{
      title: 'Dimensions',
      dataIndex: 'dimensions',
      key: 'dimensions',
        },

    {
      title: 'Action',
      render: (_, record) => {
        return (
            <Space>
                <Typography.Link onClick={() => onRecordSelected(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                    <EditOutlined /> Edit
                </Typography.Link>
                <Popconfirm
                    onConfirm={() => onDeleteRecord(record)}
                    title="Delete Confirmation"
                    description="Are you sure to delete this product size?"
                    okText="Yes"
                    cancelText="No">
                    <Typography.Link
                        style={{ color: '#ff0000' }}>
                        <DeleteOutlined />
                        Delete
                    </Typography.Link>
                </Popconfirm>
            </Space>
        );
      },
    },
    ]
