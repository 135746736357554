import {
  ADD_PRODUCT,
  ADD_PRODUCT_CATEGORY_SUCCESS,
  ADD_PRODUCT_COLOR,
  ADD_PRODUCT_COLOR_FAIL,
  ADD_PRODUCT_COLOR_SUCCESS,
  ADD_PRODUCT_FAIL,
  ADD_PRODUCT_IMAGES,
  ADD_PRODUCT_IMAGES_FAIL,
  ADD_PRODUCT_IMAGES_SUCCESS,
  ADD_PRODUCT_SIZE,
  ADD_PRODUCT_SIZE_FAIL,
  ADD_PRODUCT_SIZE_SUCCESS,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT_CATEGORIES,
  GET_PRODUCT_CATEGORIES_FAIL,
  GET_PRODUCT_CATEGORIES_SUCCESS,
  GET_PRODUCT_COLORS,
  GET_PRODUCT_COLORS_FAIL,
  GET_PRODUCT_COLORS_SUCCESS,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_IMAGES_FAIL,
  GET_PRODUCT_IMAGES_SUCCESS,
  GET_PRODUCT_SIZES,
  GET_PRODUCT_SIZES_FAIL,
  GET_PRODUCT_SIZES_SUCCESS,
  GET_PRODUCTS,
  GET_PRODUCTS_FAIL,
  GET_PRODUCTS_SUCCESS,
  SET_SELECTED_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_PRODUCT_CATEGORY,
  UPDATE_PRODUCT_CATEGORY_FAIL,
  UPDATE_PRODUCT_CATEGORY_SUCCESS,
  UPDATE_PRODUCT_COLOR,
  UPDATE_PRODUCT_COLOR_FAIL,
  UPDATE_PRODUCT_COLOR_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  UPDATE_PRODUCT_SIZE,
  UPDATE_PRODUCT_SIZE_FAIL,
  UPDATE_PRODUCT_SIZE_SUCCESS,
  UPDATE_PRODUCT_SUCCESS,
} from './action-types';

const INIT_STATE = {
  productList: [],
  colors: [],
  sizes: [],
  images: [],
  productCategories: [],
  selectedProduct: null,
  loading: false,
  error: null,
};

const productsReducer = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case GET_PRODUCTS:
    case GET_PRODUCT_CATEGORIES:
    case GET_PRODUCT_COLORS:
    case GET_PRODUCT_SIZES:
    case ADD_PRODUCT_COLOR:
    case ADD_PRODUCT_SIZE:
    case UPDATE_PRODUCT:
    case UPDATE_PRODUCT_COLOR:
    case ADD_PRODUCT:
    case UPDATE_PRODUCT_SIZE:
    case UPDATE_PRODUCT_CATEGORY:
    case GET_PRODUCT_IMAGES:
    case ADD_PRODUCT_IMAGES:
      return {
        ...state,
        loading: true,
        error: null
      };
    case GET_PRODUCT_CATEGORIES_SUCCESS:
      return {
        ...state,
        productCategories: payload,
        loading: false,
      };
    case GET_PRODUCT_COLORS_SUCCESS:
      return {
        ...state,
        colors: payload,
        loading: false,
        error: null
      };
    case GET_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        images: payload,
        loading: false,
        error: null
      };
    case ADD_PRODUCT_IMAGES_SUCCESS:
      return {
        ...state,
        images: [payload, ...state.images],
        loading: false,
        error: null
      };
    case GET_PRODUCT_SIZES_SUCCESS:
      return {
        ...state,
        sizes: payload,
        loading: false,
        error: null
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        productList: state.productList.map(product => product?.uid === payload?.uid ? payload : product), loading: false, success: "product has been updated", error: null
      }
    case UPDATE_PRODUCT_CATEGORY_SUCCESS:
      return {
        ...state,
        productCategories: state.productCategories.map(category => category?.uid === payload?.uid ? payload : category), loading: false, success: "category has been updated", error: null
      }
    case UPDATE_PRODUCT_COLOR_SUCCESS:
      return {
        ...state,
        colors: state.colors.map(color => color?.uid === payload?.uid ? payload : color), loading: false, success: "color has been updated", error: null
      }
    case UPDATE_PRODUCT_SIZE_SUCCESS:
      return {
        ...state,
        sizes: state.sizes.map(size => size?.uid === payload?.uid ? payload : size), loading: false, success: "size has been updated", error: null
      }
    case ADD_PRODUCT_SUCCESS:
      return {...state, productList: [payload, ...state.productList], loading: false, error: null}
    case ADD_PRODUCT_SIZE_SUCCESS:
      return {...state, sizes: [payload, ...state.sizes], loading: false, error: null}
    case ADD_PRODUCT_COLOR_SUCCESS:
      return {...state, colors: [payload, ...state.colors], loading: false, error: null}
    case ADD_PRODUCT_CATEGORY_SUCCESS:
      return {...state, productCategories: [payload, ...state.productCategories], loading: false, error: null}
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productList: payload,
        loading: false,
      };
    case GET_PRODUCTS_FAIL:
    case ADD_PRODUCT_IMAGES_FAIL:
    case GET_PRODUCT_IMAGES_FAIL:
    case GET_PRODUCT_SIZES_FAIL:
    case GET_PRODUCT_COLORS_FAIL:
    case ADD_PRODUCT_COLOR_FAIL:
    case ADD_PRODUCT_SIZE_FAIL:
    case GET_PRODUCT_CATEGORIES_FAIL:
    case UPDATE_PRODUCT_COLOR_FAIL:
    case ADD_PRODUCT_FAIL:
    case UPDATE_PRODUCT_FAIL:
    case UPDATE_PRODUCT_SIZE_FAIL:
    case UPDATE_PRODUCT_CATEGORY_FAIL:
      return {
        ...state,
        error: payload,
        loading: false
      };

    case SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: payload,
        loading: false
      }

    default:
      return state;
  }
};

export default productsReducer;
