import React, {useState} from 'react';
import {Button, Form, Input, Modal, Row, Select} from 'antd';
import PropTypes from 'prop-types'
import api from 'utils/api/config';

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '100%',
  }
}

const AddNotification = ({
  isOpen,
  onCancel,
  user,
  onCompleteRequest,
  regions,
  districts,
  onGetDistricts
}) => {

  const [form] = Form.useForm();

  const [state, setState] = useState({
    loading: false
  })

  const handleOnValuesChange = (payload) => {
  }

  const submitData = async (values) => {
    setState({ ...state, loading: true })
    let payload = values
    payload.created_by = user.uid
    // console.log(payload); return;
    try {
      const response = await api(true).post('notification/create/', payload)
      if (response.status === 201) {
        onCompleteRequest(response.data)
      } else {
        onCompleteRequest('error')
      }
      setState({ ...state, loading: false, categories: [], image: null })
    } catch (e) {
      setState({ ...state, loading: false });
      onCompleteRequest('error')
    }
    form.resetFields()
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F", width: '100%' }}>
          <span style={{ fontSize: 25 }}>Create a new notification</span>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'40vw'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}
    >
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        onValuesChange={handleOnValuesChange}
        onFinish={v => submitData(v)}
      >
        <Form.Item label={`Title`} name='title' htmlFor='title'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Input style={_style.input} required placeholder={`Enter title`} size='large' name='title' />
        </Form.Item>

        <Form.Item label={`Notification Type`} name='type' required htmlFor='type'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Select placeholder="Select Type"
            showSearch={false}
            id='type' size='large'
          >
            {[
              { label: 'SMS Notification', value: 'SMS' },
              { label: 'In App Notification', value: 'APPLICATION' }
            ].map(({ label, value }, idx) => (
              <Select.Option value={value} key={`type-${idx}`}>{label}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={`Target Audience`} name='target' required htmlFor='target'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Select placeholder="All"
            showSearch={false}
            id='target' size='large'
          >
            {[
              { label: 'Technicians', value: 'technician' },
              { label: 'Agents', value: 'agent' },
              { label: 'Customers', value: 'customer' }
            ].map(({ label, value }, idx) => (
              <Select.Option value={value} key={`region-${idx}`}>{label}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={`Region`} name='region' required htmlFor='region'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Select placeholder="All"
            showSearch={false}
            id='target' size='large'
            onSelect={v => {
              form.setFieldValue('district', null)
              onGetDistricts({ region: v })
            }}
          >
            {regions.map((_region, idx) => (
              <Select.Option value={_region.uid} key={`region-${idx}`}>{_region.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={`District`} name='district' htmlFor='district'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Select placeholder="All"
            showSearch={false}
            id='target' size='large'
          >
            {districts.map((_district, idx) => (
              <Select.Option value={_district.uid} key={`district-${idx}`}>{_district.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label={`Message`} name='message' htmlFor='message'
          style={{ width: '90%', fontWeight: 'bold', textTransform: 'capitalize' }}>
          <Input.TextArea style={_style.input} required placeholder={`Enter Message`} size='large' name='message' />
        </Form.Item>

        <Row style={{ width: '100%' }}>
          <Button key="back" size='large' style={{ width: '40%' }}
            onClick={() => { setState({ ...state, loading: false }); onCancel() }}>
            Cancel
          </Button>
          <Button size='large' type='primary' htmlType='submit' loading={state.loading}
            style={{ textTransform: 'capitalize', marginLeft: '10%', width: '40%' }}>
            Send
          </Button>
        </Row>
      </Form>
    </Modal>
  );
};

AddNotification.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  onCompleteRequest: PropTypes.func,
  regions: PropTypes.array,
  districts: PropTypes.array,
  onGetDistricts: PropTypes.func
}

export { AddNotification };
