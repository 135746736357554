import api from './config';

export const authenticateUser = (credentials) => {
  return api(false).post('auth/token/', credentials);
};

// Agents
export const getUserList = (params) => {
  return api(true).get('user/list/', {params: {type: params?.designation, sort: params?.sort_by, ...params}});
};

// Agents
export const exportUserList = (params) => {
  return api(true).get('user/export/', {responseType: 'blob', params: {type: params?.designation, sort: params?.sort_by, ...params}});
};
export const exportSalesList = (params) => {
  return api(true).get('sales/export/', {responseType: 'blob', params});
};

// Locations APIs
export const getRegions = async params => {
  return await api(true).get('regions/list/', { params });
};

export const getDistricts = async payload => {
  let url = 'districts/list/'
  if (payload?.region) url = `regions/${payload.region}/districts/`
  return await api(true).get(url);
};


export const getWards = async payload => {
  return await api(true).get(`districts/${payload?.district}/wards/`);
};

// Products
export const addProduct = async (payload) => {
  return await api(true).post('product/create/', payload)
}
export const verifyUser = async ({userId, payload}) => {
  return await api(true).post(`user/${userId}/verify/`, payload)
}
export const resetUserPassword = async ({userId, payload}) => {
  return await api(true).post(`user/${userId}/reset-password/`, payload)
}
export const createUser = async (payload) => {
  return await api(true).post(`user/create/`, payload)
}
export const updateUser = async ({userId, payload}) => {
  return await api(true).put(`user/${userId}/update/`, payload)
}
export const updateProduct = async ({productId, payload}) => {
  return await api(true).put(`product/${productId}/update/`, payload)
}

export const getProducts = async () => {
  return await api(true).get('product/list/')
}

export const addProductCategory = async (payload) => {
  return await api(true).post('product-category/create/', payload)
}

export const getProductCategories = async () => {
  return await api(true).get('product-category/list/')
}

export const addProductColor = async (payload) => {
  return await api(true).post('product-color/create/', payload)
}

export const addProductImages = async (payload) => {
  return await api(true).post('product-image/create/', payload)
}


export const addProjectImages = async (payload) => {
  return await api(true).post('project-images/create/', payload)
}

export const getProductImages = async (params) => {
  return await api(true).get('product-image/list/', {params})
}

export const getProjectImages = async (params) => {
  return await api(true).get('project-images/list/', {params})
}

export const updateProductColor = async ({colorId, payload}) => {
  return await api(true).put(`product-color/${colorId}/update/`, payload)
}

export const updateProductSize = async ({sizeId, payload}) => {
  return await api(true).put(`product-size/${sizeId}/update/`, payload)
}

export const updateProductCategory = async ({categoryId, payload}) => {
  return await api(true).put(`product-category/${categoryId}/update/`, payload)
}

export const getProductSizes = async () => {
  return await api(true).get('product-size/list/')
}

export const addProductSize = async (payload) => {
  return await api(true).post('product-size/create/', payload)
}

export const getProductColors = async () => {
  return await api(true).get('product-color/list/')
}

// Ads
export const getAds = async params => {
  return await api(true).get('ads/list/', { params });
};
export const createAd = async payload => {
  console.log("Sending Request::", payload)
  return await api(true).post('ads/create/', payload);
};
export const updateAd = async ({adId, payload}) => {
  return await api(true).put(`ads/${adId}/update/`, payload);
};

// reward settings
export const getRewardSettings = async params => {
  return await api(true).get('reward-setting/list/', { params });
};
export const createRewardSetting = async payload => {
  return await api(true).post('reward-setting/create/', payload);
};
export const updateRewardSetting = async ({rewardId, payload}) => {
  return await api(true).put(`reward-setting/${rewardId}/update/`, payload);
};

export const getSales = async params => {
  return await api(true).get('sales/list/', { params });
};


export const getPersonalSales = async ({userId}) => {
  return await api(true).get(`sales/${userId}/list`);
};

// Projects
export const addProject = async (payload) => {
  return await api(true).post('project/create/', payload)
}
export const updateProject = async ({projectId, payload}) => {
  return await api(true).put(`project/${projectId}/update/`, payload)
}

export const getProjects = async params => {
  return await api(true).get('project/list/', { params });
};

export const addProfessionCategory = async (payload) => {
  return await api(true).post('profession-category/create/', payload)
}
export const updateProfessionCategory = async ({categoryId, payload}) => {
  return await api(true).put(`profession-category/${categoryId}/update/`, payload)
}

export const getProfessionCategories = async params => {
  return await api(true).get('profession-category/list/', { params });
};

export const addProjectCategory = async (payload) => {
  return await api(true).post('project-category/create/', payload)
}

export const updateProjectCategory = async ({categoryId, payload}) => {
  return await api(true).put(`project-category/${categoryId}/update/`, payload)
}

export const getProjectCategories = async params => {
  return await api(true).get('project-category/list/', { params });
};

export const regenerateIDCard = async payload => {
  return await api(true).post('user/id-regenerate/', payload);
};
