import {Popconfirm, Tag, Typography} from "antd";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import api from "../../../utils/api/config";


export const productColumns= (sortedInfo, onRecordSelected, onEditRecord, onDeleteRecord) => [
    {
        title: 'Product Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (
            <Typography.Link onClick={() => onRecordSelected(record, 'detail')} style={{ color: '#000', fontWeight: 'bold' }}>
                {record.name}
            </Typography.Link>
        )
    },
    {
        title: 'Serial Number',
        dataIndex: 'serial_number',
        key: 'serial_number',
        sorter: (a, b) => a.name.length - b.name.length,
        sortOrder: sortedInfo.columnKey === 'serial_number' ? sortedInfo.order : null,
        ellipsis: true,
        render: (text, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{text ? text : '--'}</Typography.Link>)
    },
    {
        title: 'Min. Order Qty',
        dataIndex: 'min_order_quantity',
        key: 'min_order_quantity'
    },

    {
        title: 'Categories',
        dataIndex: 'categories',
        key: 'categories',
        render: (_, record) => {
            return record?.categories?.map(({uid, name}, index) => (
                <Tag key={uid} color={'#cbb52f'}>{name}</Tag>
            ))
        }
    },

    {
        title: 'Color',
        dataIndex: 'color',
        key: 'color',
        render: (_, record) => {
            return record?.color?.map(({uid, name, color}, index) => (
                <Tag key={uid} color={color}>{name}</Tag>
            ))
        }
    },

    {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        render: (_, record) => {
            return record?.size?.map(({uid, name, color}, index) => (
                <Tag key={uid} color={color}>{name}</Tag>
            ))
        }
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <React.Fragment>
                    <Typography.Link onClick={() => onEditRecord(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                        <EditOutlined /> Edit
                    </Typography.Link>
                    <Popconfirm
                        onConfirm={() => onDeleteRecord(record)}
                        title="Delete Confirmation"
                        description="Are you sure to delete this product color?"
                        okText="Yes"
                        cancelText="No">
                        <Typography.Link
                            style={{ color: '#ff0000' }}>
                            <DeleteOutlined />
                            Delete
                        </Typography.Link>
                    </Popconfirm>
                </React.Fragment>
            );
        },
    },
];
