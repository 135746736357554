import {Popconfirm, Tag, Typography} from "antd";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import api from "../../../utils/api/config";


export const getProjectColumns = (sortedInfo, onRecordSelected, onEditRecord, onDeleteRecord) => [
    {
        title: 'Project Title',
        dataIndex: 'project_title',
        key: 'project_title',
        sortOrder: sortedInfo.columnKey === 'project_title' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (<Typography.Link onClick={() => onRecordSelected(record, 'detail')} style={{ color: '#000', fontWeight: 'bold' }}>{record?.project_title}</Typography.Link>)
    },
    {
        title: 'Project Category',
        dataIndex: 'category',
        key: 'category',
        sortOrder: sortedInfo.columnKey === 'category' ? sortedInfo.order : null,
        ellipsis: true,
        width: '25%',
        render: (_, record) => record?.categories?.map(({uid, name}, index) => (
                <Tag key={uid} color={'#cbb52f'}>{name}</Tag>
            ))
    },
    {
        title: 'Region',
        dataIndex: 'region.name',
        key: 'region.name',
        sortOrder: sortedInfo.columnKey === 'region' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.region ? record?.region?.name : '--'}</Typography.Link>)
    },
    {
        title: 'District',
        dataIndex: 'district.name',
        key: 'district.name',
        sortOrder: sortedInfo.columnKey === 'district' ? sortedInfo.order : null,
        ellipsis: true,
        render: (_, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record?.district ? record?.district?.name : '--'}</Typography.Link>)
    },
    {
        title: 'Likes',
        dataIndex: 'number_of_likes',
        key: 'number_of_likes',
        ellipsis: true,
        render: (text, _) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{text ? text : '--'}</Typography.Link>)
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <React.Fragment>
                    <Typography.Link onClick={() =>  onEditRecord(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                        <EditOutlined /> Edit
                    </Typography.Link>
                    <Popconfirm
                        onConfirm={() => onDeleteRecord(record)}
                        title="Delete Confirmation"
                        description="Are you sure to delete this project?"
                        okText="Yes"
                        cancelText="No">
                        <Typography.Link
                            style={{ color: '#ff0000' }}>
                            <DeleteOutlined />
                            Delete
                        </Typography.Link>
                    </Popconfirm>
                </React.Fragment>
            );
        },
    },
];
