import {Typography} from "antd";
import {formatNumber} from "../../../utils/lib/helpers";
import React from "react";

export const salesColumns = [
    {
        title: 'Date, Time',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Agent',
        dataIndex: 'agent',
        key: 'agent',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Technician',
        dataIndex: 'technician',
        key: 'technician',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Sale Amount',
        dataIndex: 'sales_amount',
        key: 'sales_amount',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{formatNumber(record)}</Typography.Link>)
    },
    {
        title: 'Reward Points',
        dataIndex: 'reward',
        key: 'reward',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    }
];
