import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, InputNumber, Modal, Row, Select, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types'
import {convertUploadedFileToBase64} from 'utils/lib/helpers';
import {connect} from 'react-redux';
import {
    createProduct,
    getProductCategories,
    getProductColors,
    getProductSizes,
    updateProduct
} from "../../../store/products/actions";

const AddProduct = ({
  isOpen,
  onCancel,
  user,
  categories,
    action,
  sizes,
    title,
   colors,
    onCreateProduct,
    onGetProductCategories,
    initialValues,
    btnLabel,
    onUpdateProduct,
    loading,
    error,
    onGetProductSizes,
    onGetProductColors
}) => {

  const [form] = Form.useForm();
  const [state, setState] = useState({
    loading: false,
    categories: [],
    image: null
  })

  useEffect(() => {
    onGetProductCategories();
    onGetProductSizes();
    onGetProductColors();
  }, [onGetProductCategories]);

  const getFields = (formType) => {
    const sharedFields = [
      {name: "name", label: "Product Name", field: Input},
      {name: "serial_number", label: "Serial Number", field: Input},
      {name: "categories", label: "Product Category", options: categories, fieldType: 'select', field: Select, mode: 'multiple'},
      {name: "color", label: "Product Color", options: colors, fieldType: 'select', field: Select, mode: 'multiple'},
      {name: "size", label: "Product Size", options: sizes, fieldType: 'select', field: Select, mode: 'multiple'},
      {name: "min_order_quantity", label: "Min. Order Quantity", fieldType: 'number', field: InputNumber},
      {name: "description", label: "Product Description", fieldType: 'text-area', field: Input.TextArea},
    ]
    const fields = {
      edit: [...sharedFields], create: [...sharedFields,
        {name: "image", label: "Product Image", fieldType: 'attachment', field: Upload.Dragger },
      ] }
    return fields[formType]
  }

  const handleOnSubmit = (payload) => {
    payload.created_by = user?.uid
    payload.image = state.image
    onCreateProduct(payload, onCancel)
  }

  const handleOnUpdate = (record, values) => {
    onUpdateProduct(record?.uid, values, onCancel);
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F" }}>
          <Col span={12} lg={10} md={12} sm={12}>
            <span style={{ fontSize: 20, textTransform: 'capitalize' }}>
              {title}
            </span>
          </Col>
          <Divider/>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'40%'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}>
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        required
        initialValues={initialValues}
        onFinish={v => action === 'edit' ? handleOnUpdate(initialValues, v) : handleOnSubmit(v)}>
          {getFields(action)?.map(({name, label, options, fieldType, field: Field, mode}, index) => (
              <Form.Item label={label} name={name}  htmlFor={name} key={index} style={{fontWeight: '600'}} required>
                {fieldType !== 'attachment' && fieldType !=='select' && ( <Field placeholder={label} size={'large'} style={{width: '100%'}}/>)}
                {fieldType === 'select' && (
                    <Field
                        placeholder={label}
                        size={'large'}
                        mode={mode || 'single'}
                        allowClear
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) =>
                               (optionA?.name ?? '').toLowerCase().localeCompare((optionB?.name ?? '').toLowerCase())
                           }>
                      {options?.map(({name, uid}, index) => (
                          <Field.Option value={uid} key={index}>{name}</Field.Option>
                      ))}
                    </Field>
                )}
                {fieldType === 'attachment' && (
                    <React.Fragment>
                      { state.image && <img
                              alt={name}
                              style={{ height: '150px', width: '250px', resize: 'initial' }}
                              src={`${state.image}`}
                          /> }
                      {
                          !state.image &&
                          <Field
                              id={name}
                              style={{ width: '100%' }}
                              onChange={(e) => { }}
                              beforeUpload={async (file) => {
                                const _file = await convertUploadedFileToBase64(file)
                                setState({ ...state, image: _file });
                                return false
                              }}
                              multiple={false}
                              onRemove={() => { }}
                              accept='png,jpeg,jpg'
                              action={null}>
                                <p className="ant-upload-drag-icon">
                                  <InboxOutlined />
                                </p>
                                <p style={{ fontSize: 16, fontWeight: '400' }}>
                                  Click or drag file to this area to upload Image<br />
                                  <span style={{ color: 'red' }}>Maximum upload size: 1MB</span>
                                </p>
                          </Field>
                      }
                    </React.Fragment>
                )}
              </Form.Item>
          ))}
        <Divider />
        <Row gutter={0}>
          <Col lg={24} sm={12} md={12} style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Button key="back" size='large' onClick={onCancel}>
                  Cancel
                </Button>
                <Form.Item >
                  <Button size='large' type='primary' htmlType='submit' loading={loading}>
                    {btnLabel}
                  </Button>
                </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AddProduct.propTypes = {
  initialValues: PropTypes.object,
  title: PropTypes.string,
  btnLabel: PropTypes.string,
  action: PropTypes.string,
  sizes: PropTypes.array,
  colors: PropTypes.array,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  categories: PropTypes.array,
  onCreateProduct: PropTypes.func,
  onGetProductCategories: PropTypes.func,
  onGetProductColors: PropTypes.func,
  onGetProductSizes: PropTypes.func,
  onUpdateProduct: PropTypes.func,
  loading: PropTypes.bool,
  error: PropTypes.any,
}

const mapStateToProProps = ({ auth: {user},  products: { sizes, colors, productCategories: categories, loading, error } }) => ({ user, sizes, colors, categories, loading, error})
const mapDispatchToProps = dispatch => ({
  onCreateProduct: (payload, callback) => dispatch(createProduct(payload, callback)),
  onUpdateProduct: (productId, payload, callback) => dispatch(updateProduct(productId, payload, callback)),
  onGetProductCategories: (params) => dispatch(getProductCategories(params)),
  onGetProductColors: (params) => dispatch(getProductColors(params)),
  onGetProductSizes: (params) => dispatch(getProductSizes(params)),
})

export default connect(mapStateToProProps, mapDispatchToProps)(AddProduct);
