import {Popconfirm, Typography} from "antd";
import {formatNumber, getUserFullName} from "../../../utils/lib/helpers";
import React from "react";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {archiveUser} from "../users";


export const getAgentColumns = (onRecordSelected, onEditRecord, onDeleteRecord) => [
    {
        title: 'Date, Time',
        dataIndex: 'created_at',
        key: 'created_at',
        ellipsis: true,
        render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
        title: 'Office Name',
        dataIndex: 'agent_office_name',
        key: 'agent_office_name',
        ellipsis: true,
        width: '15%',
        render: (text, record) => (<Typography.Link onClick={() => onRecordSelected(record, 'profile')} style={{ color: '#000', fontWeight: 'bold' }}>{text}</Typography.Link>)
    },
    {
        title: 'ID #',
        dataIndex: 'user_id',
        key: 'user_id',
        ellipsis: true,
        render: (_, record) => {
            return (
                <Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>
                    {record.user_id.match(/.{1,3}/g).join('-')}
                </Typography.Link>
            )
        }
    },
    {
        title: 'Region',
        dataIndex: 'region.name',
        key: 'region.name',
        ellipsis: true,
        render: (_, record) => record?.region?.name
    },
    {
        title: 'District',
        key: 'district.name',
        dataIndex: 'district.name',
        ellipsis: true,
        render: (_, record) => record?.district?.name
    },
    {
        title: 'Ward',
        key: 'ward.name',
        dataIndex: 'ward.name',
        ellipsis: true,
        render: (_, record) => record?.ward ? record?.ward?.name : 'N/A'
    },
    {
        title: 'Phone Number',
        dataIndex: 'phone_number',
        key: 'phone_number',
        ellipsis: true,
    },

    {
        title: 'Total Sales',
        key: 'total_sales',
        dataIndex: 'total_sales',
        ellipsis: true,
        render: (text, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onRecordSelected(record, 'sales')}>{`TZS${formatNumber(text)}`}</Typography.Link>)
    },

    {
        title: 'Total Technicians',
        key: 'technicians',
        dataIndex: 'technicians',
        ellipsis: true,
        render: (text, record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }} onClick={() => onRecordSelected(record, 'technicians')}>{formatNumber(text)}</Typography.Link>)
    },
    {
        title: 'Action',
        render: (_, record) => {
            return (
                <React.Fragment>
                    <Typography.Link onClick={() => onEditRecord(record)} style={{ color: '#cbb52f', marginRight: 10 }}>
                        <EditOutlined /> Edit
                    </Typography.Link>
                    <Popconfirm
                        onConfirm={() => onDeleteRecord(record)}
                        title="Delete Confirmation"
                        description="Are you sure to delete this agent?"
                        okText="Yes"
                        cancelText="No">
                        <Typography.Link
                            style={{ color: '#ff0000' }}>
                            <DeleteOutlined />
                            Delete
                        </Typography.Link>
                    </Popconfirm>
                </React.Fragment>
            );
        },
    },
];
