import {Layout} from "antd"
import PropTypes from "prop-types"
import React, {Component} from "react"
import {withRouter} from "react-router-dom"

class AppInnerLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {}
    this.capitalizeFirstLetter.bind(this)
  }

  capitalizeFirstLetter = string => {
    return string.charAt(1).toUpperCase() + string.slice(2)
  }
  render() {
    return <Layout id="auth-content">{this.props.children}</Layout>
  }
}

AppInnerLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
}

export default withRouter(AppInnerLayout)
