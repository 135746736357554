import React from "react"
import {Link} from "react-router-dom"
import {Col, Row} from "antd";

const PageNotFound = () => {
	return (
		<React.Fragment>
			<div className="my-5 pt-2">
				<div>
					<Row>
						<Col lg="12">
							<div className="text-center mb-1">
								<h1 className="font-weight-medium" style={{ fontSize: 60 }}>
									404
								</h1>
								<h4 className="text-uppercase">Sorry, page not found</h4>
								<div className="mt-1 text-center">
									<Link
										style={{
											textDecoration: 'none',
											fontSize: 18,
											color: '#000'
										}} to="/">
										Go Back
									</Link>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</React.Fragment>
	)
}

export default PageNotFound
