import {call, put, takeEvery} from 'redux-saga/effects';

import {loginUserFail, loginUserSuccess} from './actions';
import {LOGIN_USER} from './action-types';
import {authenticateUser} from 'utils/api';

function* doLoginUser({ payload }) {
  try {
    const response = yield call(authenticateUser, payload.credentials)
    localStorage.setItem('emirate:authToken', response.data.access)
    localStorage.setItem('emirate:refreshToken', response.data.refresh)
    yield put(loginUserSuccess(response.data));
    payload.replace('/dashboard/')
  } catch (error) {
    yield put(loginUserFail('Failed to login with the provided credentials!'));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, doLoginUser);
}

export default authSaga;
