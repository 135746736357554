import React, {useEffect, useState} from 'react';
import {Col, Row, Space, Typography} from 'antd';
import {GiftOutlined} from '@ant-design/icons';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {getRewardSettings} from 'store/sales-rewards/actions';
import {formatNumber} from 'utils/lib/helpers';
import api from 'utils/api/config';
import {tablePagination} from 'utils/constants/layout';
import {Table} from "ant-table-extensions";


const Awards = props => {

  document.title = 'Emirate Builders | Awards'
  const {
    onGetRewardSettings
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    selectedRewardSetting: null,
    awards: [],
    showAwardValueUpdate: false,
    loading: false
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  const getAwards = async () => {
    try {
      setState({ ...state, loading: true })
      const response = await api(true).get('award/list/')
      if (response.status === 200) {
        setState({ ...state, loading: false, awards: response.data.results })
      } else {
        setState({ ...state, loading: false })
      }
    } catch (error) {
      setState({ ...state, loading: false })
      console.log(error)
    }
  }

  const awardUser = async () => {

  }

  useEffect(() => {
    getAwards()
  }, [])

  const columns = [
    {
      title: 'Technician',
      dataIndex: 'technician',
      key: 'technician',
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{formatNumber(record)}</Typography.Link>)
    },
    {
      title: 'Award Amount',
      dataIndex: 'award_amount',
      key: 'award_amount',
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{formatNumber(record)}</Typography.Link>)
    },
    {
      title: 'Action',
      render: (_, record) => {
        return (
          <React.Fragment>
            <Typography.Link onClick={() => {
              setState({ ...state, selectedRewardSetting: record, showCreateForm: true })
            }}
              style={{ color: '#016a1a', marginRight: 10 }}>
              <GiftOutlined /> Award
            </Typography.Link>
          </React.Fragment>
        );
      },
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>Redeemed Awards</Typography.Title>
          <Space>
            {/* <Button onClick={() => { setState({ ...state, showCreateForm: true, selectedRewardSetting: null }) }}
              icon={<UserAddOutlined />}>Add Reward</Button> */}
          </Space>
        </Space>
        <Table
            rowKey={'uuid'}
            searchable
            searchableProps={{
              inputProps: {
                placeholder: "Type a word to search...",
                style:{width: '15%'}
              }
            }}
            exportable
            exportableProps={{showColumnPicker: true, fileName: 'reward-wards-request'}}
            pagination={tablePagination} rowSelection={rowSelection} columns={columns} dataSource={state.awards} onChange={handleChange} loading={state.loading} />
      </Col>
    </Row>
  )
}

Awards.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  rewardSettings: PropTypes.array,
  onGetRewardSettings: PropTypes.func
}

const mapStateToProps = ({
  auth: { user },
  rewardsAndSales: { loading, error, rewardSettings }
}) => ({
  user, error, loading, rewardSettings
})

const mapDispatchToProps = dispatch => ({
  onGetRewardSettings: params => dispatch(getRewardSettings(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(Awards);
