import i18next from 'i18next';
import detector from 'i18next-browser-languagedetector';
import {initReactI18next} from 'react-i18next';
import en from './utils/locales/eng/translation.json';
import sw from './utils/locales/sw/translation.json';


const resources = {
  sw: {
    translation: sw,
  },
  en: {
    translation: en,
  },
};

// const language = localStorage.getItem('I18N_LANGUAGE');
// if (!language) {
//   localStorage.setItem('I18N_LANGUAGE', 'en');
// }

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    lng: localStorage.getItem('I18N_LANGUAGE') || 'en',
    fallbackLng: 'en',
    keySeparator: true,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18next;
