import {
    GET_DISTRICTS,
    GET_DISTRICTS_FAIL,
    GET_DISTRICTS_SUCCESS,
    GET_REGIONS,
    GET_REGIONS_FAIL,
    GET_REGIONS_SUCCESS,
    GET_WARDS,
    GET_WARDS_FAIL,
    GET_WARDS_SUCCESS,
    SET_SELECTED_REGION
} from './action-types';

const INIT_STATE = {
  districts: [],
  regions: [],
  wards: [],
  loading: false,
  error: null,
  selectedRegion: null
};

const locations = (state = INIT_STATE, { payload, type }) => {
  switch (type) {
    case GET_REGIONS:
    case GET_DISTRICTS:
    case GET_WARDS:
      return {
        ...state,
        loading: true,
        error: null,
        success: null,
      };

    case GET_REGIONS_SUCCESS:
      return {
        ...state,
        regions: payload,
        loading: false,
      };
    case GET_DISTRICTS_SUCCESS:
      return {
        ...state,
        districts: payload,
        loading: false,
      };
    case GET_WARDS_SUCCESS:
      return {
        ...state,
        wards: payload,
        loading: false,
      };
    case GET_REGIONS_FAIL:
    case GET_DISTRICTS_FAIL:
    case GET_WARDS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
        success: null,
      };

    case SET_SELECTED_REGION:
      return {
        ...state,
        selectedRegion: payload
      }

    default:
      return state;
  }
};

export default locations;
