import React, {useEffect, useState} from 'react';
import {Col, Row, Space, Table, Typography} from 'antd';
import {connect} from 'react-redux';
import PropTypes from 'prop-types'
import {useRouteMatch} from 'react-router-dom'
import {getWards} from 'store/locations/actions';
import {tablePagination} from 'utils/constants/layout';


const Districts = props => {

  document.title = 'Emirate Builders | Wards'
  const {params} = useRouteMatch()
  console.log("Params:: ", params)
  const {
    user,
    loading,
    error,
    selectedRegion,
    onGetWards,
      wards
  } = props

  const [state, setState] = useState({
    openRightMenu: false,
    showCreateForm: false,
    objectToCreate: 'region'
  })

  const [filteredInfo, setFilteredInfo] = useState({});
  const [sortedInfo, setSortedInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const handleChange = (pagination, filters, sorter) => {
    console.log('Various parameters', pagination, filters, sorter);
    setFilteredInfo(filters);
    setSortedInfo(sorter);
  };

  useEffect(() => {
    onGetWards({ district: params?.uid })
  }, [onGetWards, selectedRegion])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      // filters: [],
      // filteredValue: filteredInfo.name || null,
      // onFilter: (value, record) => record.name.includes(value),
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    },
    {
      title: 'District',
      dataIndex: 'district',
      key: 'district',
      sorter: (a, b) => a.name.length - b.name.length,
      sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
      ellipsis: true,
      render: (record) => (<Typography.Link style={{ color: '#000', fontWeight: 'bold' }}>{record}</Typography.Link>)
    }
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    console.log('selectedRowKeys changed: ', newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
  };
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  const hasSelected = selectedRowKeys.length > 0;

  return (
    <Row>
      <Col>
        <Space
          style={{
            marginBottom: 16,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
          }}>
          <Typography.Title level={4} style={{ display: 'flex', alignItems: 'center' }}>
            {wards[0]?.district} <span style={{ fontWeight: 'normal' }}>&nbsp;&nbsp;Wards.</span>
          </Typography.Title>
        </Space>
        <Table
            rowKey={'uid'}
            pagination={tablePagination}
            rowSelection={rowSelection}
            columns={columns}
            dataSource={wards}
            onChange={handleChange}
            loading={loading} />
      </Col>
    </Row>
  )
}

Districts.propTypes = {
  user: PropTypes.any,
  loading: PropTypes.any,
  error: PropTypes.any,
  onGetRegions: PropTypes.func,
  onGetWards: PropTypes.func,
  wards: PropTypes.array,
  selectedRegion: PropTypes.any
}

const mapStateToProps = ({
  auth: { user },
  locations: { regions, districts, wards, loading, error, selectedRegion }
}) => ({
  user, error, loading, regions,wards,  districts, selectedRegion
})

const mapDispatchToProps = dispatch => ({
  onGetWards: params => dispatch(getWards(params)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Districts);
