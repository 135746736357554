import React, {useEffect, useState} from 'react';
import {Button, Col, Divider, Form, Input, Modal, Row, Select, Upload} from 'antd';
import {InboxOutlined} from '@ant-design/icons';
import PropTypes from 'prop-types'
import {convertUploadedFileToBase64} from 'utils/lib/helpers';
import {createProject, getProjectCategories, updateProject} from "../../../store/projects/actions";
import {getDistricts, getRegions} from "../../../store/locations/actions";
import {getUsers} from "../../../store/users/actions";
import {connect} from "react-redux";

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '90%',
  }
}

const AddProject = ({
  isOpen,
  onCancel,
  user,
  categories,
  regions,
  districts,
  onGetDistricts,
    initialValues,
    action,
    title,
    btnLabel,
  users, onUpdateProject, onCreateProject, onGetUserList, onGetRegions, onCompleteRequest, onGetProjectCategories
}) => {

  const [form] = Form.useForm();

  const [state, setState] = useState({
    loading: false,
    categories: [],
    image: null
  });

  useEffect(() => {
    onGetUserList();
    onGetRegions();
    onGetProjectCategories();
    action === 'edit' && onGetDistricts({region: initialValues?.region})
  }, [onGetProjectCategories, onGetRegions, onGetUserList]);
  const handleOnUpdateRecord = (record, data) => { onUpdateProject(record?.uid, data, onCancel); }


  const handleOnDeleteRecord = (record) => {}

  const handleOnSubmit = (data) => {
    const payload = {...data}
    payload.created_by = user?.uid;
    payload.image = state?.image;
    onCreateProject(payload, onCancel);
  }

  return (
    <Modal
      title={
        <Row style={{ justifyContent: 'flex-start', color: "#CAB52F" }}>
          <Col span={12} offset={1} lg={10} md={12} sm={12}><span style={{ fontSize: 20, textTransform: 'capitalize' }}>{title}</span></Col>
          <Divider />
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'60vw'}
      okButtonProps={{
        type: 'primary',
        disabled: state.loading
      }}
      style={{
        top: '20px'
      }}
      footer={[
      ]}
    >
      <Form
        style={{}}
        layout={'vertical'}
        form={form}
        initialValues={initialValues}
        onFinish={v => action === 'edit' ? handleOnUpdateRecord(initialValues, v) : handleOnSubmit(v)}
      >
        <Row style={{ width: '60vw', justifyContent: 'space-evenly' }}>
          <Col lg={10} md={12} sm={12}>
            <Form.Item label="Project title" required style={_style.label} name='project_title' htmlFor='project_title'>
              <Input style={_style.input} required placeholder="Enter project title" size='large' name='project_title' />
            </Form.Item>
            <Form.Item label="Project Category" required style={{ fontWeight: 'bold' }} htmlFor='categories' name='categories' >
              <Select placeholder="Select Category" id='categories' size='large' style={{ width: '90%' }} mode='multiple'>
                {categories?.map((opt, idx) => (
                  <Select.Option value={opt.uid} key={`categories-${idx}`}>{opt.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <Form.Item label="Project Manager" required style={{ fontWeight: 'bold' }} htmlFor='project_manager' name='project_manager' >
              <Select placeholder="Select Agent" id='project_manager' size='large' style={{ width: '90%' }}>
                {users?.map((opt, idx) => (
                  <Select.Option value={opt.uid} key={`manager-${idx}`}>{getUserFullName(opt)}</Select.Option>
                ))}
              </Select>
            </Form.Item> */}

            <Form.Item label="Region" required style={{ fontWeight: 'bold' }} htmlFor='region' name='region' >
              <Select placeholder="Select Region" id='region' size='large' style={{ width: '90%' }}
                onSelect={(v) => {
                  onGetDistricts({ region: v })
                }}
              >
                {regions?.map((opt, idx) => (
                  <Select.Option value={opt.uid} key={`-${idx}`}>{opt.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="District" required style={{ fontWeight: 'bold' }} htmlFor='district' name='district' >
              <Select placeholder="Select District" id='district' size='large' style={{ width: '90%' }}>
                {districts?.map((opt, idx) => (
                  <Select.Option value={opt.uid} key={`districts-${idx}`}>{opt.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>

          </Col>
          <Col lg={10} md={12} sm={12}>

            <Form.Item label="Project Description" style={_style.label} name='description'>
              <Input.TextArea
                required
                style={_style.input}
                placeholder="Maximum length: 300"
                size='large'
                name='description'
                maxLength={300}
              />
            </Form.Item>
            <Form.Item label="Project Image" required style={_style.label} htmlFor='image'>
              {
                initialValues?.image || state.image && <img alt={initialValues?.project_title || ""}
                  style={{ height: '150px', width: '200px', resize: 'initial' }}
                  src={initialValues?.image || state?.image}
                />
              }
              {
                action !== 'edit' && !state.image &&
                <Upload.Dragger id='image'
                  style={{ width: '90%' }}
                  onChange={(e) => { }}
                  beforeUpload={async (file) => {
                    const _file = await convertUploadedFileToBase64(file)
                    setState({ ...state, image: _file });
                    return false
                  }}
                  multiple={false}
                  onRemove={() => { }}
                  accept='png,jpeg,jpg'
                  action={null}
                >
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p style={{ fontSize: 16, fontWeight: '400' }}>
                    Click or drag file to this area to upload Image<br />
                    <span style={{ color: 'red' }}>Maximum upload size: 1MB</span>
                  </p>
                </Upload.Dragger>
              }
            </Form.Item>
          </Col>
          <Col lg={24} sm={24} md={24}>
            <Row style={{ justifyContent: 'end', width: '100%' }}>
              <Col span={12} sm={6}>
                <Button key="back" size='large' onClick={() => { setState({ ...state, loading: false, image: null }); onCancel() }}>
                  Cancel
                </Button>
              </Col>
              <Col sm={6}>
                <Form.Item >
                  <Button size='large' type='primary' htmlType='submit' loading={state.loading}>
                    {btnLabel}
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

AddProject.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  user: PropTypes.any,
  title: PropTypes.any,
  btnLabel: PropTypes.any,
  action: PropTypes.any,
  initialValues: PropTypes.any,
  categories: PropTypes.array,
  onCompleteRequest: PropTypes.func,
  onGetProjectCategories: PropTypes.func,
  onGetDistricts: PropTypes.func,
  onCreateProject: PropTypes.func,
  onUpdateProject: PropTypes.func,
  onGetRegions: PropTypes.func,
  onGetUserList: PropTypes.func,
  regions: PropTypes.array,
  districts: PropTypes.array,
  users: PropTypes.array
}

const mapStateToProps = ({
                           auth: { user },
                           users: { allUsers: users },
                           projectsReducer: { projectCategories: categories, loading, error },
                           locations: { regions, districts }
                         }) => ({
  user, users, error, loading, categories, regions, districts
})

const mapDispatchToProps = dispatch => ({
  onCreateProject: (payload, callback) => dispatch(createProject(payload, callback)),
  onUpdateProject: (projectId, payload, callback) => dispatch(updateProject(projectId, payload, callback)),
  onGetProjectCategories: (params) => dispatch(getProjectCategories(params)),
  onGetDistricts: params => dispatch(getDistricts(params)),
  onGetRegions: params => dispatch(getRegions(params)),
  onGetUserList: params => dispatch(getUsers({ designation: null })),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddProject);
